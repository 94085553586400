import { AxiosResponse } from "axios";
import {
  AdministrativeSectorResponse,
  AdministrativeSectorsResponse,
} from "@@types/administrative-sectors/administrative-sectors-response.type";
import { axiosGeoApi } from "../axios";
import { ADMINISTRATIVE_SECTORS_API_ROUTES, PARAMETERS } from "./constants";

export class AdministrativeSectorsService {
  static async fetchAdministrativeSectors(): Promise<AxiosResponse<AdministrativeSectorsResponse>> {
    return axiosGeoApi.get<AdministrativeSectorsResponse>(ADMINISTRATIVE_SECTORS_API_ROUTES.list);
  }
  static async fetchAdministrativeSector(id: string): Promise<AxiosResponse<AdministrativeSectorResponse>> {
    return axiosGeoApi.get<AdministrativeSectorResponse>(
      ADMINISTRATIVE_SECTORS_API_ROUTES.show.replace(PARAMETERS.id, id)
    );
  }
}
