import React, { ReactElement, ReactNode } from "react";

import Icon from "@/components/icons/Icon";
import { IconSize } from "@/components/icons/Icon.type";
import {
  ButtonBase,
  ButtonBaseProps,
  Dialog as DialogMui,
  DialogContent,
  DialogContentProps,
  DialogProps as DialogMuiProps,
  DialogTitle,
  DialogTitleProps,
  Stack,
} from "@mui/material";

export type DialogProps = {
  children: ReactNode;
  onClose: (event: React.MouseEvent<never>) => void;
  open: boolean;
  showCloseButton?: boolean;
  closeButtonProps?: Omit<DialogCloseButtonProps, "onClose">;
  title?: React.ReactNode | string;
  titleProps?: DialogTitleProps;
  contentProps?: DialogContentProps;
} & Omit<DialogMuiProps, "onClose" | "open" | "title">;

type DialogCloseButtonProps = {
  onClose: (event: React.MouseEvent<never>) => void;
  iconSize?: IconSize;
} & ButtonBaseProps;

const DialogCloseButton = ({ onClose, iconSize = "small", ...props }: DialogCloseButtonProps): ReactElement => (
  <ButtonBase
    onClick={onClose}
    {...props}
  >
    <Icon
      name="close"
      size={iconSize}
    />
  </ButtonBase>
);

export const Dialog = ({
  open,
  children,
  onClose,
  showCloseButton = true,
  closeButtonProps,
  title,
  titleProps,
  contentProps,
  ...props
}: DialogProps): ReactElement => {
  return (
    <DialogMui
      open={open}
      onClose={onClose}
      // Prevent clicking actionnable items behind the dialog
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      {(title || showCloseButton) && (
        <Stack
          direction="row"
          justifyContent={showCloseButton && !title ? "flex-end" : "space-between"}
          px={3}
          py={2}
        >
          {title && (
            <DialogTitle
              sx={{ padding: 0 }}
              // onClick={(e) => e.stopPropagation()}
              {...titleProps}
            >
              {title}
            </DialogTitle>
          )}

          {showCloseButton && (
            <DialogCloseButton
              onClose={onClose}
              {...closeButtonProps}
            />
          )}
        </Stack>
      )}

      <DialogContent
        dividers={!!title || showCloseButton}
        {...contentProps}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </DialogContent>
    </DialogMui>
  );
};

export default Dialog;
