// React imports
import React, { memo, useCallback } from "react";

// External iports
import Select from "react-select";
import { isEqual } from "lodash";
import { Button, Stack } from "@mui/material";

function SubRouteSelect({ isError, subRoute, subRoutes, handlers }) {
  // console.log("[SubRouteSelect] >>> RENDERED");

  const handleChangeSubRoute = useCallback((subRoute) => {
    subRoute && handlers.setSingleCurrent("subRoute", subRoute);
  }, []);

  return (
    <Stack direction="row" spacing={1} sx={{
      alignItems: "center"
    }}>
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            boxShadow: isError ? "none" : base.boxShadow,
            borderColor: isError ? "red" : base.borderColor,
            "&:hover": {
              borderColor: isError ? "red" : base["&:hover"].borderColor,
            },
          }),
        }}
        value={subRoute.value ? subRoute : null}
        isDisabled={subRoutes.loading}
        isLoading={subRoutes.loading}
        menuPosition="fixed"
        onChange={(subRoute) => handleChangeSubRoute(subRoute)}
        options={subRoutes.data}
        placeholder={"Sélectionner une sous-route"}
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { isError: prevIsError, subRoute: prevSubRoute, subRoutes: prevSubRoutes } = prevProps;
  const { isError: nextIsError, subRoute: nextSubRoute, subRoutes: nextSubRoutes } = nextProps;
  let arePropsEqual = true;

  if (!isEqual(prevSubRoutes, nextSubRoutes)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevSubRoute, nextSubRoute)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevIsError, nextIsError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(SubRouteSelect, areEqual);
