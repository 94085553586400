import moment, { Moment } from "moment";
import React, { ReactElement } from "react";

import { Icon, IconButton, Stack } from "@mui/material";
import theme from "@styles/theme";
import InlineDatePickerWrapper from "@ui/InlineDatePickerWrapper";
import { formattedDate } from "@utils/dates";

export type DatePickerActionBarProps = {
  currentDate: string;
  onChange: (date: string) => void;
};

const DatePickerActionBar = ({ currentDate, onChange }: DatePickerActionBarProps): ReactElement => {
  const handleArrowClick = (direction: string): void => {
    let dateToChange;
    const date = currentDate || new Date();

    if (direction === "backward") {
      dateToChange = moment(moment(date).add(-1, "d").format("YYYY-MM-DD"));
    } else {
      dateToChange = moment(moment(date).add(1, "d").format("YYYY-MM-DD"));
    }

    onChange(formattedDate(dateToChange));
  };

  // Handle the date change event
  const handleDateChange = (date: Moment | unknown): void => {
    const newDate = date || new Date();
    onChange(formattedDate(newDate));
  };

  return (
    <Stack
      direction="row"
      sx={{
        width: "330px",
      }}
    >
      <IconButton
        onClick={() => handleArrowClick("backward")}
        size="large"
        color="secondary"
      >
        <Icon>arrow_back</Icon>
      </IconButton>
      <InlineDatePickerWrapper
        label="Date"
        value={currentDate ? moment(currentDate) : moment()}
        onChange={(event) => handleDateChange(event)}
        slotProps={{
          textField: {
            sx: {
              svg: { color: theme.colors.palette.secondary.main },
            },
          },
        }}
      />
      <IconButton
        onClick={() => handleArrowClick("")}
        size="large"
        color="secondary"
      >
        <Icon>arrow_forward</Icon>
      </IconButton>
    </Stack>
  );
};

export default DatePickerActionBar;
