import { TextField as MuiTextField } from "@mui/material";
import React from "react";

const TextArea = ({ ...remainingProps }) => (
  <MuiTextField
    multiline
    rows={3}
    variant="outlined"
    {...remainingProps}
    slotProps={{
      inputLabel: { disableAnimation: true, shrink: true }
    }} />
);

export default TextArea;
