import generateInvoiceableJobReports, {
  GenerateInvoiceableJobReportsServiceReturn,
} from "@/services/jobs/generate-invoiceable-job-reports.service";
import transactionKeys from "@hooks/transactions/transaction.keys";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { downloadFile } from "@utils/files";

const useGenerateInvoiceableJobReports = (
  jobId: number
): UseQueryResult<GenerateInvoiceableJobReportsServiceReturn> => {
  return useQuery({
    queryKey: transactionKeys.clientJobReports(jobId),
    queryFn: async () => {
      const { blob, filename } = await generateInvoiceableJobReports(jobId);

      downloadFile(blob, filename);
      return null;
    },
    enabled: false,
    retry: false,
  });
};

export default useGenerateInvoiceableJobReports;
