// React imports
import React, { useEffect, useMemo, memo } from "react";

// Internal imports
import * as API from "@services";
import useAsync from "@hooks/useAsync";
import DialogWrapper from "@ui/DialogWrapper";
import LoadingButton from "@ui/LoadingButton";
import useQuickRoute from "@hooks/useQuickRoute";
import useLogStateChange from "@hooks/useLogStateChange";
import DateUnitSelects from "@components/modals/quickTask/common/DateUnitSelects";
import NoteColorPicker from "@components/modals/quickTask/common/NoteColorPicker";
import CustomerSelect from "@components/modals/quickTask/quickRoute/CustomerSelect";
import ContractSelect from "@components/modals/quickTask/quickRoute/ContractSelect";
import SubRouteSelect from "@components/modals/quickTask/quickRoute/SubRouteSelect";
import DropPointSelect from "@components/modals/quickTask/quickRoute/DropPointSelect";

// External iports
import PropTypes from "prop-types";
import { map, includes, isEqual } from "lodash";
import {
  DialogTitle,
  Stack,
  Box,
  Button,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
  NativeSelect,
  RadioGroup,
  FormLabel,
  Radio,
} from "@mui/material";

import { AddTask } from "@mui/icons-material";
import { formattedDate } from "@utils/dates";

const formatQuickRoutePayload = (current) => {
  return {
    average_lifting_time: current.subRoute.data.averageLiftingTime,
    customer_item_id: current.subRoute.data.customerItemId,
    name: current.subRoute.data.name,
    route_template_id: current.subRoute.value,
    start_date: formattedDate(current.date),
    supplier_location_id: current.dropPoint.value,
    unit_id: current.unit.value ? current.unit.value : "-1",
    weekly: true,
    monthly: true,
    twice_monthly: true,
  };
};

const handleSubmitQuickRoute = (current, handlers, createRoute) => {
  // TODO - Add fields validation from the current object
  try {
    // let isFormValid = validateForm(current, handlers, errors);

    // if (isFormValid) {
    const payload = formatQuickRoutePayload(current);
    createRoute.run(payload);
    // }
  } catch (error) {
    console.warn("[handleSubmitQuickRoute] error:", error);
  }
};

function QuickRoute({ isOpen, onClose, units, Header, taskType, setTaskType }) {
  // console.log("[QuickRoute] >>> RENDERED");
  const { data, current, handlers } = useQuickRoute(units);

  const createRoute = useAsync((payload) => API.Route.createRoute(payload));

  // useLogStateChange("createRoute.value", createRoute.value);
  // useLogStateChange("createRoute.error", createRoute.error);
  // useLogStateChange("createRoute.loading", createRoute.loading);

  useEffect(() => {
    // Whenever createRoute value returns 200, that means the user created the route so close the modal
    createRoute.value === 200 && onClose();
  }, [createRoute.value]);

  const headerProps = useMemo(
    () => ({
      taskType,
      setTaskType,
      topLeft: <Typography variant="h6">Ajouter une route</Typography>,
      topRight: <></>,
      bottomRight: <></>,
    }),
    [taskType]
  );

  return (
    <DialogWrapper
      onClose={onClose}
      open={isOpen}
    >
      <Stack>
        <Header {...headerProps} />

        {/* TODO - Remove each isError to use the one from the current object defined in useQuickRoute */}
        <Stack
          spacing={3}
          sx={{
            minHeight: 500,
            px: 3,
            pb: 2,
          }}
        >
          {/* CUSTOMER SELECT */}
          <CustomerSelect
            isError={false}
            customer={current.customer}
            customers={data.customers}
            handlers={handlers}
          />

          {/* CONTRACT SELECT */}
          {current.customer.data && (
            <ContractSelect
              isError={false}
              contract={current.contract}
              contracts={data.contracts}
              handlers={handlers}
            />
          )}

          {/* SUB-ROUTE SELECT */}
          {current.contract.data && (
            <SubRouteSelect
              isError={false}
              subRoute={current.subRoute}
              subRoutes={data.subRoutes}
              handlers={handlers}
            />
          )}

          {/* DATE & UNIT SELECTS */}
          {current.subRoute.data && (
            <DateUnitSelects
              date={current.date}
              unit={current.unit}
              units={data.units}
              handlers={handlers}
            />
          )}

          {/* DROP POINT SELECT */}
          {current.subRoute.data && (
            <DropPointSelect
              isError={false}
              dropPoint={current.dropPoint}
              dropPoints={data.suppliers}
              handlers={handlers}
            />
          )}

          {/*/!* NOTE COLOR PICKER *!/*/}
          {/*{current.subRoute.data && (*/}
          {/*  <NoteColorPicker colorStateName="routeNoteColor" noteColor={current.routeNoteColor} handlers={handlers} />*/}
          {/*)}*/}
        </Stack>

        <Stack
          direction="row"
          // justifyContent={current.location.data ? "space-between" : "flex-end"}
          spacing={1}
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "flex-end",
            height: "64px",
            alignItems: "center",
          }}
        >
          {/* LEFT-SIDE OF BUTTONS CONTAINER */}
          {/* <Typography variant="body1">{current.location.data && lastCustomerItem}</Typography> */}

          {/* BUTTONS */}
          <Stack
            direction="row"
            spacing={1}
            sx={{
              height: "fit-content",
            }}
          >
            <Button
              onClick={onClose}
              disabled={createRoute.loading}
            >
              Annuler
            </Button>

            <LoadingButton
              loading={createRoute.loading}
              startIcon={<AddTask />}
              onClick={() => handleSubmitQuickRoute(current, handlers, createRoute)}
              loadingPosition="start"
            >
              Ajouter
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </DialogWrapper>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { units: prevUnits, taskType: prevTaskType } = prevProps;
  const { units: nextUnits, taskType: nextTaskType } = nextProps;
  let arePropsEqual = true;

  if (!isEqual(prevUnits, nextUnits)) {
    arePropsEqual = false;
  }

  if (prevTaskType !== nextTaskType) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

QuickRoute.propTypes = {
  onClose: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  Header: PropTypes.elementType.isRequired,
  taskType: PropTypes.string.isRequired,
  setTaskType: PropTypes.func.isRequired,
};

export default memo(QuickRoute, areEqual);
