import React, { ReactElement } from "react";

import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

const ProblemCaseIcon = ({
  size = defaultIconProps.size,
  fillColor = "#E31B0C",
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 3.4V5M5 6.6H5.004M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default ProblemCaseIcon;
