import createCustomerLocation, {
  CreateCustomerLocationPayload,
  CreateCustomerLocationServiceReturn,
} from "@/services/customers/create-customer-location.service";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

export type UseCreateCustomerLocationParams = {
  id: number;
  payload: CreateCustomerLocationPayload;
};

export type UseCreateCustomerLocationReturn = UseMutationResult<
  CreateCustomerLocationServiceReturn,
  Error,
  UseCreateCustomerLocationParams
>;

const useCreateCustomerLocation = (): UseCreateCustomerLocationReturn => {
  return useMutation({
    mutationFn: ({ id, payload }: UseCreateCustomerLocationParams) => createCustomerLocation(id, payload),
  });
};

export default useCreateCustomerLocation;
