// React imports
import { useState, useEffect, useMemo, useCallback } from "react";

// Internal imports
import * as API from "@services";
import useAsync from "@hooks/useAsync";

// External importrs
import moment from "moment";
import { map, orderBy, find, filter } from "lodash";
import useLogStateChange from "@hooks/useLogStateChange";

moment.locale("fr-ca");
const today = moment();

const initialCurrentState = { value: null, label: "", error: false, data: null };
const initialCurrentStates = {
  customer: initialCurrentState,
  contract: initialCurrentState,
  subRoute: initialCurrentState,
  date: today,
  unit: initialCurrentState,
  dropPoint: initialCurrentState,
  routeNoteColor: "#000",
};

const initialDataState = { data: null, loading: false };
const initialDataStates = {
  customers: initialDataState,
  contracts: initialDataState,
  subRoutes: initialDataState,
  units: initialDataState,
  suppliers: initialDataState,
};

const formattedSelect = (elements, value = "value", label = "label") => {
  if (!elements || elements.length <= 0) {
    return [];
  }

  const formattedElements = map(elements, (element) => ({
    value: element[value],
    label: element[label],
    data: element,
  }));

  const orderedElements = orderBy(formattedElements, ["label"], ["asc"]);

  return orderedElements;
};

const fetchCustomerRouteContractsAsync = async (customerID, setSingleData) => {
  const contracts = await API.Contract.fetchCustomerRouteContracts(customerID);
  const formattedContracts = formattedSelect(contracts, "id", "name");

  formattedContracts && setSingleData("contracts", formattedContracts, "data");
};

const fetchCustomerContractRouteTemplatesAsync = async (customerID, contractID, setSingleData) => {
  const routeTemplates = await API.Contract.fetchCustomerContractRouteTemplates(customerID, contractID);
  const filteredRouteTemplates = routeTemplates.filter((routeTemplate) => routeTemplate.isAMasterRoute === false);
  const formattedRouteTemplates = formattedSelect(filteredRouteTemplates, "id", "completeName");

  formattedRouteTemplates && setSingleData("subRoutes", formattedRouteTemplates, "data");
};

const fetchInitialDataAsync = async (setData) => {
  const customers = await API.Customer.fetchCustomersWithRoute();
  const suppliers = await API.Location.fetchSuppliers();

  setData((prevState) => ({
    ...prevState,
    customers: { ...prevState.customers, data: formattedSelect(customers, "id", "name") },
    suppliers: { ...prevState.suppliers, data: formattedSelect(suppliers, "id", "name") },
  }));
};

export default function useQuickRoute(initialUnits = null) {
  // console.log("[useQuickRoute] >>> RENDERED");
  const frontLoadingUnits = initialUnits.filter((unit) => unit.label.includes("CA"));
  const formattedUnits = useMemo(() => formattedSelect(frontLoadingUnits), [initialUnits]);

  const [current, setCurrent] = useState(initialCurrentStates);
  const [data, setData] = useState({ ...initialDataStates, units: { data: formattedUnits, loading: false } });

  const { customers, contracts, subRoutes, units, suppliers } = data;
  const { customer, contract, subRoute, date, unit, dropPoint, routeNoteColor } = current;

  // useLogStateChange("useQuickRoute > data", data);
  // useLogStateChange("useQuickRoute > current", current);

  const fetchInitialData = useAsync(async () => fetchInitialDataAsync(setData));
  const fetchCustomerRouteContracts = useAsync(() => fetchCustomerRouteContractsAsync(customer.value, setSingleData));
  const fetchCustomerContractRouteTemplates = useAsync(() =>
    fetchCustomerContractRouteTemplatesAsync(customer.value, contract.value, setSingleData)
  );

  useEffect(() => {
    fetchInitialData.run();
  }, []);

  useEffect(() => {
    // Whenever innitial data is loading, update customers and suppliers to show it's loading
    setData((prevState) => ({
      ...prevState,
      customers: { ...prevState.customers, loading: fetchInitialData.loading },
      suppliers: { ...prevState.suppliers, loading: fetchInitialData.loading },
    }));
  }, [fetchInitialData.loading]);

  useEffect(() => {
    // Whenever accounting-items are loading, update its state to show it's loading
    setSingleData("contracts", fetchCustomerRouteContracts.loading, "loading");
  }, [fetchCustomerRouteContracts.loading]);

  useEffect(() => {
    // Whenever subRoutes are loading, update its state to show it's loading
    setSingleData("subRoutes", fetchCustomerContractRouteTemplates.loading, "loading");
  }, [fetchCustomerContractRouteTemplates.loading]);

  // Handle the effects related to the customer select
  useEffect(() => {
    // Whenever the selected customer changes, reset the current contract to null if we have one selected
    contract.data && setSingleCurrent("contract", initialCurrentState);

    // Whenenver the selected customer changes and it's valid, fetch its route accounting-items
    customer.data && fetchCustomerRouteContracts.run();
  }, [customer.data]);

  // Handle the effects related to the contract select
  useEffect(() => {
    // Whenever the current contract changes and it's valid, fetch its route templates
    contract.data && fetchCustomerContractRouteTemplates.run();

    // Whenever the current contract changes, reset the subRoute if currently selected
    subRoute.data && setSingleCurrent("subRoute", initialCurrentState);
  }, [contract.data]);

  // Handle the effects related to the subRoute select
  useEffect(() => {
    // whenever a subRoute is selected, get it's default drop point
    if (subRoute.data) {
      const supplierLocationID = subRoute.data.supplierLocationId;

      const dropPoint = find(suppliers.data, ["value", supplierLocationID]);

      dropPoint && setSingleCurrent("dropPoint", dropPoint);
    }
    // Whenever the subRoute changes to null, reset the related fields if they are selected in one setState
    if (!subRoute.data) {
      let updates = {};

      if (date) updates = { ...updates, date: today };
      if (routeNoteColor) updates = { ...updates, routeNoteColor: "#000" };
      if (dropPoint.data) updates = { ...updates, dropPoint: initialCurrentState };
      if (unit.data) updates = { ...updates, unit: initialCurrentState };

      setCurrent((prevState) => ({ ...prevState, ...updates }));
    }
  }, [subRoute.data]);

  /**
   * Handler function to update current state, but just a specific property or a nested property
   */
  const setSingleCurrent = useCallback((state, value, nestedState = null) => {
    // Since state is an object, we want to allow updating of nested properties
    if (nestedState) {
      setCurrent((prevState) => ({ ...prevState, [state]: { ...prevState[state], [nestedState]: value } }));
    } else {
      setCurrent((prevState) => ({ ...prevState, [state]: value }));
    }
  }, []);

  /**
   * Handler function to update data state, but just a specific property or a nested property
   */
  const setSingleData = useCallback((state, value, nestedState = null) => {
    // Since state is an object, we want to allow updating of nested properties
    if (nestedState) {
      setData((prevState) => ({ ...prevState, [state]: { ...prevState[state], [nestedState]: value } }));
    } else {
      setData((prevState) => ({ ...prevState, [state]: value }));
    }
  }, []);

  /**
   * Handler function to update current state, but just the error property
   */
  const setSingleError = useCallback((state, value) => {
    setCurrent((prevState) => ({ ...prevState, [state]: { ...prevState[state], error: value } }));
  }, []);

  const handlers = useMemo(
    () => ({
      setData,
      setCurrent,
      setSingleData,
      setSingleError,
      setSingleCurrent,
    }),
    []
  );

  return { data, current, handlers };
}
