import React, { useEffect } from "react";
import DialogWrapper from "@ui/DialogWrapper";
import { Stack, Box, Button, DialogTitle } from "@mui/material";
import CustomerDetails from "@components/customer/CustomerDetails";

import { withProvider } from "@utils/withProvider";
import CustomerContextProvider, { useCustomerContext } from "@context/customer/CustomerContextProvider";
import useAsync from "@hooks/useAsync";
import * as API from "@services";
import useLogStateChange from "@hooks/useLogStateChange";
import useApiResponseHandler from "@hooks/useApiResponseHandler";

import { Save } from "@mui/icons-material";

import LoadingButton from "@ui/LoadingButton";
import { isArrayTruthy } from "@utils/common";

const postContractWithSalesInformation = async (customer, onClose, handleApiResponse, validateSaleDetails) => {
  const { saleDetails, data } = customer;

  const isSaleDetailsValid = validateSaleDetails();

  if (isSaleDetailsValid) {
    const array = [];
    // create array of key: value for sub contract details if private collection selected
    if (!saleDetails.planType.id) {
      Object.values(saleDetails.subContractDetails).map((t) => {
        if (t.checked && t.value) {
          array.push({ [t.key]: t.value });
        }
      });
    }

    const type = isArrayTruthy(array) ? "sub_contract_details" : "plan_code";
    const params = {
      payment_type_code: saleDetails.paymentMode.id,
      sales_type_code: saleDetails.saleType.id,
      require_weigh_in: saleDetails.requireWeighIn,
      [type]: isArrayTruthy(array) ? array : saleDetails.planType.id,
    };

    const res = await API.Contract.createContractWithSalesInformation(data.id, params);
    handleApiResponse(res, () => onClose(res));
  }
};

function ModalContract({ open, onClose, customer }) {
  const handleApiResponse = useApiResponseHandler();
  const { handlers, customer: customerContext } = useCustomerContext();
  const postContractWithSalesInformationAsync = useAsync(() =>
    postContractWithSalesInformation(customerContext, onClose, handleApiResponse, handlers.validateSaleDetails)
  );

  useEffect(() => {
    // Set the customer data in the context at mount
    handlers.setCustomer((prevState) => ({
      ...prevState,
      data: customer,
      currentlyOpenModal: "contract",
    }));

    // Since handleValidateForm from CustomerDetails is based off fields, set them from the customer data too
    handlers.setFields(customer);
  }, []);

  return (
    <DialogWrapper
      onClose={onClose}
      open={open}
    >
      <DialogTitle>Ajouter un contrat</DialogTitle>
      <CustomerDetails
        isLoading={postContractWithSalesInformationAsync.loading}
        isContractSelectShowing={false}
      >
        <Stack
          spacing={1}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 1
          }}>
          <Button
            onClick={onClose}
            disabled={postContractWithSalesInformationAsync.loading}
          >
            Annuler
          </Button>

          <LoadingButton
            loading={postContractWithSalesInformationAsync.loading}
            startIcon={<Save />}
            onClick={postContractWithSalesInformationAsync.run}
            loadingPosition="start"
          >
            Enregister le contrat
          </LoadingButton>
        </Stack>
      </CustomerDetails>
    </DialogWrapper>
  );
}

export default withProvider(CustomerContextProvider)(ModalContract);
