import React, { ReactElement } from "react";

import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

const EcoIcon = ({
  size = defaultIconProps.size,
  fillColor = "#7BC67E",
  strokeColor = "#FFFFFF",
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="5"
          cy="5"
          r="5"
          fill={fillColor}
        />
        <path
          d="M2 8C2 7.05263 2.58421 6.30737 3.60421 6.10526C4.36842 5.95368 5.15789 5.47368 5.47368 5.15789M4.84211 7.68421C4.2876 7.68589 3.75271 7.47911 3.34353 7.10487C2.93435 6.73063 2.68077 6.21628 2.63307 5.66382C2.58538 5.11137 2.74706 4.56117 3.08605 4.12234C3.42503 3.68351 3.91657 3.38812 4.46316 3.29474C6.26316 2.94737 6.73684 2.78316 7.36842 2C7.68421 2.63158 8 3.32 8 4.52632C8 6.26316 6.49053 7.68421 4.84211 7.68421Z"
          stroke={strokeColor}
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default EcoIcon;
