import axios from "axios";

import { requestOptions } from "../auth";
import { formatBackendCollectionData } from "../utils";
import { isArrayTruthy } from "@utils/common";

// Vérifier si le stripe customer existe et sil n'existe pas verifier si on peut trouver des correspondants dans Stripe
const getCustomerStripeStatus = async (customerID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/customers/${customerID}/stripe_status`,
      requestOptions()
    );

    return res.data.status === "success" ? res.data : null;
  } catch (error) {
    console.warn("[API.getCustomerStripeStatus] Error:", error);
    return error;
  }
};

const createStripeCustomer = async (customerID) => {
  const payload = {};

  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/customers/${customerID}/create_stripe_customer`,
      payload,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[API.createStripeCustomer] Error:", error);
    throw error.response.data;
  }
};

const createCustomer = async (payload) => {
  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/customers.json`,
      { customer: { ...payload } },
      requestOptions()
    );
    res.data.status !== "success" && console.warn("[API.createCustomer] status is not successfull:", res);

    return res.data;
  } catch (error) {
    console.warn("[API.Customer] createCustomer >> error:", error);
    return error.response.data;
  }
};

const updateCustomer = async (customerID, payload) => {
  try {
    const res = await axios.put(
      `${import.meta.env.VITE_API_URL}/customers/${customerID}`,
      { customer: { ...payload } },
      requestOptions()
    );
    res.data.status !== "success" && console.warn("[API.updateCustomer] status is not successfull:", res);

    return res.data;
  } catch (error) {
    console.warn("[API.updateCustomer] Error:", error);
    return error.response.data;
  }
};

const prepareCustomerForWork = async (customerID, saleTypeCode, planType, paymentTypeCode, contractID = null, requireWeighIn = false) => {
  // planType params is array if private_collection selected as saleType
  const type = isArrayTruthy(planType) ? "sub_contract_details" : "plan_code";

  const payload = {
    customer: {
      id: customerID,
      contract_id: contractID,
      sales_type_code: saleTypeCode,
      [type]: planType,
      payment_type_code: paymentTypeCode,
      require_weigh_in: requireWeighIn,
    },
  };

  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/customers/${customerID}/prepare_customer_for_work`,
      payload,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[API.prepareCustomerForWork] Error:", error);
    return error.response.data;
  }
};

const fetchCustomer = async (customerID) => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/customers/${customerID}`, requestOptions());

    return response.data.data.attributes;
  } catch (error) {
    console.warn("[fetchCustomer] error:", error);
  }
};

const fetchCustomersWithRoute = async (filter = "", limit = -1, page = 1) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}/customers/route_customers.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    const formattedResponse = formatBackendCollectionData(response.data);

    return formattedResponse;
  } catch (error) {
    console.warn("[fetchCustomersWithRoute] error:", error);
  }
};

const fetchCustomersWithJob = async (filter = "", limit = -1, page = 1) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}/customers/job_customers.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    const formattedResponse = formatBackendCollectionData(response.data);

    return formattedResponse;
  } catch (error) {
    console.warn("[fetchCustomersWithJob] error:", error);
  }
};

const fetchCustomerLocation = async (customerID, locationID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/customers/${customerID}/location/${locationID}`,
      requestOptions()
    );

    return res.data.data.attributes;
  } catch (error) {
    console.warn("[API.fetchCustomerLocation] Error:", error);
    return error.response.data;
  }
};

const changeLocationPosition = async (locationID, params) => {
  try {
    const res = await axios.put(
      `${import.meta.env.VITE_API_URL}/locations/${locationID}/change_position`,
      { location: { ...params } },
      requestOptions()
    );
    return res;
  } catch (error) {
    console.warn("[API.changeLocationPosition] Error:", error);
    return error.res.data;
  }
};

const managementBlockCustomerJob = async (id, reason, stopTheBlock) => {
  try {
    await axios.post(
      `${import.meta.env.VITE_API_URL}/customers/${id}/customer_control_task_block`,
      {
        id,
        reason,
        is_blocked: !stopTheBlock,
      },
      requestOptions()
    );
  } catch (error) {
    console.warn("[API.createBlockTaskError] Error:", error);
  }
};
//
const fetchCustomerProblemCaseReport = async (params) => {
  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/customers/${params.customer_id}/problem_case_report?`,
      params,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[API.fetchCustomerProblemCaseReport] Error:", error);
  }
};

/**
 * Customer api service for exposing api endpoints.
 *
 * Available requests:
 * - `createCustomer(payload)`
 * - `updateCustomer(customerID, payload)`
 * - `prepareCustomerForWork(customerID, saleTypeCode, planType, paymentTypeCode)`
 * - `fetchCustomer(customerID)`
 * - `fetchCustomersWithRoute(filter, limit, page)`
 * - `fetchCustomersWithJob(filter, limit, page)`
 * - `fetchCustomerLocation(customerID, locationID)`
 * - `changeLocationPosition(locationID, params)`
 * - `getCustomerStripeStatus(customerID)`
 * - `createStripeCustomer(customerID)`
 * - `managementBlockCustomerJob(customerId, reason, stopTheBlock)`
 * - `fetchCustomerProblemCaseReport(customerId, startDate, endDate)`
 */
export {
  createCustomer,
  updateCustomer,
  prepareCustomerForWork,
  fetchCustomer,
  fetchCustomersWithRoute,
  fetchCustomersWithJob,
  fetchCustomerLocation,
  changeLocationPosition,
  getCustomerStripeStatus,
  createStripeCustomer,
  managementBlockCustomerJob,
  fetchCustomerProblemCaseReport,
};
