import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  withCustomerLocations,
  withJobs,
  withJobTemplates,
  withLocations,
  withRoutes,
  withRouteTemplates,
  withSupplierLocations,
} from "optigo-redux";
import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { Trans } from "react-i18next";
import { withRouter } from "@utils/withRouter";

import DatePicker from "./form/DatePickerMui";
import DialogWrapper from "./ui/DialogWrapper";
import FlexRowWrapper from "./ui/FlexRowWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import HalfFormControl from "./ui/HalfFormControl";
import RecurrenceAnnualy from "./RecurrenceAnnualy";
import RecurrenceMonthly from "./RecurrenceMonthly";
import RecurrenceWeekly from "./RecurrenceWeekly";
import RecurrenceDaily from "./RecurrenceDaily";
import SelectUi from "./ui/Select";
import SelectHalfUi from "./ui/SelectHalf";
import TextFieldUi from "./ui/TextField";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import { filterJobTemplatesByCustomerItem, filterSupplierLocations } from "@utils/filtering";
import { formattedDate } from "@utils/dates";
import TextAreaUi from "./ui/TextArea";
import AutocompleteModal from "./ui/AutocompleteModal";
import styled from "@emotion/styled";
import LoaderSpinner from "./LoaderSpinner";
import { orderBy } from "lodash";
import { formattedPhoneNumber } from "@utils/phoneNumber";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import * as API from "@services";
import SmsAndEmailNotifications from "@components/modals/quickTask/quickJob/SmsAndEmailNotifications";
import { isArrayTruthy } from "@utils/common.js";
import { mapResponsesNotificationContacts } from "@@types/locations/mappers/mapResponseToLocation.mapper";
import createLineaCollection from "@services/collections/create-linea-collection.service";

const frequencies = [
  { label: <Trans i18nKey="frequencies.daily" />, value: "DAILY" },
  { label: <Trans i18nKey="frequencies.weekly" />, value: "WEEKLY" },
  { label: <Trans i18nKey="frequencies.monthly" />, value: "MONTHLY" },
  { label: <Trans i18nKey="frequencies.annualy" />, value: "ANNUALY" },
];

const initialNotificationContactStates = { id: null, name: "", smsOrEmail: "", error: false, delete: false };

const initialNotificationContactState = { 0: initialNotificationContactStates };

const initialState = {
  errors: {
    customerDestinationLocationId: false,
    customerLocationId: false,
    // depositId: false,
    jobTemplateId: false,
    frequency: false,
    recurrence: false,
    startDate: false,
    routeTemplateId: false,
    days_of_month: false,
    days_of_week: false,
    days: false,
  },
  averageLiftingTime: "",
  purchaseOrderNo: "",
  notificationContacts: initialNotificationContactState,
  customerMobilePhoneNumber: "",
  customerDestinationLocationId: "-1",
  customerLocationId: "-1",
  // depositId: "-1",
  frequency: frequencies[0].value,
  jobTemplateId: "-1",
  // noteCommentsColor: "#000",
  // noteComments: "",
  noteSchedule: "",
  noteLocation: "",
  currentCustomerLocationNote: "",
  currentCustomerPurchaseOrderNo: "",
  noteLocationColor: "#000",
  recurrence: { interval: null },
  routeTemplateId: "-1",
  showSpinner: false,
  startDate: formattedDate(),
  supplierLocationId: "-1",
  supplierSecondLocationId: "-1",
  unitId: "-1",
  routeFrequencyWeeklyChecked: true,
  routeFrequencyTwiceMonthlyChecked: true,
  routeFrequencyMonthlyChecked: true,
  units: [],
};

const LiftingFrequencyWrapper = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.basic.lightGrey};
  border-radius: ${({ theme }) => theme.inputBorderRadius};
  padding: ${({ theme }) => theme.inputPadding};
  padding-left: ${({ adornment, theme }) => (adornment === "start" ? "40px" : theme.inputPadding)};
  padding-right: ${({ adornment, theme }) => (adornment === "end" ? "40px" : theme.inputPadding)};
  display: flex;
  align-items: space-between;
  flex-direction: row;
  position: relative;
  padding: 20px;

  .color-picker-label {
    position: absolute;
    top: -6px;
    left: 6px;
    background: #fff;
    padding: 0 5px;
    font-size: 12px;
  }
`;

const ModalRecurrence = (props) => {
  const [state, setState] = useState(initialState);

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchAllSuppliersLocations();
      fetchCustomerLocations();
      await fetchUnits();
      defaultSupplierLocation();
      fetchJobTemplates();
      const { router } = props;
      const { customerItemId } = router.params;
      props.fetchRouteTemplatesByCustomerItem(customerItemId, { limit: "all" });
    };

    fetchInitialData();
  }, []);

  const getErrorMessageFunc = (field) => {
    if (!state.errors[field]) return "";
    return <Trans i18nKey={`errors.${field}`} />;
  };

  const fetchAllSuppliersLocations = async () => {
    await props.fetchAllSuppliersLocations();
  };

  const fetchCustomerLocations = () => {
    const { fetchCustomerLocations, router } = props;
    const { params } = router;
    const { contractId, customerId } = params;

    fetchCustomerLocations(customerId, { limit: -1 }, contractId);
  };

  const fetchUnits = async () => {
    const units = await API.Unit.fetchUnits({ rowsPerPage: "all" });
    updateState({ units: units?.instances ?? [] });
  };

  const createCollection = async (payload) => {
    await createLineaCollection(payload);
  };

  const fetchJobTemplates = async () => {
    const { fetchJobTemplates } = props;
    await fetchJobTemplates();
  };

  const getJobTemplate = (jobTemplateId) => props.jobTemplates.find(({ id }) => id === jobTemplateId) || {};

  const getSupplierLocation = (locationId) => props.locations.find(({ id }) => id === locationId) || {};

  const getRouteTemplate = (routeTemplateId) =>
    props.routeTemplates.flatMap((rt) => rt.children).find(({ id }) => id === routeTemplateId) || {};

  const toggleFrequencyCheckbox = (frequency) => (event) => {
    const stateVariable = `routeFrequency${frequency}Checked`;
    updateState({ [stateVariable]: !state[stateVariable] });
  };

  const handleChangeFieldsFunc = (name) => (event) => {
    updateState({
      errors: {
        ...state.errors,
        [name]: false,
      },
      [name]: event.target.value,
    });
  };

  const handleChangeFrequency = ({ target }) => {
    updateState({
      errors: {
        ...state.errors,
        frequency: false,
        recurrence: false,
      },
      frequency: target.value,
      recurrence: {},
    });
  };

  const handleChangeRecurrenceInterval =
    (type) =>
    ({ target }) => {
      updateState({
        errors: {
          ...state.errors,
          recurrence: false,
        },
        recurrence: {
          ...state.recurrence,
          interval: target.value,
          type,
        },
      });
    };

  const handleChangeRecurrenceDays = (key) => (days) => {
    updateState({
      errors: {
        ...state.errors,
        days_of_month: false,
        days_of_week: false,
        days: false,
      },
      recurrence: {
        ...state.recurrence,
        [key]: days,
      },
    });
  };

  const handleChangeRecurrenceType = (type) => {
    updateState({
      recurrence: {
        ...state.recurrence,
        type,
      },
    });
  };

  const handleChangeStartDate = (date) => {
    updateState({
      errors: {
        ...state.errors,
        startDate: false,
      },
      startDate: formattedDate(date),
    });
  };

  const handleChangeRouteTemplateId =
    (name) =>
    ({ target }) => {
      const routeTemplateSelected = props.routeTemplates
        .flatMap((rt) => rt.children)
        .filter((f) => target.value === f.id);

      updateState({
        errors: {
          ...state.errors,
          [name]: false,
        },
        [name]: target.value,
        name: routeTemplateSelected[0].name,
        jobTemplateId: routeTemplateSelected[0].jobTemplateId,
        supplierLocationId: routeTemplateSelected[0].supplierLocationId,
        averageLiftingTime: routeTemplateSelected[0].averageLiftingTime,
      });
    };

  const handleClose = () => {
    setState(initialState);
    props.onClose();
  };

  const handleDisplayFrequencyComponent = () => {
    const { frequency, recurrence } = state;

    let Component;
    let onIntervalChange;
    let daysError;

    switch (frequency) {
      case "ANNUALY": {
        Component = RecurrenceAnnualy;
        onIntervalChange = handleChangeRecurrenceInterval("yearly");
        break;
      }
      case "MONTHLY": {
        daysError = recurrence.type === "monthly-DOM" ? "days_of_month" : "days_of_week";
        Component = RecurrenceMonthly;
        onIntervalChange = handleChangeRecurrenceInterval;
        break;
      }
      case "WEEKLY": {
        daysError = "days";
        Component = RecurrenceWeekly;
        onIntervalChange = handleChangeRecurrenceInterval("weekly");
        break;
      }
      default: {
        Component = RecurrenceDaily;
        onIntervalChange = handleChangeRecurrenceInterval("daily");
        break;
      }
    }

    return (
      <Component
        daysError={state.errors[daysError]}
        error={getErrorMessageFunc("recurrence")}
        onDaysChange={handleChangeRecurrenceDays}
        onIntervalChange={onIntervalChange}
        onTypeChange={handleChangeRecurrenceType}
        value={recurrence.interval}
      />
    );
  };

  const handleAddAndRemoveNotificationContact = (stateName, value, nestedState = null) => {
    if (nestedState) {
      setState((prevState) => ({
        ...prevState,
        [stateName]: { ...prevState[stateName], [nestedState]: value },
      }));
    } else {
      setState((prevState) => ({ ...prevState, [stateName]: value }));
    }
  };

  const handleChangeValueNotificationContact = (stateName, key, nestedState, value) => {
    setState((prevState) => ({
      ...prevState,
      [stateName]: {
        ...prevState[stateName],
        [key]: {
          ...prevState[stateName][key],
          [nestedState]: value,
        },
      },
    }));
  };

  const isValid = () => {
    const errors = { ...initialState.errors };
    const {
      customerDestinationLocationId,
      jobTemplateId,
      recurrence,
      startDate,
      routeTemplateId,
      notificationContacts,
      frequency,
    } = state;

    let valid = true;
    const { customerItem } = props;
    const { contractType } = customerItem;

    for (const name of ["frequency", "customerLocationId", "jobTemplateId"]) {
      if (state[name] === "-1" && contractType === "job") {
        valid = false;
        errors[name] = true;
      }
    }

    if (jobTemplateId !== "-1" && contractType === "job") {
      const jobTemplate = getJobTemplate(jobTemplateId);

      if (jobTemplate.code === "RE" && customerDestinationLocationId === "-1") {
        valid = false;
        errors.customerDestinationLocationId = true;
      }
    }
    if (routeTemplateId === "-1" && contractType === "route") {
      valid = false;
      errors.routeTemplateId = true;
    }

    if (!startDate && contractType === "job") {
      valid = false;
      errors.startDate = true;
    }

    if (!recurrence.interval) {
      valid = false;
      errors.recurrence = true;
    }

    if (frequency === "MONTHLY" && recurrence.type === "monthly-DOM") {
      if (!recurrence.days_of_month || recurrence.days_of_month.length === 0) {
        valid = false;
        errors.days_of_month = true;
      }
    }

    if (frequency === "MONTHLY" && recurrence.type === "monthly-DOW") {
      if (
        !recurrence.days_of_week ||
        (recurrence.days_of_week &&
          Object.keys(recurrence.days_of_week).filter((week) => recurrence.days_of_week[week].length > 0).length === 0)
      ) {
        valid = false;
        errors.days_of_week = true;
      }
    }

    if (frequency === "WEEKLY") {
      if (!recurrence.days || recurrence.days.length === 0) {
        valid = false;
        errors.days = true;
      }
    }

    const notificationContactsArray = Object.values(notificationContacts);

    notificationContactsArray.map((contact, index) => {
      const activeNotificationContacts = notificationContactsArray.filter((contact) => !contact.delete);

      if (!contact.delete) {
        const phoneNoLength = contact.smsOrEmail.replace(/\D/g, "").length;

        const smsIsValid = [10, 11].includes(phoneNoLength) && !contact.smsOrEmail.includes("@");

        const emailIsValid = contact.smsOrEmail.includes("@");

        const hasDuplicate = activeNotificationContacts.filter((c) => c.smsOrEmail === contact.smsOrEmail).length > 1;

        if ((!smsIsValid && !emailIsValid) || hasDuplicate) {
          if (activeNotificationContacts.length === 1 && contact.smsOrEmail === "") return;

          handleChangeValueNotificationContact("notificationContacts", index, "error", true);

          valid = false;
        }
      }
    });

    updateState({ errors });

    return valid;
  };

  const deleteExistingDefaultSmsOrEmailIfNeeded = (defaultSmsOrEmail) => {
    const hasDefaultSmsOrEmail = defaultSmsOrEmail.id;
    const defaultValueIsEmpty = defaultSmsOrEmail.smsOrEmail === "";

    if (hasDefaultSmsOrEmail && defaultValueIsEmpty) {
      defaultSmsOrEmail.delete = true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isValid()) {
      const { createJob, router, refreshList, createRoute, customerItem } = props;
      const { contractType } = customerItem;

      updateState({ showSpinner: true });

      const {
        averageLiftingTime,
        customerDestinationLocationId,
        customerLocationId,
        jobTemplateId,
        noteLocation,
        noteLocationColor,
        purchaseOrderNo,
        recurrence,
        routeTemplateId,
        name,
        startDate,
        supplierLocationId,
        supplierSecondLocationId,
        unitId,
        routeFrequencyWeeklyChecked,
        routeFrequencyMonthlyChecked,
        routeFrequencyTwiceMonthlyChecked,
        notificationContacts,
      } = state;

      deleteExistingDefaultSmsOrEmailIfNeeded(notificationContacts[0]);

      if (contractType === "job") {
        await createJob(router.params.customerItemId, {
          customer_destination_location_id: customerDestinationLocationId,
          customer_location_id: customerLocationId.value,
          job_template_id: jobTemplateId,
          note_location: noteLocation ? noteLocation.trim() : "",
          note_location_color: noteLocationColor ? noteLocationColor.trim() : "",
          purchase_order_no: purchaseOrderNo ? purchaseOrderNo.trim() : "",
          start_date: startDate,
          supplier_location_id: supplierLocationId,
          supplier_second_location_id: supplierSecondLocationId,
          unit_id: unitId,
          recurrence,
          notification_contacts: notificationContacts,
        });
      }

      if (contractType === "route") {
        await createRoute({
          customer_item_id: router.params.customerItemId,
          job_template_id: jobTemplateId,
          route_template_id: routeTemplateId,
          name,
          average_lifting_time: averageLiftingTime,
          start_date: startDate,
          unit_id: unitId,
          supplier_location_id: supplierLocationId,
          weekly: routeFrequencyWeeklyChecked,
          monthly: routeFrequencyMonthlyChecked,
          twice_monthly: routeFrequencyTwiceMonthlyChecked,
          recurrence,
        });
      }

      if (contractType === "collection") {
        await createCollection({
          contractId: router.params.contractId,
          unitId,
          date: startDate,
          collectionSectorId: routeTemplateId,
          supplierLocationId,
          recurrence,
        });
      }

      setState(initialState);

      refreshList();
    }
  };

  const defaultSupplierLocation = () => {
    const newState = {};
    const { customerItem } = props;
    if (customerItem) {
      const { contractMatterSpec } = customerItem;
      if (contractMatterSpec && contractMatterSpec.length > 0) {
        const supplierLocations = ["supplierLocationId", "supplierSecondLocationId"];
        contractMatterSpec.forEach(({ defaultSupplierLocationId }, index) => {
          if (defaultSupplierLocationId) {
            newState[supplierLocations[index]] = defaultSupplierLocationId;
          }
        });
      }
      if (Object.keys(newState).length > 0) {
        updateState(newState);
      }
    }
  };

  const renderMenuItems = (label, data, key) => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,
    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  const renderLocationsFields = () => {
    const { customerDestinationLocationId, errors, jobTemplateId, supplierLocationId, supplierSecondLocationId } =
      state;

    const { customerLocations, supplierLocations } = props;

    const sortedCustomerLocations = orderBy(customerLocations, ["name", "id"], ["asc", "asc"]);

    const jobTemplate = getJobTemplate(jobTemplateId);

    if (jobTemplate.code === "RE") {
      return (
        <Select
          disabled={jobTemplateId === "-1" || jobTemplate.code.toUpperCase().indexOf("LI") > -1}
          error={errors.customerDestinationLocationId}
          formControlError={errors.customerDestinationLocationId}
          formHelperErrorMsg={getErrorMessageFunc("customerDestinationLocationId")}
          id="cpbr-customer-destination-location"
          inputLabelText={<Trans i18nKey="customer_destination_location" />}
          onChange={handleChangeFieldsFunc("customerDestinationLocationId")}
          value={`${customerDestinationLocationId}`}
        >
          {renderMenuItems(<Trans i18nKey="select_customer_destination_location" />, sortedCustomerLocations, "name")}
        </Select>
      );
    }

    const filteredSupplierLocations = filterSupplierLocations(supplierLocations, jobTemplate.code);

    return (
      <>
        <Select
          fullWidth
          disabled={jobTemplateId === "-1" || jobTemplate.code.toUpperCase().indexOf("LI") > -1}
          error={errors.supplierLocationId}
          formControlError={errors.supplierLocationId}
          formHelperErrorMsg={getErrorMessageFunc("supplierLocation")}
          id="cpbr-supplier-location"
          inputLabelText={<Trans i18nKey="drop_point_1" />}
          onChange={handleChangeFieldsFunc("supplierLocationId")}
          value={`${supplierLocationId}`}
        >
          {renderMenuItems(<Trans i18nKey="select_drop_point" />, filteredSupplierLocations, "name")}
        </Select>
        <Select
          fullWidth
          disabled={jobTemplateId === "-1" || jobTemplate.code.toUpperCase() !== "ROSD_VP_CC"}
          error={errors.supplierSecondLocationId}
          formControlError={errors.supplierSecondLocationId}
          formHelperErrorMsg={getErrorMessageFunc("supplierSecondLocationId")}
          id="cpbr-supplier-location"
          inputLabelText={<Trans i18nKey="drop_point_2" />}
          onChange={handleChangeFieldsFunc("supplierSecondLocationId")}
          value={`${supplierSecondLocationId}`}
        >
          {renderMenuItems(<Trans i18nKey="select_drop_point" />, filteredSupplierLocations, "name")}
        </Select>
      </>
    );
  };

  const renderJob = () => {
    const {
      purchaseOrderNo,
      errors,
      frequency,
      jobTemplateId,
      customerLocationId,
      noteLocation,
      currentCustomerPurchaseOrderNo,
      currentCustomerLocationNote,
      noteLocationColor,
      startDate,
      unitId,
      customerMobilePhoneNumber,
      notificationContacts,
      units,
    } = state;

    const { customerItem, customerLocations, jobTemplates, router } = props;

    const { params } = router;
    const { contractId, customerId } = params;

    const filteredJobTemplates = filterJobTemplatesByCustomerItem(jobTemplates, customerItem);
    const sortedUnit = orderBy(units, ["name", "id"], ["asc", "asc"]);

    return (
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <SelectUi
              error={errors.jobTemplateId}
              formControlError={errors.jobTemplateId}
              formHelperErrorMsg={getErrorMessageFunc("jobTemplateId")}
              id="cpbr-job-template"
              inputLabelText={<Trans i18nKey="flow" />}
              onChange={handleChangeFieldsFunc("jobTemplateId")}
              value={`${jobTemplateId}`}
            >
              {renderMenuItems(<Trans i18nKey="select_flow" />, filteredJobTemplates, "kind")}
            </SelectUi>
          </FormGroupWrapper>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              pb: 3,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              sx={{
                width: "97%",
              }}
            >
              <DatePicker
                error={errors.startDate}
                label="Date"
                value={moment(startDate)}
                onChange={handleChangeStartDate}
                variant="outlined"
                disablePast
              />
            </Stack>
            <Select
              fullWidth
              id="cpbr-unit"
              inputLabelText={<Trans i18nKey="unit" />}
              onChange={handleChangeFieldsFunc("unitId")}
              value={`${unitId}`}
            >
              {renderMenuItems(<Trans i18nKey="select_unit" />, sortedUnit, "name")}
            </Select>
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              pb: 3,
            }}
          >
            {renderLocationsFields()}
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
              pb: 3,
            }}
          >
            <TextFieldUi
              fullWidth
              error={errors.purchaseOrderNo}
              helperText={getErrorMessageFunc("purchaseOrderNo")}
              label={<Trans i18nKey="purchase_order_no" />}
              onChange={handleChangeFieldsFunc("purchaseOrderNo")}
              value={purchaseOrderNo ?? currentCustomerPurchaseOrderNo}
            />
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body1">Notifications SMS ou courriel</Typography>
            <SmsAndEmailNotifications
              notificationContacts={notificationContacts}
              customerMobilePhoneNumber={customerMobilePhoneNumber}
              addNotificationButtonDisabled={
                notificationContacts[0].smsOrEmail === "" ||
                Object.values(notificationContacts).filter((contact) => !contact.delete).length === 4
              }
              copyCellphoneNumberDisabled={notificationContacts[0].smsOrEmail || !customerMobilePhoneNumber}
              setSingleCurrent={handleAddAndRemoveNotificationContact}
              setSingleNestedCurrent={handleChangeValueNotificationContact}
            />
          </Stack>

          <FormGroupWrapper>
            <TextAreaUi
              error={errors.note_location}
              helperText={getErrorMessageFunc("note_location")}
              id="cpbr-note-location"
              label={<Trans i18nKey="preparation.note_location" />}
              onChange={handleChangeFieldsFunc("noteLocation")}
              value={noteLocation ?? currentCustomerLocationNote}
            />
          </FormGroupWrapper>

          <FormGroupWrapper>
            <FlexRowWrapper>
              <SelectHalfUi
                error={errors.frequency}
                formControlError={errors.frequency}
                formHelperErrorMsg={getErrorMessageFunc("frequency")}
                id="cpbr-job-frequency"
                inputLabelText={<Trans i18nKey="frequency" />}
                onChange={handleChangeFrequency}
                value={frequency}
              >
                {frequencies.map(({ label, value }) => (
                  <MenuItem
                    key={value}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                ))}
              </SelectHalfUi>
            </FlexRowWrapper>
          </FormGroupWrapper>

          <FormGroupWrapper>{handleDisplayFrequencyComponent()}</FormGroupWrapper>
        </form>
      </DialogContent>
    );
  };

  const renderRoute = () => {
    const { errors, routeTemplateId, frequency, startDate, averageLiftingTime, unitId, units } = state;
    const { routeTemplates, customerItem } = props;
    const loadingType = customerItem.loadingType === "CCAV" ? "AVANT" : "LATERAL";
    const routeTemplate = getRouteTemplate(routeTemplateId);
    const availableSortedUnit = orderBy(units, ["name", "id"], ["asc", "asc"]).filter(
      (unit) => unit.loadingType === loadingType
    );

    const subRoutes = routeTemplates.flatMap((routeTemplate) => routeTemplate.children);

    return (
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <SelectUi
              error={errors.routeTemplateId}
              formControlError={errors.routeTemplateId}
              formHelperErrorMsg={getErrorMessageFunc("routeTemplateId")}
              id="cpbr-route-template"
              inputLabelText={<Trans i18nKey="routes.route_template" />}
              onChange={handleChangeRouteTemplateId("routeTemplateId")}
              value={routeTemplate.id}
            >
              {renderMenuItems(<Trans i18nKey="select_route_template_id" />, subRoutes, "completeName")}
            </SelectUi>
          </FormGroupWrapper>

          <FormGroupWrapper>
            <FlexRowWrapper>
              <HalfFormControl>
                <TextFieldUi
                  disabled={routeTemplateId !== "-1"}
                  error={errors.averageLiftingTime}
                  helperText={getErrorMessageFunc("averageLiftingTime")}
                  id="route-template-average-lifting-time"
                  label={<Trans i18nKey="route_templates.average_lifting_time" />}
                  onChange={handleChangeFieldsFunc("averageLiftingTime")}
                  value={averageLiftingTime || routeTemplate.averageLiftingTime || ""}
                />
              </HalfFormControl>
            </FlexRowWrapper>
          </FormGroupWrapper>

          <FormGroupWrapper>
            <FlexRowWrapper>
              <HalfFormControl error={errors.startDate}>
                <DatePicker
                  error={errors.startDate}
                  label="Date"
                  value={moment(startDate)}
                  onChange={handleChangeStartDate}
                  variant="outlined"
                  disablePast
                />
                <FormHelperText>{getErrorMessageFunc("startDate")}</FormHelperText>
              </HalfFormControl>
              <HalfFormControl error={errors.startDate}>
                <SelectHalfUi
                  id="cpbr-unit"
                  inputLabelText={<Trans i18nKey="unit" />}
                  onChange={handleChangeFieldsFunc("unitId")}
                  value={`${unitId}`}
                >
                  {renderMenuItems(<Trans i18nKey="select_unit" />, availableSortedUnit, "name")}
                </SelectHalfUi>
              </HalfFormControl>
            </FlexRowWrapper>
          </FormGroupWrapper>

          <FormGroupWrapper style={{ paddingTop: "20px" }}>
            <FlexRowWrapper>
              <SelectHalfUi
                error={errors.frequency}
                formControlError={errors.frequency}
                formHelperErrorMsg={getErrorMessageFunc("frequency")}
                id="cpbr-job-frequency"
                inputLabelText={<Trans i18nKey="frequency" />}
                onChange={handleChangeFrequency}
                value={frequency}
              >
                {frequencies.map(({ label, value }) => (
                  <MenuItem
                    key={value}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                ))}
              </SelectHalfUi>
            </FlexRowWrapper>
          </FormGroupWrapper>

          <FormGroupWrapper>{handleDisplayFrequencyComponent()}</FormGroupWrapper>
        </form>
      </DialogContent>
    );
  };

  const { showSpinner } = state;
  const { open, customerItem } = props;
  const { contractType } = customerItem;

  return (
    <DialogWrapper
      id="recurrence-modal"
      onClose={handleClose}
      open={open}
    >
      {showSpinner && <LoaderSpinner text={<Trans i18nKey="recurrence_creation" />} />}
      <DialogTitle>
        <span style={{ paddingRight: "5px" }}>
          {contractType === "job" ? <Trans i18nKey="common.title.jobs" /> : <Trans i18nKey="route" />}
        </span>
      </DialogTitle>

      {contractType === "job" ? renderJob() : renderRoute()}

      <DialogActions>
        <Button onClick={handleClose}>
          <Trans i18nKey="cancel" />
        </Button>

        <Button
          onClick={handleSubmit}
          variant="contained"
        >
          <Trans i18nKey="add" />
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

ModalRecurrence.propTypes = {
  createJob: PropTypes.func.isRequired,
  createRoute: PropTypes.func.isRequired,
  customerItem: PropTypes.object.isRequired,
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchAllSuppliersLocations: PropTypes.func.isRequired,
  fetchCustomerLocations: PropTypes.func.isRequired,
  fetchRouteTemplatesByCustomerItem: PropTypes.func.isRequired,
  jobTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  routeTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withRoutes(
  withRouteTemplates(
    withJobs(withJobTemplates(withCustomerLocations(withSupplierLocations(withLocations(withRouter(ModalRecurrence))))))
  )
);
