import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import EditOffIcon from "@mui/icons-material/EditOff";

const handleCancelEdit = (setUser, setIsEditingNip) => {
  setIsEditingNip((prevState) => !prevState);

  setUser((prevState) => ({
    ...prevState,
    passwords: {
      ...prevState.passwords,
      nip: { error: false, value: "" },
      confirmNip: { error: false, value: "" },
    },
  }));
};

const handleEditUserTextArea = (userState, setUser) => (event) => {
  setUser((prevState) => ({
    ...prevState,
    passwords: {
      ...prevState.passwords,
      [userState]: { show: prevState.passwords[userState].show, error: false, value: event.target.value },
    },
  }));
};

const handleClickShowPassword = (setUser, field) => {
  setUser((prevState) => ({
    ...prevState,
    passwords: {
      ...prevState.passwords,
      [field]: {
        value: prevState.passwords[field].value,
        error: prevState.passwords[field].error,
        show: !prevState.passwords[field].show,
      },
    },
  }));
};

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

export default function UserNip({ user, setUser, modalType }) {
  const [isEditingNip, setIsEditingNip] = useState(false);
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        pl: 3,
        pr: 3,
        pb: 2,
        width: "100%"
      }}>
      <FormControl variant="outlined" fullWidth>
        {/* NIP */}
        <InputLabel>Nip</InputLabel>
        <OutlinedInput
          type={user.passwords.nip.show ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleClickShowPassword(setUser, "nip")}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                disabled={!isEditingNip && modalType === "editUser"}
                color="primary"
              >
                {user.passwords.nip.show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          autoComplete="new-password"
          label="Nip"
          disabled={!isEditingNip && modalType === "editUser"}
          error={user.passwords.nip.error}
          value={user.passwords.nip.value}
          onChange={handleEditUserTextArea("nip", setUser)}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </FormControl>
      {/* CONFIRM NIP */}
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Confirmer nip</InputLabel>
        <OutlinedInput
          type={user.passwords.confirmNip.show ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleClickShowPassword(setUser, "confirmNip")}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                disabled={!isEditingNip && modalType === "editUser"}
                color="primary"
              >
                {user.passwords.confirmNip.show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          autoComplete="new-password"
          fullWidth
          label="Confirmer nip"
          disabled={!isEditingNip && modalType === "editUser"}
          error={user.passwords.confirmNip.error}
          value={user.passwords.confirmNip.value}
          onChange={handleEditUserTextArea("confirmNip", setUser)}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </FormControl>
      {/* EDIT BUTTON */}
      {modalType === "editUser" && (
        <Stack
          direction={"row"}
          sx={{
            width: "55px",
            alignItems: "center"
          }}>
          <Box>
            {!isEditingNip && (
              <Tooltip title="Modifier">
                <IconButton color="primary" onClick={() => setIsEditingNip((prevState) => !prevState)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            )}

            {isEditingNip && (
              <Tooltip title="Annuler Modification">
                <IconButton color="primary" onClick={() => handleCancelEdit(setUser, setIsEditingNip)}>
                  <EditOffIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
