import React, { FC } from "react";

import { TableCell, TableHead, TableRow } from "@mui/material";

interface TransactionsTableHeadProps {
  headers: string[];
  align?: "left" | "center" | "right" | "justify" | "inherit";
  wrapText?: boolean;
}

const TransactionsTableHead: FC<TransactionsTableHeadProps> = ({ headers, align = "left", wrapText = false }) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map((header, index) => (
          <TableCell
            key={index}
            align={align}
            style={{ whiteSpace: wrapText ? "wrap" : "nowrap" }}
          >
            {header}&nbsp;
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TransactionsTableHead;
