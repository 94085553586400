import React, { useState, useEffect } from "react";
import * as API from "@services";
import PageContainer from "@ui/PageContainer";
import PaperWrapper from "@ui/PaperWrapper";
import useAsync from "@hooks/useAsync";
import TabsWrapper from "@ui/TabsWrapper";
import { sortedData } from "@utils/filtering";
import TransactionsCreditCardsTable from "@components/transactions/credit-cards/TransactionsCreditCardsTable";
import TransactionsClientAccountsTable from "@components/transactions/client-accounts/TransactionsClientAccountsTable";
import TransactionsWebTable from "@components/transactions/web/TransactionsWebTable";
import TransactionsJobsTable from "@components/transactions/jobs/TransactionsJobsTable";
import TransactionsRefundsTable from "@components/transactions/refunds/TransactionsRefundsTable";
import { Container, Tab, TableContainer, Paper, Tabs, useTheme } from "@mui/material";
import { debounce } from "lodash";

const filtersInitialState = {
  limit: 10,
  page: 0,
  filter: "",
  columnName: "transactionDate",
  direction: "desc",
};

const handleTabChange = (setTabValue, setFilters) => (event, value) => {
  setFilters(filtersInitialState);
  setTabValue(value);
};

const handleRowsPerPageChange = (setFilters) => (event) => {
  setFilters((prevState) => ({ ...prevState, limit: event.target.value }));
};

const handleSearchFilter = (setFilters) =>
  debounce((event) => {
    setFilters((prevState) => ({ ...prevState, filter: event.target.value }));
  }, 500);

const fetchPaidCustomerItemsAsync = async (setPaidCustomerItems, setCount, tabValue, filters) => {
  let res;

  const pageNumber = filters.page + 1;

  switch (tabValue) {
    case 0:
      res = await API.PaidCustomerItem.fetchTransactions(pageNumber, filters.limit, filters.filter);
      break;
    case 1:
      res = await API.PaidCustomerItem.fetchWebTransactions(pageNumber, filters.limit, filters.filter);
      break;
    case 2:
      res = await API.PaidCustomerItem.fetchUnusedPaidJobs(pageNumber, filters.limit, filters.filter);
      break;
    case 3:
      res = await API.PaidCustomerItem.fetchRefundedTransactions(pageNumber, filters.limit, filters.filter);
      break;
    case 4:
      break;

    default:
      console.log("[TransactionsPage] fetchPaidCustomerItemsAsync error");
  }

  const sortedResponse = sortedData(res.data.data, filtersInitialState);

  setPaidCustomerItems(sortedResponse);
  setCount(res.data.count);
};

const TransactionsPage = () => {
  const [paidCustomerItems, setPaidCustomerItems] = useState([]);
  const [count, setCount] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [filters, setFilters] = useState(filtersInitialState);

  const theme = useTheme();

  useEffect(() => {
    fetchPaidCustomerItems.run();
  }, [tabValue, filters]);

  const fetchPaidCustomerItems = useAsync(() =>
    fetchPaidCustomerItemsAsync(setPaidCustomerItems, setCount, tabValue, filters)
  );

  return (
    <Container
      maxWidth="xl"
      style={{ background: theme.colors.palette.background.medium }}
    >
      <Tabs
        centered
        value={tabValue}
        textColor="primary"
        indicatorColor="primary"
        onChange={handleTabChange(setTabValue, setFilters)}
      >
        <Tab
          label={"Transactions CC"}
          value={4}
        />
        <Tab
          label={"Transactions Cartes"}
          value={0}
        />
        <Tab
          label={"Transactions web"}
          value={1}
        />
        <Tab
          label={"Tâches payées / non utilisées"}
          value={2}
        />
        <Tab
          label={"Remboursements"}
          value={3}
        />
      </Tabs>
      <TableContainer component={Paper}>
        {tabValue === 0 && (
          <TransactionsCreditCardsTable
            transactions={paidCustomerItems}
            count={count}
            filters={filters}
            setFilters={setFilters}
            handleRowsPerPageChange={() => handleRowsPerPageChange(setFilters)}
            handleSearchFilter={() => handleSearchFilter(setFilters)}
          />
        )}

        {tabValue === 1 && (
          <TransactionsWebTable
            webTransactions={paidCustomerItems}
            count={count}
            filters={filters}
            setFilters={setFilters}
            handleRowsPerPageChange={() => handleRowsPerPageChange(setFilters)}
            handleSearchFilter={() => handleSearchFilter(setFilters)}
          />
        )}

        {tabValue === 2 && (
          <TransactionsJobsTable
            unusedPaidJobs={paidCustomerItems}
            count={count}
            filters={filters}
            setFilters={setFilters}
            handleRowsPerPageChange={() => handleRowsPerPageChange(setFilters)}
            handleSearchFilter={() => handleSearchFilter(setFilters)}
          />
        )}

        {tabValue === 3 && (
          <TransactionsRefundsTable
            refundedTransactions={paidCustomerItems}
            count={count}
            filters={filters}
            setFilters={setFilters}
            handleRowsPerPageChange={() => handleRowsPerPageChange(setFilters)}
            handleSearchFilter={() => handleSearchFilter(setFilters)}
          />
        )}

        {tabValue === 4 && <TransactionsClientAccountsTable />}
      </TableContainer>
    </Container>
  );
};

export default TransactionsPage;
