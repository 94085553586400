interface RGB {
  r: number;
  g: number;
  b: number;
  o: number; // o for opacity
}

export type RGBA = [number, number, number, number];

export function hexToRgb(hex: string): RGBA {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        o: parseInt(result[4], 16),
      }
    : undefined;

  if (!rgb) return [255, 255, 255, 150];

  const { r, g, b, o } = rgb;

  return [r, g, b, o];
}
