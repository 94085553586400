import { formatReportPayload } from "@/services/reports/ReportService";
import { Report } from "@components/report/hooks/report-layout.hook";

import { axiosClientV2 } from "../axios";

const responseType = "blob";

const createProblemCasesReportPhotos = async (reportSettings: Report, customerId: string): Promise<string> => {
  try {
    const res = await axiosClientV2.post<string>(
      `/reports/download_problem_cases_report_photos`,
      { reportSettings: formatReportPayload(reportSettings), customerId },
      {
        responseType,
      }
    );
    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default createProblemCasesReportPhotos;
