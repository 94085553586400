import useAsync from "@hooks/useAsync";
import { FormControl, InputLabel, MenuItem, Paper, Select, Stack, FormControlLabel, Switch } from "@mui/material";
import { isEqual } from "lodash";
import { withListCodes } from "optigo-redux";
import React, { memo, useEffect, useState, useCallback } from "react";
import { isArrayTruthy } from "@utils/common";
import CustomerPrivateCollectionDetails from "@components/customer/CustomerPrivateCollectionDetails";

const SelectItems = (items, isUserAccounting, defaultValue = "", defaultName = "", isPrivateCollection = false) => {
  if (!items || !isArrayTruthy(items)) {
    return (
      <MenuItem
        key={defaultValue}
        value={defaultValue}
      >
        {defaultName}
      </MenuItem>
    );
  }

  return items.map((item) => (
    <MenuItem
      key={item.id}
      value={item.id}
      disabled={(!isUserAccounting && !!item.role_id) || (isPrivateCollection && item.id === "CREDIT_CARD")}
    >
      {item.name}
    </MenuItem>
  ));
};

const handleChangeSaleDetails = (values, updateCustomerSaleDetails) => (event) => {
  const selectedValue = values.find((value) => value.id === event.target.value);
  updateCustomerSaleDetails(event.target.name, { ...selectedValue, error: false });
};

const handleSetSelectsValues = async (code, key, setSelectsValues, currentlyOpenModal, specificListOfCodes) => {
  let res = await specificListOfCodes(code);

  let values = res;

  if (currentlyOpenModal === "new" || currentlyOpenModal === "contract") {
    values = res.filter((t) => !(t.id.includes("PRIVATE") && t.id !== "PRIVATE_COLLECTION"));
  }

  setSelectsValues((prevState) => ({
    ...prevState,
    [key]: values,
  }));
};

const fetchSaleDetailsAsync = async (setSelectsValues, currentlyOpenModal, specificListOfCodes) => {
  try {
    // When component is mounted, fetch the values to put inside our selects
    await handleSetSelectsValues("SALES_TYPES", "saleTypes", setSelectsValues, currentlyOpenModal, specificListOfCodes);
    await handleSetSelectsValues(
      "PAYMENT_TYPES",
      "paymentModes",
      setSelectsValues,
      currentlyOpenModal,
      specificListOfCodes
    );
    await handleSetSelectsValues("_PLANS", "planTypes", setSelectsValues, currentlyOpenModal, specificListOfCodes);

    return true;
  } catch (error) {
    console.warn("[fetchSaleDetailsAsync] error:", error);
    return false;
  }
};

const SaleDetailsContractSelect = ({ selectedContract, contracts, handleChangeContract }) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        shrink
        id="contract-select-label"
      >
        Nom du contrat
      </InputLabel>
      <Select
        notched
        name="contract"
        label="Nom du contrat"
        labelId="contract-select-label"
        value={selectedContract ?? ""}
        onChange={handleChangeContract}
      >
        {contracts &&
          contracts.length > 0 &&
          contracts.map((contract) => (
            <MenuItem
              key={contract.id}
              value={contract}
            >
              {contract.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const setSelectedSaleDetail = (contractSaleDetail, selectsValues) => {
  return contractSaleDetail && selectsValues.find((value) => value.id === contractSaleDetail);
};

function CustomerSaleDetails({
  specificListOfCodes,
  isUserAccounting,
  isLoading,
  saleDetails,
  handlers,
  contracts,
  currentlyOpenModal,
  isContractSelectShowing = true,
}) {
  const { saleType, planType, paymentMode, subContractDetails, requireWeighIn } = saleDetails;
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectsValues, setSelectsValues] = useState({
    planTypes: "",
    saleTypes: "",
    paymentModes: "",
  });
  const [selectsPlanTypes, setSelectsPlanTypes] = useState(selectsValues.planTypes);

  const possiblePrivateCollectionTypes = [
    "PRIVATE_COLLECTION",
    "PRIVATE_ROLL_OFF_COLLECTION",
    "PRIVATE_MOBILE_FLOOR_COLLECTION",
    "PRIVATE_CCAV_COLLECTION",
  ];

  const fetchSaleDetails = useAsync(() =>
    fetchSaleDetailsAsync(setSelectsValues, currentlyOpenModal, specificListOfCodes)
  );

  const handleChangeContract = useCallback((event) => setSelectedContract(event.target.value), [setSelectedContract]);

  const updateFilteredPlanTypes = useCallback(
    (saleTypeId) => {
      let planTypes = selectsValues.planTypes;

      switch (saleTypeId) {
        // TODO: Juste prendre TYPE_DE_VENTE_PLANS svp
        case "CONSTRUCTION_CONTAINER":
          planTypes = planTypes.filter((value) => value.code_type === "CONSTRUCTION_CONTAINER_PLANS");
          break;
        case "DISPOSAL":
          planTypes = planTypes.filter((value) => value.code_type === "DISPOSAL_PLANS");
          break;
        case "PUBLIC_CALL_FOR_TENDERS":
          planTypes = planTypes.filter((value) => value.code_type === "PUBLIC_CALL_FOR_TENDERS_TEST_PLANS");
          break;
        case "PRIVATE_COLLECTION":
          planTypes = {
            rollOff: selectsValues.planTypes.filter((value) => value.code_type === "PRIVATE_ROLL_OFF_COLLECTION_PLANS"),
            mobileFloor: selectsValues.planTypes.filter(
              (value) => value.code_type === "PRIVATE_MOBILE_FLOOR_COLLECTION_PLANS"
            ),
            ccav: selectsValues.planTypes.filter((value) => value.code_type === "PRIVATE_CCAV_COLLECTION_PLANS"),
          };
          break;
        case "PRIVATE_ROLL_OFF_COLLECTION":
          planTypes = selectsValues.planTypes.filter(
            (value) => value.code_type === "PRIVATE_ROLL_OFF_COLLECTION_PLANS"
          );
          break;
        case "PRIVATE_MOBILE_FLOOR_COLLECTION":
          planTypes = selectsValues.planTypes.filter(
            (value) => value.code_type === "PRIVATE_MOBILE_FLOOR_COLLECTION_PLANS"
          );
          break;
        case "PRIVATE_CCAV_COLLECTION":
          planTypes = selectsValues.planTypes.filter((value) => value.code_type === "PRIVATE_CCAV_COLLECTION_PLANS");
          break;
      }

      // If we have a selected planType, we have to clear it first because the
      // filtered plans might not include the previously selected planType.
      if (planType) {
        handlers.setCustomer((prevState) => ({
          ...prevState,
          saleDetails: {
            ...prevState.saleDetails,
            subContractDetails: {
              rollOff: { id: "rollOff", key: "ROLL_OFF", value: "", error: false, checked: false },
              mobileFloor: { id: "mobileFloor", key: "MOBILE_FLOOR", value: "", error: false, checked: false },
              ccav: { id: "ccav", key: "CCAV", value: "", error: false, checked: false },
            },
            planType: { id: "", name: "", error: false },
          },
        }));
      }

      setSelectsPlanTypes(planTypes);
    },
    [selectsValues.planTypes, planType]
  );

  useEffect(() => {
    // Whenever we have the filtered planTypes

    if (selectsPlanTypes && selectedContract) {
      const selectedPlanType = setSelectedSaleDetail(selectedContract.planType, selectsPlanTypes);
      handlers.setCustomer((prevState) => ({
        ...prevState,
        saleDetails: {
          ...prevState.saleDetails,
          planType: { ...prevState.saleDetails.planType, ...selectedPlanType },
        },
      }));
    }
  }, [selectsPlanTypes, selectedContract]);

  useEffect(() => {
    // Fetch the sale details values at mount
    fetchSaleDetails.run();

    return () => {
      // When unmounting, clear the subContractDetails and planType to avoid issues
      const initialSubContractDetails = {
        rollOff: { id: "rollOff", key: "ROLL_OFF", value: "", error: false, checked: false },
        mobileFloor: { id: "mobileFloor", key: "MOBILE_FLOOR", value: "", error: false, checked: false },
        ccav: { id: "ccav", key: "CCAV", value: "", error: false, checked: false },
      };

      handlers.setCustomer((prevState) => ({
        ...prevState,
        saleDetails: {
          ...prevState.saleDetails,
          subContractDetails: initialSubContractDetails,
          planType: { id: "", name: "", error: false },
        },
      }));

      handlers.dispatch({ type: "reset", state: { ...initialSubContractDetails } });
    };
  }, [currentlyOpenModal]);

  useEffect(() => {
    if (
      saleType.id &&
      fetchSaleDetails.value &&
      (selectedContract || currentlyOpenModal === "new" || currentlyOpenModal === "contract")
    ) {
      updateFilteredPlanTypes(saleType.id);
    }
  }, [saleType.id, fetchSaleDetails.value, selectsValues.planTypes, selectedContract]);

  useEffect(() => {
    // Set the first contract as default value once we have the selects values
    if (fetchSaleDetails.value && contracts && isArrayTruthy(contracts) && currentlyOpenModal !== "contract") {
      setSelectedContract(contracts[0]);
    }
  }, [fetchSaleDetails.value]);

  useEffect(() => {
    // When the selected contract changes, set the sale details values from the contract besides planType
    if (selectedContract) {
      const selectedSaleType = setSelectedSaleDetail(selectedContract.saleType, selectsValues.saleTypes);
      const selectedPaymentMode = setSelectedSaleDetail(selectedContract.paymentMode, selectsValues.paymentModes);

      handlers.setCustomer((prevState) => ({
        ...prevState,
        saleDetails: {
          ...prevState.saleDetails,
          // If one of the sale details is undefined, ...undefined will be ignored
          contractID: selectedContract.id,
          requireWeighIn: selectedContract.requireWeighIn,
          saleType: { ...prevState.saleDetails.saleType, ...selectedSaleType },
          paymentMode: { ...prevState.saleDetails.paymentMode, ...selectedPaymentMode },
        },
      }));
    }
  }, [selectedContract]);

  useEffect(() => {
    // If the sale type changes when creating a new contract, we
    // also want to reset the currently selected payment method
    if (saleType?.id && currentlyOpenModal === "contract") {
      handlers.setCustomer((prevState) => ({
        ...prevState,
        saleDetails: {
          ...prevState.saleDetails,
          paymentMode: { id: "", name: "", error: false },
        },
      }));
    }
  }, [saleType, currentlyOpenModal]);

  // When creating a new contract or a new sale, update the requireWeighIn depending on the sale type
  useEffect(() => {
    if (saleType?.id && ["new", "contract"].includes(currentlyOpenModal)) {
      handlers.setCustomer((prevState) => ({
        ...prevState,
        saleDetails: {
          ...prevState.saleDetails,
          requireWeighIn: ["PRIVATE_COLLECTION", "CONSTRUCTION_CONTAINER"].includes(saleType.id),
        },
      }));
    }
  }, [saleType, currentlyOpenModal]);

  return (
    <Paper variant="outlined">
      <Stack
        spacing={3}
        sx={{
          pt: 2,
          px: 1
        }}>
        {isContractSelectShowing && isArrayTruthy(contracts) && (
          <SaleDetailsContractSelect
            selectedContract={selectedContract}
            contracts={contracts}
            handleChangeContract={handleChangeContract}
          />
        )}

        <Stack
          direction="row"
          spacing={2}
        >
          <FormControl
            fullWidth
            error={saleType.error}
          >
            <InputLabel
              shrink
              id="sale-type-label"
            >
              Type de vente
            </InputLabel>
            <Select
              notched
              name="saleType"
              label="Type de vente"
              labelId="sale-type-label"
              disabled={currentlyOpenModal === "edit" || isLoading}
              value={fetchSaleDetails.value ? saleType.id ?? "" : ""}
              onChange={handleChangeSaleDetails(selectsValues.saleTypes, handlers.updateCustomerSaleDetails)}
            >
              {SelectItems(selectsValues.saleTypes, isUserAccounting)}
            </Select>
          </FormControl>

          {saleType.id !== "PRIVATE_COLLECTION" && (
            <FormControl
              fullWidth
              error={false}
            >
              <InputLabel
                shrink
                id="plan-type-label"
              >
                Type de plan
              </InputLabel>
              <Select
                notched
                name="planType"
                label="Type de plan"
                labelId="plan-type-label"
                disabled={!saleType.id || isLoading}
                value={fetchSaleDetails.value ? planType.id ?? "" : ""}
                onChange={handleChangeSaleDetails(selectsPlanTypes, handlers.updateCustomerSaleDetails)}
              >
                {SelectItems(selectsPlanTypes, isUserAccounting)}
              </Select>
            </FormControl>
          )}
        </Stack>

        {saleType.id === "PRIVATE_COLLECTION" && (
          <CustomerPrivateCollectionDetails
            subContractDetails={subContractDetails}
            saleType={saleType}
            isLoading={isLoading}
            selectsPlanTypes={selectsPlanTypes}
            isUserAccounting={isUserAccounting}
            SelectItems={SelectItems}
            handlers={handlers}
          />
        )}

        <FormControl
          fullWidth
          error={paymentMode.error}
        >
          <InputLabel
            shrink
            id="payment-mode-label"
          >
            Mode de paiement
          </InputLabel>

          <Select
            notched
            name="paymentMode"
            label="Mode de paiement"
            labelId="payment-mode-label"
            disabled={isLoading}
            value={fetchSaleDetails.value ? paymentMode.id ?? "" : ""}
            onChange={handleChangeSaleDetails(selectsValues.paymentModes, handlers.updateCustomerSaleDetails)}
          >
            {SelectItems(
              selectsValues.paymentModes,
              isUserAccounting,
              paymentMode.id,
              paymentMode.name,
              possiblePrivateCollectionTypes.includes(saleType?.id)
            )}
          </Select>
        </FormControl>
      </Stack>
      <FormControlLabel
        disabled={!isUserAccounting}
        control={
          <Switch
            checked={requireWeighIn}
            onChange={() => handlers.updateCustomerSaleDetails("requireWeighIn", !requireWeighIn)}
          />
        }
        label="Exiger le bon de pesée au chauffeur (Roll-off seulement)"
        style={{ paddingBottom: 5, paddingTop: 5, paddingLeft: 10 }}
      />
    </Paper>
  );
}

const areEqual = (prevProps, nextProps) => {
  let arePropsEqual = true;

  if (!isEqual(prevProps.currentlyOpenModal, nextProps.currentlyOpenModal)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.contracts, nextProps.contracts)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.saleDetails, nextProps.saleDetails)) {
    arePropsEqual = false;
  }

  if (prevProps.isUserAccounting !== nextProps.isUserAccounting) {
    arePropsEqual = false;
  }

  if (prevProps.isLoading !== nextProps.isLoading) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(withListCodes(CustomerSaleDetails), areEqual);
