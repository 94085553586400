import { Moment } from "moment";
// Externals imports
import moment from "moment/moment";
import React, { ReactElement } from "react";

import { Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/";

type QuickPepDateSelectorProps = {
  value: string;
  onChange: (event: Moment | null) => void;
};

const QuickPepDateSelector = ({ value, onChange }: QuickPepDateSelectorProps): ReactElement => {
  return (
    <Stack
      sx={{
        width: "275px",
      }}
    >
      <DatePicker
        label="Date de début"
        value={value ? moment(value) : moment()}
        onChange={(event) => onChange(event)}
        slotProps={{
          textField: {
            sx: {
              svg: { color: "primary.main" },
            },
          },
        }}
      />
    </Stack>
  );
};

export default QuickPepDateSelector;
