// React imports
import React, { memo, useCallback } from "react";

// External imports
import { isEqual, debounce } from "lodash";
import { Stack, TextField } from "@mui/material";

function LocationNoteTextField({ locationNote, handlers }) {
  // console.log("[LocationNoteTextField] >>> RENDERED");

  const handleChangeLocationNote = useCallback((event) => {
    event && handlers.setSingleCurrent("locationNote", event.target.value);
  }, []);

  return (
    <Stack direction="row" spacing={1} sx={{
      alignItems: "center"
    }}>
      <TextField
        multiline
        rows={4}
        label="Note de l'emplacement"
        value={locationNote || ""}
        onChange={(event) => handleChangeLocationNote(event)}
        fullWidth
        slotProps={{
          inputLabel: {
            shrink: true,
          }
        }}
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { locationNote: prevLocationNote } = prevProps;
  const { locationNote: nextLocationNote } = nextProps;
  let arePropsEqual = true;

  // If the current location note changed, rerender
  if (!isEqual(prevLocationNote, nextLocationNote)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(LocationNoteTextField, areEqual);
