import React, { FC, useState } from "react";
import { Trans } from "react-i18next";

import Dialog from "@/components/ui/dialog/Dialog";
import useCreateCustomerLocation from "@/hooks/customers/create-customer-location.hook";
import { Location } from "@/types/locations/location.type";
import {
  Button,
  DialogActions,
  FormControlLabel,
  MenuItem,
  Switch,
} from "@mui/material";

import FlexRowWrapper from "./ui/FlexRowWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import HalfFormControl from "./ui/HalfFormControl";
import SelectHalfUi from "./ui/SelectHalf";
import TextAreaUi from "./ui/TextArea";
import TextFieldUi from "./ui/TextField";

const initialState = {
  errors: {
    adr1: false,
    adr2: false,
    city: false,
    doorNo: false,
    fileNumber: false,
    note: false,
    postalCode: false,
    province: false,
  },
  adr1: "",
  adr2: "",
  city: "",
  doorNo: "",
  name: "",
  note: "",
  purchaseOrderNo: "",
  postalCode: "",
  province: "QC",
  fileNumber: "",
};

const provinces = [
  { name: <Trans i18nKey="provinces.ab" />, code: "AB" },
  { name: <Trans i18nKey="provinces.bc" />, code: "BC" },
  { name: <Trans i18nKey="provinces.pe" />, code: "PE" },
  { name: <Trans i18nKey="provinces.mb" />, code: "MB" },
  { name: <Trans i18nKey="provinces.nb" />, code: "NB" },
  { name: <Trans i18nKey="provinces.ns" />, code: "NS" },
  { name: <Trans i18nKey="provinces.on" />, code: "ON" },
  { name: <Trans i18nKey="provinces.qc" />, code: "QC" },
  { name: <Trans i18nKey="provinces.sk" />, code: "SK" },
  { name: <Trans i18nKey="provinces.nl" />, code: "NL" },
  { name: <Trans i18nKey="provinces.nu" />, code: "NU" },
  { name: <Trans i18nKey="provinces.nt" />, code: "NT" },
  { name: <Trans i18nKey="provinces.yt" />, code: "YT" },
  { name: <Trans i18nKey="provinces.fl" />, code: "FL" },
];

type ModalCustomerLocationProps = {
  open: boolean;
  title: React.ReactNode | string;
  customerId: number;
  contractId: number;
  contractSaleType?: string;
  onClose: () => void;
  callback?: (location?: Location) => void;
};

const ModalCustomerLocation: FC<ModalCustomerLocationProps> = ({
  open,
  title,
  customerId,
  contractId,
  contractSaleType,
  onClose,
  callback,
}) => {
  const [requireLeedReport, setRequireLeedReport] = useState(false);

  const { mutateAsync: createCustomerLocation } = useCreateCustomerLocation();

  const [state, setState] = useState(initialState);

  const { adr1, adr2, city, doorNo, errors, name, note, postalCode, province, fileNumber, purchaseOrderNo } = state;

  const validateForm = (): boolean => {
    const { postalCode, fileNumber } = state;
    const errors = { ...initialState.errors };
    let valid = true;

    for (const name of ["adr1", "city", "doorNo", "province"] as const) {
      const value = state[name as keyof typeof state];
      if (typeof value === "string" && !value.trim()) {
        valid = false;
        errors[name as keyof typeof errors] = true;
      }
    }

    const canadianPostalCode = new RegExp(
      /[abceghjklmnprstvxy][0-9][abceghjklmnprstvwxyz]\s?[0-9][abceghjklmnprstvwxyz][0-9]/i
    );

    if (!canadianPostalCode.test(postalCode)) {
      valid = false;
      errors.postalCode = true;
      setState((prev) => ({ ...prev, errorMessage: true }));
    } else {
      setState((prev) => ({ ...prev, errorMessage: false }));
    }

    const validFileNumber = new RegExp(/^[0-9\b]+$/);

    // Only run file number validation if the field is not empty
    if (fileNumber && !validFileNumber.test(fileNumber)) {
      valid = false;
      errors.fileNumber = true;
      setState((prev) => ({ ...prev, errorMessage: true }));
    }
    setState((prev) => ({ ...prev, errors }));

    return valid;
  };

  const getErrorMessageFunc = (field: keyof typeof initialState.errors): React.ReactNode | string => {
    if (errors[field]) {
      return <Trans i18nKey={`error.${field}_required`} />;
    }
    return "";
  };

  const handleChangeField = (field: keyof typeof initialState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const handleSubmit = async (): Promise<void> => {
    if (validateForm()) {
      const { adr1, adr2, city, doorNo, name, note, postalCode, province, fileNumber, purchaseOrderNo } = state;

      const fullAddress = doorNo + " " + adr1 + ", " + city + ", " + province + ", " + postalCode;

      const location = await createCustomerLocation({
        id: customerId,
        payload: {
          adr1,
          adr2,
          contractId,
          doorNo,
          postalCode,
          city,
          name: name || fullAddress,
          note,
          province,
          purchaseOrderNo,
          fileNumber,
          requireLeedReport,
        },
      });

      console.log("location", location);

      if (callback) callback(location);
      onClose();
      setState(initialState);
    }
  };

  const renderMenuItems = (
    label: React.ReactNode,
    data: { name: React.ReactNode; code: string }[]
  ): React.ReactNode[] => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,
    ...data.map(({ name, code }) => (
      <MenuItem
        key={code}
        value={code}
      >
        {name}
      </MenuItem>
    )),
  ];

  const handleChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const trimStartPostalCode = event.target.value.trimStart();
    const completedTrimPostalCode = trimStartPostalCode.trimEnd();
    setState((prev) => ({ ...prev, postalCode: completedTrimPostalCode }));
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      title={title}
      showCloseButton={false}
    >
      <form onSubmit={handleSubmit}>
        <FormGroupWrapper>
          <TextFieldUi
            inputProps={{ maxLength: 100 }}
            fullWidth
            id="cpbr-name"
            label={<Trans i18nKey="address.name" />}
            onChange={handleChangeField("name")}
            value={name || ""}
          />
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FlexRowWrapper>
            <HalfFormControl>
              <TextFieldUi
                inputProps={{ maxLength: 10 }}
                error={errors.doorNo}
                fullWidth
                helperText={getErrorMessageFunc("doorNo")}
                id="cpbr-door-no"
                label={<Trans i18nKey="address.door_no" />}
                onChange={handleChangeField("doorNo")}
                value={doorNo.trim() || ""}
              />
            </HalfFormControl>

            <HalfFormControl>
              <TextFieldUi
                inputProps={{ maxLength: 7 }}
                error={errors.postalCode}
                fullWidth
                helperText={<Trans i18nKey="validate_postal_code" />}
                id="cpbr-postal-code"
                label={<Trans i18nKey="address.postal_code" />}
                onChange={handleChangePostalCode}
                value={postalCode.trim() || ""}
              />
            </HalfFormControl>
          </FlexRowWrapper>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <TextFieldUi
            inputProps={{ maxLength: 50 }}
            error={errors.adr1}
            fullWidth
            helperText={getErrorMessageFunc("adr1")}
            id="cpbr-adr1"
            label={<Trans i18nKey="address.adr_1" />}
            onChange={handleChangeField("adr1")}
            value={adr1 || ""}
          />
        </FormGroupWrapper>

        <FormGroupWrapper>
          <TextFieldUi
            inputProps={{ maxLength: 50 }}
            error={errors.adr2}
            fullWidth
            helperText={getErrorMessageFunc("adr2")}
            id="cpbr-adr2"
            label={<Trans i18nKey="address.adr_2" />}
            onChange={handleChangeField("adr2")}
            value={adr2 || ""}
          />
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FlexRowWrapper>
            <HalfFormControl>
              <TextFieldUi
                inputProps={{ maxLength: 45 }}
                error={errors.city}
                fullWidth
                helperText={getErrorMessageFunc("city")}
                id="cpbr-city"
                label={<Trans i18nKey="address.city" />}
                onChange={handleChangeField("city")}
                value={city || ""}
              />
            </HalfFormControl>

            <SelectHalfUi
              className="cpbr-province-select"
              formControlError={errors.province}
              formHelperErrorMsg={getErrorMessageFunc("province")}
              formControlWidthClass="w-full"
              id="cpbr-province"
              inputLabelText={<Trans i18nKey="address.province" />}
              onChange={handleChangeField("province")}
              value={province}
            >
              {renderMenuItems(<Trans i18nKey="select_province" />, provinces)}
            </SelectHalfUi>
          </FlexRowWrapper>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FlexRowWrapper>
            <HalfFormControl>
              <TextFieldUi
                inputProps={{ maxLength: 20 }}
                fullWidth
                id="cpbr-file-number"
                label={<Trans i18nKey="location.file_number" />}
                onChange={handleChangeField("fileNumber")}
                value={fileNumber || ""}
              />
            </HalfFormControl>
            <HalfFormControl>
              <TextFieldUi
                inputProps={{ maxLength: 255 }}
                className="cpbr-purchase-order-no"
                id="cpbr-purchase-order-no"
                label={<Trans i18nKey="purchase_order_no" />}
                onChange={handleChangeField("purchaseOrderNo")}
                value={purchaseOrderNo || ""}
              />
            </HalfFormControl>
          </FlexRowWrapper>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <TextAreaUi
            className="cpbr-note"
            error={errors.note}
            helperText={getErrorMessageFunc("note")}
            id="cpbr-note"
            label={<Trans i18nKey="address.note" />}
            onChange={handleChangeField("note")}
            value={note || ""}
          />
        </FormGroupWrapper>

        <FormControlLabel
          disabled={contractSaleType !== "CONSTRUCTION_CONTAINER"}
          control={
            <Switch
              checked={requireLeedReport}
              onChange={() => setRequireLeedReport((prev) => !prev)}
            />
          }
          label="Rapport Leed"
        />
      </form>

      <DialogActions>
        <Button onClick={onClose}>
          <Trans i18nKey="cancel" />
        </Button>

        <Button
          onClick={handleSubmit}
          variant="contained"
        >
          <Trans i18nKey="add_location" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCustomerLocation;
