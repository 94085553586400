import React, { Dispatch, ReactElement, SetStateAction, useMemo } from "react";
import DialogWrapper from "@ui/DialogWrapper";
import { Button, Stack, Typography } from "@mui/material";
import LoadingButton from "@ui/LoadingButton";
import { AddTask } from "@mui/icons-material";
import useQuickPepLayout from "@components/modals/quickTask/quickPep/hooks/quick-pep-layout.hook";
import QuickPepCustomersDropdown from "@components/modals/quickTask/quickPep/QuickPepCustomersDropdown";
import QuickPepContractsDropdown from "@components/modals/quickTask/quickPep/QuickPepContractsDropdown";
import QuickPepCollectionSectorsDropdown from "@components/modals/quickTask/quickPep/QuickPepCollectionSectorsDropdown";
import QuickPepUnitsDropdown from "@components/modals/quickTask/quickPep/QuickPepUnitsDropdown";
import QuickPepDateSelector from "@components/modals/quickTask/quickPep/QuickPepDateSelector";
import QuickPepSupplierLocationsDropdown from "@components/modals/quickTask/quickPep/QuickPepSupplierLocationsDropdown";

export type UnitBase = {
  value: number;
  label: string;
};

type QuickPepLayoutProps = {
  isOpen: boolean;
  onClose: () => void;
  units: UnitBase[];
  Header: ReactElement;
  taskType: string;
  setTaskType: Dispatch<SetStateAction<string>>;
};

const QuickPepLayout = ({
  isOpen,
  onClose,
  units,
  Header,
  taskType,
  setTaskType,
}: QuickPepLayoutProps): ReactElement => {
  const {
    customers,
    selectedCollection,
    collectionSectors,
    handleChangeSelectedValue,
    contracts,
    handleSubmitQuickPep,
    supplierLocations,
    handleChangeStartDate,
  } = useQuickPepLayout(onClose);

  const filteredUnits = units.filter((unit) => unit.label.includes("LA"));

  const headerProps = useMemo(
    () => ({
      taskType,
      setTaskType,
      topLeft: <Typography variant="h6">Ajouter une collecte</Typography>,
      topRight: <></>,
      bottomRight: <></>,
    }),
    [setTaskType, taskType]
  );

  return (
    <DialogWrapper
      onClose={onClose}
      open={isOpen}
      width={"600px"}
    >
      <Stack>
        {/*@ts-ignore*/}
        <Header {...headerProps} />

        <Stack
          spacing={3}
          sx={{
            minHeight: 500,
            px: 3,
            pb: 2
          }}>
          <QuickPepCustomersDropdown
            customers={customers}
            value={selectedCollection.customer.value}
            error={selectedCollection.customer.error}
            onChange={handleChangeSelectedValue}
          />

          {selectedCollection.customer.value !== "" && (
            <QuickPepContractsDropdown
              contracts={contracts}
              value={selectedCollection.contract.value}
              error={selectedCollection.contract.error}
              onChange={handleChangeSelectedValue}
            />
          )}

          {selectedCollection.contract.value !== "" && (
            <QuickPepCollectionSectorsDropdown
              collectionSectors={collectionSectors}
              value={selectedCollection.collectionSector.value}
              error={selectedCollection.collectionSector.error}
              onChange={handleChangeSelectedValue}
            />
          )}

          {selectedCollection.collectionSector.value !== "" && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "space-between"
              }}
            >
              <QuickPepDateSelector
                value={selectedCollection.date.value}
                onChange={handleChangeStartDate}
              />
              <QuickPepUnitsDropdown
                units={filteredUnits}
                value={selectedCollection.unit.value}
                error={selectedCollection.unit.error}
                onChange={handleChangeSelectedValue}
              />
            </Stack>
          )}

          {selectedCollection.collectionSector.value !== "" && (
            <QuickPepSupplierLocationsDropdown
              supplierLocations={supplierLocations}
              value={selectedCollection.supplierLocation.value}
              error={selectedCollection.supplierLocation.error}
              onChange={handleChangeSelectedValue}
            />
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "flex-end",
            height: "64px",
            alignItems: "center"
          }}>
          {/* BUTTONS */}
          <Stack
            direction="row"
            spacing={1}
            sx={{
              height: "fit-content"
            }}
          >
            <Button onClick={onClose}>Annuler</Button>

            <LoadingButton
              loading={false}
              startIcon={<AddTask />}
              onClick={handleSubmitQuickPep}
              loadingPosition="start"
              color={"primary"}
              disabled={false}
            >
              Ajouter
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </DialogWrapper>
  );
};

export default QuickPepLayout;
