// React imports
import React, { memo, useCallback, useState } from "react";

// External iports
import Select from "react-select";
import { isEqual, orderBy, map } from "lodash";
import { Stack, IconButton, Tooltip } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { isArrayTruthy, formattedReactSelect } from "@utils/common";
import ModalContract from "@components/modals/contract/ModalContract";

// Format all the customerItems and each customerItem's job_templates (flows)
const formatCustomerItems = (customerItems) => {
  if (!isArrayTruthy(customerItems)) return;

  const customerItemsWithFormattedFlows = map(customerItems, (customerItem) => {
    const formattedJobTemplates = formattedReactSelect(customerItem.job_templates, "id", "kind");
    customerItem.job_templates = { data: formattedJobTemplates, loading: false };

    return customerItem;
  });

  const formattedCustomerItems = formattedReactSelect(customerItemsWithFormattedFlows, "id", "name");

  return formattedCustomerItems;
};

function ContractSelect({ isError, contract, contracts, handlers, customer }) {
  // console.log("[ContractSelect] >>> RENDERED");
  const [openModalContract, setOpenModalContract] = useState(false);
  const handleChangeContract = useCallback((contract) => {
    contract && handlers.setSingleCurrent("contract", contract);
    contract && contract.data && handlers.setSingleData("customerItems", contract.data.customerItems);
  }, []);

  const handleOpenModalContract = () => {
    setOpenModalContract((prevState) => !prevState);
  };

  const handleCloseContractModal = (contract) => {
    if (contract && contract.status && contract.status === "success") {
      const newContractParent = {
        value: contract.data.id,
        label: contract.data.name,
        data: contract.data,
      };

      const subContractDetails = contract.data.subContractDetails.map((subContractDetail) => {
        return {
          value: subContractDetail.id,
          label: subContractDetail.name,
          data: subContractDetail,
        };
      });

      const newCreatedContract = isArrayTruthy(subContractDetails)
        ? subContractDetails.length === 1
          ? subContractDetails[0]
          : subContractDetails
        : newContractParent;

      if (isArrayTruthy(newCreatedContract)) {
        newCreatedContract.map((contract) => {
          const customerItems = formatCustomerItems(contract.data.customerItems);
          return (contract.data.customerItems = { data: customerItems, loading: false });
        });
      } else {
        const customerItems = formatCustomerItems(newCreatedContract.data.customerItems);
        newCreatedContract.data.customerItems = { data: customerItems, loading: false };
      }

      const orderedContracts = orderBy(contracts.data.concat(newCreatedContract), ["label"], ["asc"]);

      handlers.setSingleData("contracts", orderedContracts, "data");

      !isArrayTruthy(newCreatedContract) && handleChangeContract(newCreatedContract);
    }
    setOpenModalContract((prevState) => !prevState);
  };

  return (
    <>
      {openModalContract && (
        <ModalContract
          open={openModalContract}
          onClose={handleCloseContractModal}
          customer={customer}
        />
      )}
      <Stack
        direction="row"
        sx={{
          alignItems: "center"
        }}
      >
        <Select
          styles={{
            container: (base) => ({ ...base, flex: 1 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (base) => ({
              ...base,
              height: 42,
              boxShadow: isError ? "none" : base.boxShadow,
              borderColor: isError ? "red" : base.borderColor,
              "&:hover": {
                borderColor: isError ? "red" : base["&:hover"].borderColor,
              },
            }),
          }}
          value={contract.value ? contract : null}
          isDisabled={contracts.loading}
          isLoading={contracts.loading}
          menuPosition="fixed"
          onChange={(contract) => handleChangeContract(contract)}
          options={contracts.data}
          placeholder={"Sélectionner un contrat"}
        />

        <Stack
          direction="row"
          sx={{
            width: "80px",
            ml: 1
          }}>
          <Tooltip
            title="Ajouter"
            placement="top"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -10] },
                  },
                ],
              }
            }}
          >
            <IconButton
              color="primary"
              size="small"
              onClick={handleOpenModalContract}
            >
              <Add sx={{ fontSize: 30 }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Rechercher"
            placement="top"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -10] },
                  },
                ],
              }
            }}
          >
            {/* The <span> is the show the tooltip on disabled button or else console error */}
            <span>
              <IconButton
                color="primary"
                size="small"
                disabled
              >
                <Search sx={{ fontSize: 30 }} />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
    </>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { isError: prevIsError, contract: prevContract, contracts: prevContracts } = prevProps;
  const { isError: nextIsError, contract: nextContract, contracts: nextContracts } = nextProps;
  let arePropsEqual = true;

  // If the accounting-items array changed, rerender
  if (!isEqual(prevContracts, nextContracts)) {
    arePropsEqual = false;
  }

  // If the current contract changed, rerender
  if (!isEqual(prevContract, nextContract)) {
    arePropsEqual = false;
  }

  // If the error changed, rerender
  if (!isEqual(prevIsError, nextIsError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(ContractSelect, areEqual);
