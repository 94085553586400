import { filter, find, orderBy } from 'lodash';

export const filteringState = {
  filter: '',
  secondFilter: '',
  limit: 10,
  page: 0,
};

// eslint-disable-next-line no-shadow
export const handleFilter = (callback, secondFilter = false) =>
  (function filter(event) {
    event.persist();
    const filterRank = secondFilter
      ? { secondFilter: event.target.value }
      : { filter: event.target.value };
    this.setState(filterRank, callback);
  });

export const handlePageChange = callback =>
  (function pageChange(_, page) {
    this.setState(
      {
        page,
        sort: this.initialSort,
      },
      callback,
    );
  });

export const handleRowsPerPageChange = callback =>
  (function rowsPerPage({ target }) {
    this.setState(
      {
        limit: target.value,
        page: 0,
        sort: this.initialSort,
      },
      callback,
    );
  });


export const handleSortFunctional = function sort(name, state, setState) {
  return () => {
    const { columnName, direction } = state;

    let newColumnName = name;
    let newDirection = 'asc';

    if (columnName === name) {
      switch (direction) {
        case 'asc': {
          newDirection = 'desc';
          break;
        }
        case 'desc': {
          newColumnName = null;
          newDirection = undefined;
          break;
        }
        default: {
          break;
        }
      }
    }

    setState({
      ...state,
      columnName: newColumnName,
      direction: newDirection,
    });
  };
};

export const handleSort = function sort(name) {
  return () => {
    const { columnName, direction } = this.state.sort;

    let newColumnName = name;
    let newDirection = 'asc';

    if (columnName === name) {
      switch (direction) {
        case 'asc': {
          newDirection = 'desc';
          break;
        }
        case 'desc': {
          newColumnName = null;
          newDirection = undefined;
          break;
        }
        default: {
          break;
        }
      }
    }

    this.setState({
      sort: {
        columnName: newColumnName,
        direction: newDirection,
      },
    });
  };
};

export const sortedData = (data, state) => {
  const { columnName, direction } = state;

  return orderBy(data, [columnName], [direction]);
};

export const filterSupplierLocations = (supplierLocations, jobCode) => {
  let filteredLocations = supplierLocations;

  if (['PLAD_VP', 'PLAD_VR'].indexOf(jobCode) > -1) {
    filteredLocations = filter(
      supplierLocations,
      location =>
        location.name.toUpperCase().indexOf('VILLÉCO') > -1 ||
        location.name.toUpperCase().indexOf('AUTRES') > -1,
    );
  }

  if (jobCode === 'MECA') {
    return [];
  }

  return filteredLocations;
};

const filterJobTemplates = (jobTemplates, customerItem) => {
  const { contractDispositionFlow, contractItemSku, contractOperationalFlow } = customerItem;
  const flow = contractOperationalFlow + contractDispositionFlow;

  if (contractItemSku === 'S-MECANIQUE') {
    return filter(jobTemplates, jt => jt.code.toUpperCase() === 'MECA');
  }

  if (contractItemSku === 'ASS-INTERNE') {
    return filter(jobTemplates, jt => jt.code.toUpperCase() === 'TRNSP_MT');
  }

  switch (flow) {
    case 'CCASD':
      return filter(
        jobTemplates,
        jt => jt.code.toUpperCase() === 'RE' || jt.code.toUpperCase().indexOf('CA_') > -1,
      );
    case 'PLAD':
      return filter(
        jobTemplates,
        jt => jt.code.toUpperCase() === 'RE' || jt.code.toUpperCase().indexOf('PLAD_') > -1,
      );
    case 'ROAD':
      return filter(
        jobTemplates,
        jt => jt.code.toUpperCase() === 'RE' || jt.code.toUpperCase().indexOf('ROAD_') > -1,
      );
    case 'ROSD':
      return filter(
        jobTemplates,
        jt => jt.code.toUpperCase() === 'RE' || jt.code.toUpperCase().indexOf('ROSD_') > -1,
      );
    default:
      return jobTemplates;
  }
};

// eslint-disable-next-line max-len
export const filterJobTemplatesByCustomerItem = (
  jobTemplates,
  customerItems,
  customerItemId = null,
) => {
  let chosenCustomerItem;

  if (customerItemId) {
    chosenCustomerItem = find(customerItems, ci => ci.id === customerItemId.toString());
  } else {
    chosenCustomerItem = customerItems;
  }

  return filterJobTemplates(jobTemplates, chosenCustomerItem);
};

export const removeRecurringCustomerItems = customerItems =>
  filter(customerItems, ci => ci.contractItemSku.indexOf('-M-') === -1);

// Returns a string of the status depending on the status of the route location
export const getLocationStatus = (status) => {
  switch (status) {
    case 'unfinished':
      return 'À faire';
    case 'finished_with_anomaly':
      return 'Terminé avec cas problème(s)';
    case 'finished_without_anomaly':
      return 'Terminé';
    case 'finished_with_only_anomaly':
      return 'Cas problème';
    default:
      return 'À faire';
  }
};

// Returns an hex color code depending on the status of the route location
export const getLocationStatusColor = (status) => {
  switch (status) {
    case 'unfinished':
      return '#CBCACB'; // Gray color
    case 'finished_with_anomaly':
      return '#FDBE02'; // Yellow color
    case 'finished_without_anomaly':
      return '#A3C52D'; // Green color
    case 'finished_with_only_anomaly':
      return '#E73B2F'; // Red color
    default:
      return '#CBCACB'; // Gray color
  }
};
