import React, { FC } from "react";

import { Button, ButtonProps } from "@mui/material";

type LoadingButtonProps = ButtonProps & {
  loading: boolean;
  loadingPosition?: "start" | "end";
  children: React.ReactNode;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  disabled?: boolean;
  startIcon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: "contained" | "outlined" | "text";
};

const LoadingButton: FC<LoadingButtonProps> = ({
  color,
  loading,
  disabled,
  startIcon,
  onClick,
  loadingPosition,
  children,
  ...remainingProps
}) => {
  return (
    <Button
      color={color}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      startIcon={startIcon}
      loadingPosition={loadingPosition}
      variant="contained"
      {...remainingProps}
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
