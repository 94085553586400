// React imports
import React, { memo, useCallback } from "react";

// External imports
import { isEqual } from "lodash";
import { Button, Stack, TextField, Tooltip } from "@mui/material";
import { formattedPhoneNumber } from "@utils/phoneNumber";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function PurchaseOrderNoSmsAlertTextField({
  isError,
  purchaseOrderNo,
  smsAlertPhoneNumber,
  customerMobilePhoneNumber,
  handlers,
}) {
  // console.log("[PurchaseOrderNoSmsAlertTextField] >>> RENDERED");

  const handleChangePurchaseOrderNo = useCallback((event) => {
    event && handlers.setSingleCurrent("purchaseOrderNo", event.target.value);
  }, []);

  return (
    <TextField
      label="Numéro du bon de commande"
      value={purchaseOrderNo || ""}
      onChange={(event) => handleChangePurchaseOrderNo(event)}
      fullWidth
      sx={{
        ".MuiInputBase-input": {
          padding: "0 0 0 25px",
          height: 42,
        },
      }}
      slotProps={{
        inputLabel: {
          shrink: true,
        }
      }}
    />
  );
}

const areEqual = (prevProps, nextProps) => {
  let arePropsEqual = true;

  // If the current purchase order no changed, rerender
  if (!isEqual(prevProps.purchaseOrderNo, nextProps.purchaseOrderNo)) {
    arePropsEqual = false;
  }

  // If the current sms phone number changed, rerender
  if (!isEqual(prevProps.smsAlertPhoneNumber, nextProps.smsAlertPhoneNumber)) {
    arePropsEqual = false;
  }

  // If the current error changed, rerender
  if (!isEqual(prevProps.isError, nextProps.isError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(PurchaseOrderNoSmsAlertTextField, areEqual);
