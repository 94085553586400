import React, { ReactElement } from "react";

import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

const DownloadIcon = ({
  size = defaultIconProps.size,
  strokeColor = "#FFFFFF",
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.5 5.16667V0.5M3.5 5.16667L1.35714 3.16667M3.5 5.16667L5.64286 3.16667M6 6.5H1"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default DownloadIcon;
