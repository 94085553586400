// External imports
import {
  Badge as BadgeMui,
  Button,
  DialogActions,
  MenuItem,
  Tab,
  Tooltip,
  Typography,
  Box,
  Stack,
  TextField,
} from "@mui/material";
import moment from "moment";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { ContentCopy, Edit } from "@mui/icons-material";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { withCustomerItem, withJob } from "optigo-redux";

// Local imports
import useUnit from "@hooks/units";
import * as API from "@services";
import useAsync from "@hooks/useAsync";
import useToggle from "@hooks/useToggle";
import { formattedDate, formattedDateDayMonthYear } from "@utils/dates";
import useBackdrop from "@hooks/useBackdrop";
import useLogStateChange from "@hooks/useLogStateChange";
import useApiResponseHandler from "@hooks/useApiResponseHandler";
// Utils
import { jobStatuses } from "@utils/statuses";
import { withRouter } from "@utils/withRouter";
// Components
import Breadcrumbs from "@components/Breadcrumbs";
import CommentsList from "@components/CommentsList";
import ModalEditJob from "@components/ModalEditJob";
import Interruption from "@components/Interruption";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InterventionsList from "@components/InterventionsList";
import ModalJobCopy from "@components/modals/job/ModalJobCopy";
import ModalRefund from "@components/modals/refund/ModalRefund";
// UI
import PageHeader from "@ui/PageHeader";
import SelectHalfUi from "@ui/SelectHalf";
import TabsWrapper from "@ui/TabsWrapper";
import HeaderLabel from "@ui/HeaderLabel";
import HeaderColumnWrapper from "@ui/HeaderColumnWrapper";
import FlexHeaderRowWrapper from "@ui/FlexHeaderRowWrapper";
import FloatingActionButton from "@ui/FloatingActionButton";
import HeaderContextWrapper from "@ui/HeaderContextWrapper";
import { Trans } from "react-i18next";

const Badge = styled(BadgeMui)`
  & .badge {
    right: -22px;
    transform: scale(0.8);
  }

  right: 10px;
`;

const BundleNote = styled.div`
  max-width: 400px;
  margin-top: 5px;
  font-style: italic;
`;

const CustomHeaderLabel = styled.div`
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #b9b9b9;
  }
`;

const StatusBadge = styled.span`
  position: absolute;
  top: 8px;
  left: -25px;
  ${({ status, theme }) => `
    background-color: ${theme.colors.components.taskStatus[status]};
  `}
  height: 14px;
  width: 14px;
  padding: 0;
  border-radius: 14px;
`;

const RefundButton = ({ paidCustomerItem, isInterrupted, refundModalHandler }) => {
  return (
    <Box
      sx={{
        pt: 2,
      }}
    >
      <Tooltip
        title={
          paidCustomerItem
            ? paidCustomerItem?.refunded
              ? "Le paiement a déjà été remboursé."
              : ""
            : "Aucun paiement n'est relié avec cette tâche ou il a déjà été remboursé."
        }
        placement="right"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: { offset: [0, -10] },
              },
            ],
          },
        }}
      >
        {/* The <span> is to prevent a console error from Tooltip when button is disabled */}
        <span>
          <Button
            color="info"
            disabled={paidCustomerItem?.refunded ?? true}
            variant="contained"
            sx={{ background: "#635bff" }}
            onClick={refundModalHandler.setState.on}
          >
            {isInterrupted ? "Rembourser avec déplacement inutile" : "Rembourser la tâche"}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

const RefundMessage = ({ refundableItem, hasInterruption }) => {
  const {
    totalAmount,
    refundableAmount: itemRefundableAmount,
    unnecessaryTravelRefundAmount: itemUnnecessaryTravelRefundAmount,
    invoiceDetails: {
      DELIVERY_ZONE_FEE,
      DELIVERY_ZONE_FUEL_FEE,
      UNNECESSARY_TRAVEL_FEE,
      UNNECESSARY_TRAVEL_FUEL_FEE,
      UNNECESSARY_TRAVEL_FEE_SUB_TOTAL,
      UNNECESSARY_TRAVEL_FEE_TOTAL,
    } = {},
  } = refundableItem || {};

  const initialAmount = totalAmount || "montant inconnu";
  const refundableAmount = (itemRefundableAmount / 100)?.toFixed(2) || "montant inconnu";
  const deliveryZoneFee = DELIVERY_ZONE_FEE?.toFixed(2) || "montant inconnu";
  const deliveryZoneFuelFee = DELIVERY_ZONE_FUEL_FEE?.toFixed(2) || "montant inconnu";
  const unnecessaryTravelFee = UNNECESSARY_TRAVEL_FEE?.toFixed(2) || "montant inconnu";
  const unnecessaryTravelFuelFee = UNNECESSARY_TRAVEL_FUEL_FEE?.toFixed(2) || "montant inconnu";
  const unnecessaryTravelFeeSubTotal = UNNECESSARY_TRAVEL_FEE_SUB_TOTAL?.toFixed(2) || "montant inconnu";
  const unnecessaryTravelFeeTotal = UNNECESSARY_TRAVEL_FEE_TOTAL?.toFixed(2) || "montant inconnu";
  const unnecessaryTravelRefundAmount = (itemUnnecessaryTravelRefundAmount / 100).toFixed(2) || "montant inconnu";

  return (
    <>
      {hasInterruption ? (
        <Stack spacing={2}>
          <Typography>Vous allez effectuer le remboursement suivant:</Typography>

          <Stack>
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Montant initial:
              </Typography>
              <Typography>{initialAmount}$</Typography>
            </Stack>

            <Stack
              sx={{
                py: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Frais associé à la zone de livraison:
              </Typography>

              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    pl: 4,
                  }}
                >
                  Frais de base:
                </Typography>
                <Typography>{deliveryZoneFee}$</Typography>
              </Stack>

              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    pl: 4,
                  }}
                >
                  Frais de carburant:
                </Typography>
                <Typography>{deliveryZoneFuelFee}$</Typography>
              </Stack>
            </Stack>

            <Stack
              sx={{
                py: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Frais de déplacement inutile:
              </Typography>

              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    pl: 4,
                  }}
                >
                  Frais de base:
                </Typography>
                <Typography>{unnecessaryTravelFee}$</Typography>
              </Stack>

              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    pl: 4,
                  }}
                >
                  Frais de carburant:
                </Typography>
                <Typography>{unnecessaryTravelFuelFee}$</Typography>
              </Stack>
            </Stack>

            <Stack
              sx={{
                justifyContent: "space-between",
                py: 1,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography>Sous-total:</Typography>
                <Typography>{unnecessaryTravelFeeSubTotal}$</Typography>
              </Stack>

              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography>Total avec taxes:</Typography>
                <Typography>{unnecessaryTravelFeeTotal}$</Typography>
              </Stack>

              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  py: "2px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Total remboursé:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    textDecorationLine: "underline",
                    textUnderlineOffset: 4,
                  }}
                >
                  {unnecessaryTravelRefundAmount}$
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Typography variant="h6">Êtes-vous sûr de vouloir procéder au remboursement?</Typography>
        </Stack>
      ) : (
        <Typography>
          Êtes-vous sûr(e) de vouloir rembourser la somme de <strong>{refundableAmount}$</strong> pour cette tâche?
        </Typography>
      )}
    </>
  );
};

function JobPage(props) {
  const { job, jobLoading, customerItem, fetchJob: fetchJobRedux, router } = props;

  const fetchJobAsync = useAsync(async () => await fetchJobRedux(router.params.jobId));
  const computePriceAsync = useAsync(
    async () =>
      await API.CustomerItem.fetchJobComputedPrice(
        job.customerItemId,
        job.jobTemplateId,
        job.customerLocationId,
        job.customerDestinationLocationId
      )
  );

  const { data: units } = useUnit.fetchUnits({ rowsPerPage: "all" });

  const priceTotal = computePriceAsync.value?.data?.total;
  const isCurrentlyBillable = job?.isCurrentlyBillable ?? false;
  const sortedUnit = orderBy(units?.instances || [], ["name", "id"], ["asc", "asc"]);
  const refundableItem = job?.interruptedRefundablePaidCustomerItem
    ? job.interruptedRefundablePaidCustomerItem
    : job?.paidCustomerItem;

  const backdrop = useBackdrop(isCurrentlyBillable && !job?.alreadyPaid ? "En attente de la transaction..." : "");
  const refundModalHandler = useToggle(false);
  const handleApiResponse = useApiResponseHandler();

  const [unitId, setUnitId] = useState("-1");
  const [currentTab, setCurrentTab] = useState(0);
  const [jobModalOpened, setJobModalOpened] = useState(false);
  const [jobCopyModalOpened, setJobCopyModalOpened] = useState(false);
  const [startDateCopyJob, setStartDateCopyJob] = useState(formattedDate());

  useEffect(() => {
    // Only compute the price when the job is finished being fetched and it's billable
    !fetchJobAsync.loading && isCurrentlyBillable && computePriceAsync.run();
  }, [fetchJobAsync.loading]);

  useEffect(() => {
    const initMount = async () => {
      await fetchJobAsync.run();
    };

    initMount();
  }, []);

  const handleChangeTab = useCallback((_, tab) => setCurrentTab(tab), []);
  const handleToggleJobModal = useCallback((openState) => () => setJobModalOpened(openState), []);
  const handleToggleJobCopyModal = useCallback(() => setJobCopyModalOpened((prevState) => !prevState), []);
  const handleChangeStartDateCopyJob = useCallback((date) => setStartDateCopyJob(formattedDate(date)), []);
  const handleChangeUnitIdCopyJob = useCallback((event) => setUnitId(event.target.value), []);
  const handleRowClick = useCallback((path) => () => router.navigate(path), []);

  const handleRefund = useAsync(async () => {
    if (refundableItem?.id) {
      const res = await API.PaidCustomerItem.refund(refundableItem.id);

      handleApiResponse(res, () => {
        // Only execute the following if handleRefund is called from the refund modal
        if (refundModalHandler.state) {
          refundModalHandler.setState.off();
          fetchJobAsync.run();
        }
      });
    } else {
      console.warn("[handleRefund] No paid customer item ID was found to process a refund with.");
    }
  });

  const handleSubmitCopyJob = useAsync(async (event) => {
    event.preventDefault();

    const { params } = router;
    const { jobId } = params;

    backdrop.handlers.open();

    try {
      const res = await API.Job.copyJob(jobId, { start_date: startDateCopyJob, unit_id: unitId });

      handleApiResponse(res, () => {
        if (isCurrentlyBillable && !job?.alreadyPaid) {
          backdrop.handlers.setMessage("Le paiement a réussi!");
          setTimeout(() => {
            backdrop.handlers.close();
            setJobCopyModalOpened(false);
          }, 2000);
        } else {
          backdrop.handlers.close();
          setJobCopyModalOpened(false);
        }

        fetchJobAsync.run();
      });
    } catch (error) {
      backdrop.handlers.close();
      handleApiResponse(error);
    }
  });

  const refreshHeader = () => {
    setJobModalOpened(false);
    fetchJobAsync.run();
  };

  const renderActionButton = () => {
    const jobCompleted = null;
    const tooltipLabel = jobCompleted ? <Trans i18nKey="warning_job_not_editable" /> : <Trans i18nKey="edit" />;

    const { customerIsBlocked } = job;

    return (
      <div>
        <FloatingActionButton
          color="secondary"
          position="relative"
          disabled={jobCompleted}
          tooltipLabel={tooltipLabel}
          data-cy="buttonEditJob"
          onClick={handleToggleJobModal(true)}
        >
          <Edit />
        </FloatingActionButton>

        <FloatingActionButton
          style={{ left: 25 }}
          color="secondary"
          position="relative"
          disabled={customerIsBlocked || jobCompleted}
          tooltipLabel={customerIsBlocked ? <Trans i18nKey="blocked_customer" /> : <Trans i18nKey="task_copy" />}
          onClick={handleToggleJobCopyModal}
        >
          <ContentCopy />
        </FloatingActionButton>
      </div>
    );
  };

  const renderAssignment = () => {
    const { assignmentDate, unitName } = job;

    const assignment = unitName ? (
      <Fragment>
        <Trans i18nKey="jobs.unit" /> {unitName}
      </Fragment>
    ) : (
      <Trans i18nKey="jobs.unassigned" />
    );
    const date = assignmentDate ? <Fragment>Le {formattedDateDayMonthYear(assignmentDate)}</Fragment> : "-";

    return (
      <Fragment>
        <HeaderLabel>
          {assignment}
          <br />
        </HeaderLabel>

        {date}
        <br />
      </Fragment>
    );
  };

  const renderCommentsTabLabel = () => {
    const { commentsCount } = job;

    const label = <Trans i18nKey="comments" />;

    if (!job || !commentsCount) {
      return label;
    }

    return (
      <Badge
        id="cpbr-badge-comments-count"
        color="primary"
        badgeContent={job.commentsCount}
        classes={{ badge: "badge" }}
      >
        {label}
      </Badge>
    );
  };

  const renderHeader = () => {
    const {
      areaName,
      addressSimple,
      bundleNote,
      containerKind,
      contractNo,
      customerName,
      customerId,
      locationId,
      contractId,
      duration,
      containerNo,
      kind,
      locationName,
      name,
      startDate,
      jobCreatorInfo,
      contractPaymentTypeCode,
    } = job;

    const paymentState = () => {
      if (refundableItem?.refunded) {
        return <span style={{ color: "green", fontWeight: "bold" }}>remboursé</span>;
      } else if (
        refundableItem?.paymentIntentStatus === "requires_capture" ||
        refundableItem?.paymentIntentStatus === "succeeded"
      ) {
        return <span style={{ color: "green", fontWeight: "bold" }}>succès</span>;
      } else {
        return <span style={{ color: "red", fontWeight: "bold" }}>échec</span>;
      }
    };

    const item = containerNo ? (
      <HeaderColumnWrapper>
        <HeaderLabel>Conteneur: {containerNo}</HeaderLabel>
        <HeaderLabel>Matière: {containerKind}</HeaderLabel>
      </HeaderColumnWrapper>
    ) : (
      ""
    );
    const note = bundleNote ? <BundleNote>{bundleNote}</BundleNote> : "";

    return (
      <PageHeader
        getBadge={renderStatusJob}
        titleText={
          <Trans
            i18nKey="jobs_scheduled_on"
            values={{
              date: formattedDateDayMonthYear(startDate),
              kind,
            }}
          />
        }
        subtitleText={name}
      >
        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper>
            {renderAssignment()}
            <Trans
              i18nKey="jobs.duration_long"
              values={{ duration }}
            />
          </HeaderColumnWrapper>
          <HeaderColumnWrapper>
            <HeaderLabel>#{contractNo}</HeaderLabel>
            {customerName}
            <br />
            <Trans i18nKey="works_start" /> {startDate}
            {note}
          </HeaderColumnWrapper>
          <HeaderColumnWrapper>
            <CustomHeaderLabel
              onClick={handleRowClick(`/customers/${customerId}/contracts/${contractId}/locations/${locationId}`)}
            >
              {locationName}
            </CustomHeaderLabel>
            {addressSimple}
            <br />
            {areaName}
            {item}
          </HeaderColumnWrapper>
          {renderActionButton()}
        </FlexHeaderRowWrapper>
        <br />
        Création de la tâche: {jobCreatorInfo}
        <br />
        {contractPaymentTypeCode === "CREDIT_CARD" && refundableItem?.paymentIntentStatus && (
          <>Statut du paiement: {paymentState()}</>
        )}
        {contractPaymentTypeCode === "CREDIT_CARD" && (
          <RefundButton
            paidCustomerItem={refundableItem}
            isInterrupted={job?.hasInterruption}
            refundModalHandler={refundModalHandler}
          />
        )}
      </PageHeader>
    );
  };

  const renderInterruptionTab = () => {
    if (!job || !job.hasInterruption) {
      return null;
    }

    return <Tab label={<Trans i18nKey="interruption" />} />;
  };

  const renderModalJob = () => {
    if (!customerItem.id) {
      return null;
    }

    return (
      <ModalEditJob
        customerItem={customerItem}
        job={job}
        onClose={handleToggleJobModal(false)}
        open={jobModalOpened}
        refreshList={refreshHeader}
        handleRefund={handleRefund}
      />
    );
  };

  const renderMenuItems = (label, data, key) => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,

    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  const renderStatusJob = () => {
    const { status } = job;
    const tooltipLabel = jobStatuses[status];

    return (
      <Tooltip title={tooltipLabel}>
        <StatusBadge status={status} />
      </Tooltip>
    );
  };

  const renderTabContainer = () => {
    let Content = null;

    const _job = job || {};

    if (!_job.id) {
      return null;
    }

    switch (currentTab) {
      case 1: {
        Content = CommentsList;
        break;
      }
      case 2: {
        Content = Interruption;
        break;
      }
      default: {
        Content = InterventionsList;
        break;
      }
    }

    return (
      <Content
        {...props}
        refreshHeader={fetchJobAsync.run}
      />
    );
  };

  if (jobLoading || !job) {
    return null;
  }

  return (
    <div>
      <Breadcrumbs>
        <li>
          <Link to="/customers">
            <Trans i18nKey="customers" />
          </Link>
        </li>
        <li>
          <Link to={`/customers/${router.params.customerId}`}>
            <Trans i18nKey="contract_and_contact" />
          </Link>
        </li>
        <li>
          <Link to={`/customers/${router.params.customerId}/contracts/${router.params.contractId}`}>
            <Trans i18nKey="contract" />
          </Link>
        </li>
        <li>
          <Link
            to={`/customers/${router.params.customerId}/contracts/${router.params.contractId}/preparations/${router.params.customerItemId}`}
          >
            <Trans i18nKey="customer_item" />
          </Link>
        </li>
        <li>
          <Trans i18nKey="interventions" />
        </li>
      </Breadcrumbs>
      <HeaderContextWrapper>
        {renderHeader()}

        {refundModalHandler.state && (
          <ModalRefund
            onCancel={refundModalHandler.setState.off}
            open={refundModalHandler.state}
            onSubmit={handleRefund.run}
            title={"Rembourser"}
            loading={handleRefund.loading}
          >
            <RefundMessage
              refundableItem={refundableItem}
              hasInterruption={job?.hasInterruption}
            />
          </ModalRefund>
        )}
        {jobModalOpened ? renderModalJob() : null}

        {jobCopyModalOpened && (
          <ModalJobCopy
            open={jobCopyModalOpened}
            title="Faire une copie de la tâche"
            onSubmit={handleSubmitCopyJob.run}
            onCancel={handleToggleJobCopyModal}
            loading={handleSubmitCopyJob.loading}
            backdrop={backdrop}
          >
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
              }}
            >
              <DatePicker
                label="Date"
                disabled={handleSubmitCopyJob.loading}
                value={moment(startDateCopyJob)}
                onChange={handleChangeStartDateCopyJob}
                maxDate={moment().add(7, "d")}
                minDate={moment().subtract(7, "d")}
                disablePast={false}
                slotProps={{
                  textField: {
                    sx: {
                      svg: { color: "primary.main" },
                    },
                  },
                }}
              />

              <SelectHalfUi
                id="cpbr-unit"
                disabled={handleSubmitCopyJob.loading}
                inputLabelText={<Trans i18nKey="unit" />}
                onChange={handleChangeUnitIdCopyJob}
                value={`${unitId}`}
              >
                {renderMenuItems(<Trans i18nKey="select_unit" />, sortedUnit, "name")}
              </SelectHalfUi>
            </Stack>

            {isCurrentlyBillable && job?.alreadyPaid && (
              <Typography
                color="orange"
                sx={{
                  pt: 2,
                  textAlign: "center",
                }}
              >
                Ce client a déjà payé pour cette tâche.
              </Typography>
            )}

            {isCurrentlyBillable && !job?.alreadyPaid && priceTotal && (
              <Typography
                sx={{
                  pt: 2,
                }}
              >
                Êtes-vous sûr de vouloir charger à nouveau la somme de{" "}
                <strong>{priceTotal || "montant inconnu"}$</strong> pour cette tâche?
              </Typography>
            )}
          </ModalJobCopy>
        )}
      </HeaderContextWrapper>
      <div>
        <TabsWrapper
          indicatorColor="primary"
          onChange={handleChangeTab}
          textColor="primary"
          value={currentTab}
        >
          <Tab label={<Trans i18nKey="interventions" />} />
          <Tab label={renderCommentsTabLabel()} />
          {renderInterruptionTab()}
        </TabsWrapper>

        {renderTabContainer()}
      </div>
    </div>
  );
}

JobPage.defaultProps = {
  jobLoading: true,
};

JobPage.propTypes = {
  customerItem: PropTypes.object.isRequired,
  fetchJob: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  jobLoading: PropTypes.bool,
};

export default withRouter(withCustomerItem(withJob(JobPage)));
