import React, { ReactElement } from "react";

import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

const CopyIcon = ({
  size = defaultIconProps.size,
  fillColor = "#C8C8C8",
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.8 6.6C1.36 6.6 1 6.24 1 5.8V1.8C1 1.36 1.36 1 1.8 1H5.8C6.24 1 6.6 1.36 6.6 1.8M4.2 3.4H8.2C8.64183 3.4 9 3.75817 9 4.2V8.2C9 8.64183 8.64183 9 8.2 9H4.2C3.75817 9 3.4 8.64183 3.4 8.2V4.2C3.4 3.75817 3.75817 3.4 4.2 3.4Z"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default CopyIcon;
