import { ApiResponse } from "@@types/apiResponse";
import { JobType } from "@@types/jobs/job.type";

import { axiosClientV1 as http } from "../axios";

export type UpdateJobBillingWitnessServiceReturn = JobType[];

const updateJobBillingWitness = async (
  jobId: number,
  value: boolean
): Promise<UpdateJobBillingWitnessServiceReturn> => {
  try {
    const res = await http.post<ApiResponse<JobType[]>>(`/jobs/${jobId}/update_billing_witness.json`, {
      job: { billing_witness: value },
    });

    return res.data?.data || [];
  } catch (error) {
    throw error as Error;
  }
};

export default updateJobBillingWitness;
