import React, { FC, ReactElement, useState } from "react";

import Icon from "@/components/icons/Icon";
import Dialog from "@/components/ui/dialog/Dialog";
import useGenerateInvoiceableJobReports from "@/hooks/jobs/generate-invoiceable-job-reports.hook";
import useUpdateJobBillingWitness from "@/hooks/jobs/update-job-billing-witness.hook";
import usePopover from "@/hooks/popover.hook";
import { JobType } from "@/types/jobs/job.type";
import { Box, ButtonBase, Checkbox, Chip, Popover, Stack, TableCell, Typography } from "@mui/material";
import { JOB_STATUS_COLORS } from "@utils/colors";
import { formattedDate } from "@utils/dates";

const handleCopy = (event: React.MouseEvent<HTMLButtonElement>, value: string): void => {
  event.stopPropagation();
  navigator.clipboard.writeText(value);
};

type IconButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon: ReactElement;
};

const IconButton: FC<IconButtonProps> = ({ onClick, icon }) => {
  return <ButtonBase onClick={onClick}>{icon}</ButtonBase>;
};

const DownloadButton: FC<{ onClick: (event: React.MouseEvent<HTMLButtonElement>) => void; title: string }> = ({
  title,
  onClick,
}) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        p: 0.5,
        color: "white",
        fontWeight: "bold",
        bgcolor: "#3397FF",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        borderRadius: 1,
      }}
    >
      {title}
      <Box
        alignItems={"center"}
        pl={0.5}
      >
        <Icon
          name="download"
          size="xSmall"
        />
      </Box>
    </ButtonBase>
  );
};

const CheckboxCell: FC<{ job: JobType }> = ({ job }) => {
  const { mutate } = useUpdateJobBillingWitness();

  const [checked, setChecked] = useState<boolean>(job.billingWitness ?? false);

  const handleChangeBillingWitness = (value: boolean): void => {
    setChecked(value);
    mutate({ jobId: job?.id, value });
  };

  return (
    <TableCell>
      <Checkbox
        size="small"
        checked={checked}
        onChange={(_, checked) => handleChangeBillingWitness(checked)}
      />
    </TableCell>
  );
};

const CustomerContractCell: FC<{ job: JobType }> = ({ job }) => (
  <TableCell>
    <Typography
      variant="body2"
      sx={{
        fontWeight: "bold",
      }}
    >
      {job.customerName}
    </Typography>
    <Stack
      direction={"row"}
      spacing={0.5}
    >
      <Typography variant="body2">{job.contractNo}</Typography>
      {job.contractNo && (
        <IconButton
          onClick={(event) => handleCopy(event, `${job.contractNo}`)}
          icon={
            <Icon
              name="copy"
              size="small"
            />
          }
        />
      )}
    </Stack>

    <Stack
      direction={"row"}
      spacing={0.5}
    >
      <Typography variant="body2">{job.purchaseOrderNo}</Typography>
      {job.purchaseOrderNo && (
        <IconButton
          onClick={(event) => handleCopy(event, `${job.purchaseOrderNo}`)}
          icon={
            <Icon
              name="copy"
              size="small"
            />
          }
        />
      )}
    </Stack>
  </TableCell>
);

const CustomerItemCell: FC<{ job: JobType }> = ({ job }) => (
  <TableCell>
    <Typography variant="body2">{job.customerItemName}</Typography>
  </TableCell>
);

const CustomerLocationCell: FC<{ job: JobType }> = ({ job }) => (
  <TableCell>
    <Typography variant="body2">{job.customerLocationName}</Typography>
    <Typography variant="body2">{job.areaName}</Typography>
  </TableCell>
);

const PreviousTaskCell: FC<{ job: JobType }> = ({ job }) => (
  <TableCell>
    <Typography variant="body2">{job.previousInterventionKind}</Typography>
    <Typography variant="body2">
      Le {job.previousInterventionDate ? formattedDate(job.previousInterventionDate) : "N/A"}
    </Typography>
  </TableCell>
);

const TaskCell: FC<{ job: JobType }> = ({ job }) => {
  const popoverDriver = usePopover("driver");
  const popoverInterruption = usePopover("interruption");

  const jobDate = job.status === "IN_PROGRESS" ? job.startDate : job.endDate;

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    const path = `/customers/${job.customerId}/contracts/${job.contractId}/preparations/${job.customerItemId}/jobs/${job.id}`;
    window.open(path, "_blank");
  };

  const DETAILS = {
    COMPLETED: {
      label: "Terminé",
      bgColor: JOB_STATUS_COLORS.COMPLETED,
    },
    INTERRUPTED: {
      label: "Cas prob.",
      bgColor: JOB_STATUS_COLORS.INTERRUPTED,
    },
    IN_PROGRESS: {
      label: "En cours",
      bgColor: JOB_STATUS_COLORS.IN_PROGRESS,
    },
    TODO: {
      label: "À faire",
      bgColor: JOB_STATUS_COLORS.TODO,
    },
  };

  return (
    <TableCell>
      <Stack
        direction="row"
        spacing={0.6}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: DETAILS[job.status].bgColor,
          width: "fit-content",
          px: 0.8,
          py: 0.2,
          borderRadius: 1,
          whiteSpace: "nowrap",
        }}
      >
        <Typography
          variant="body2"
          color="white"
          aria-owns={popoverInterruption.open ? popoverInterruption.popoverId : undefined}
          aria-haspopup="true"
          onMouseEnter={popoverInterruption.handleClick}
          onMouseLeave={popoverInterruption.handleClose}
          style={{ cursor: "default" }}
          sx={{
            fontWeight: 900,
            letterSpacing: 0.5,
          }}
        >
          {DETAILS[job.status].label}
        </Typography>

        {job.status === "INTERRUPTED" && (
          <Popover
            id={popoverInterruption.popoverId}
            open={popoverInterruption.open}
            anchorEl={popoverInterruption.anchorEl}
            onClose={popoverInterruption.handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            sx={{
              pointerEvents: "none",
            }}
            disableRestoreFocus
          >
            <Box
              sx={{
                px: 2,
                py: 1,
                bgcolor: "#616161",
              }}
            >
              <Typography
                variant="body2"
                color="white"
                sx={{
                  fontWeight: 500,
                }}
              >
                {job.interruptionDescription}
              </Typography>
            </Box>
          </Popover>
        )}

        <IconButton
          onClick={handleClickOpen}
          icon={
            <Icon
              name="open"
              size="small"
              fillColor="white"
            />
          }
        />
      </Stack>
      <Typography variant="body2">{job.kind}</Typography>
      <Typography variant="body2">Le {jobDate ? formattedDate(jobDate) : "N/A"}</Typography>
      {job.unitName && (
        <>
          <Typography variant="body2">
            Par{" "}
            <Typography
              variant="body2"
              component="span"
              aria-owns={popoverDriver.open ? popoverDriver.popoverId : undefined}
              aria-haspopup="true"
              onMouseEnter={popoverDriver.handleClick}
              onMouseLeave={popoverDriver.handleClose}
              style={{ textDecoration: "underline", textDecorationStyle: "dotted", cursor: "default" }}
            >
              {job.unitName}
            </Typography>
          </Typography>
          <Popover
            id={popoverDriver.popoverId}
            open={popoverDriver.open}
            anchorEl={popoverDriver.anchorEl}
            onClose={popoverDriver.handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              pointerEvents: "none",
              ml: 1,
            }}
            disableRestoreFocus
          >
            <Box
              sx={{
                px: 2,
                py: 1,
                bgcolor: "#616161",
              }}
            >
              <Typography
                variant="body2"
                color="white"
                sx={{
                  fontWeight: 500,
                }}
              >
                {job.userName || "Inconnu"}
              </Typography>
            </Box>
          </Popover>
        </>
      )}
    </TableCell>
  );
};

const DaysCell: FC<{ job: JobType }> = ({ job }) => (
  <TableCell>
    <Chip
      color={job.extraDaysSinceLastIntervention ? "error" : "default"}
      label={job.extraDaysSinceLastIntervention ? `+${job.extraDaysSinceLastIntervention}` : "0"}
      size="small"
      sx={{
        borderRadius: 1,
        py: 0,
        fontWeight: job.extraDaysSinceLastIntervention ? "bold" : "normal",
      }}
    />
    <Typography
      variant="body2"
      sx={{
        whiteSpace: "nowrap",
      }}
    >
      {job.purchasePlanName}
    </Typography>
  </TableCell>
);

const DispositionCell: FC<{ job: JobType }> = ({ job }) => {
  const [showModal, setShowModal] = useState<"weighIn" | "report">();
  const weightInTons = Number(job.weighIn?.weight) / 1000;
  const isAbnormalWeight = Number(job.weighIn?.weight) < 1000 || Number(job.weighIn?.weight) > 14000;
  const { refetch: downloadInvoiceableJobReports } = useGenerateInvoiceableJobReports(job.id);

  const handleToggleModal = (
    event: React.MouseEvent<HTMLButtonElement | HTMLSpanElement>,
    type?: "weighIn" | "report"
  ): void => {
    event.stopPropagation();
    setShowModal(type);
  };

  const handleDownloadInvoiceableJobReports = (): void => {
    downloadInvoiceableJobReports();
  };

  return (
    <>
      <TableCell>
        <Stack alignItems={"flex-start"}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
            }}
          >
            {job.supplierLocationName}
          </Typography>

          {job.weighIn ? (
            <Stack>
              <Stack
                direction={"row"}
                spacing={0.5}
              >
                <Typography variant="body2">{weightInTons} TM</Typography>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                >
                  {isAbnormalWeight && (
                    <Icon
                      name="problemCase"
                      size="small"
                    />
                  )}

                  <IconButton
                    onClick={(event) => handleCopy(event, `${weightInTons} TM`)}
                    icon={
                      <Icon
                        name="copy"
                        size="small"
                      />
                    }
                  />
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                spacing={0.5}
              >
                <Typography
                  variant="body2"
                  style={{ cursor: "pointer", userSelect: "text" }}
                  color="#3397FF"
                  onClick={(event) => handleToggleModal(event, "weighIn")}
                >
                  #{job.weighIn?.weighingSlip}
                </Typography>

                <IconButton
                  onClick={(event) => handleCopy(event, `${job.weighIn?.weighingSlip}`)}
                  icon={
                    <Icon
                      name="copy"
                      size="small"
                    />
                  }
                />
              </Stack>
            </Stack>
          ) : (
            job.requireWeighIn && <Typography variant="body2">Bon de pesée manquant</Typography>
          )}

          {job.leedReport ? (
            <ButtonBase onClick={(event) => handleToggleModal(event, "report")}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
              >
                <Icon
                  name="eco"
                  size="small"
                />

                <Typography
                  variant="body2"
                  style={{ cursor: "pointer", userSelect: "text" }}
                  color="#3397FF"
                >
                  Leed
                </Typography>
              </Stack>
            </ButtonBase>
          ) : (
            job.customerLocationRequireLeedReport && <Typography variant="body2">Rapport Leed manquant</Typography>
          )}

          {(job.weighIn || job.leedReport) && (
            <DownloadButton
              onClick={handleDownloadInvoiceableJobReports}
              title="Rapport(s)"
            />
          )}
        </Stack>
      </TableCell>

      <Dialog
        open={showModal === "weighIn"}
        onClose={(event) => handleToggleModal(event, undefined)}
        title={"Bon de pesée"}
      >
        <img
          src={job.weighIn?.photo}
          alt="Bon de pesée"
          width={"100%"}
        />
      </Dialog>

      <Dialog
        open={showModal === "report"}
        onClose={(event) => handleToggleModal(event, undefined)}
        title={"Rapport Leed"}
      >
        <img
          src={job.leedReport?.photo}
          alt="Rapport Leed"
          width={"100%"}
        />
      </Dialog>
    </>
  );
};

export {
  CheckboxCell,
  CustomerContractCell,
  CustomerItemCell,
  CustomerLocationCell,
  PreviousTaskCell,
  TaskCell,
  DaysCell,
  DispositionCell,
};
