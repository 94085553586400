import { Location } from "@/types/locations/location.type";
import { ApiResponse } from "@@types/apiResponse";
import { convertObjectToSnakeCase } from "@utils/transformers";

import { axiosClientV2 as http } from "../axios";

export type CreateCustomerLocationServiceReturn = Location | undefined;

export type CreateCustomerLocationPayload = Partial<Location> & {
  contractId: number;
};

const createCustomerLocation = async (
  id: number,
  payload: CreateCustomerLocationPayload
): Promise<CreateCustomerLocationServiceReturn> => {
  try {
    const res = await http.post<ApiResponse<Location>>(`/customers/${id}/create_location`, {
      location: convertObjectToSnakeCase(payload),
    });

    return res.data?.data;
  } catch (error) {
    throw error as Error;
  }
};

export default createCustomerLocation;
