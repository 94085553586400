import styled from "@emotion/styled";

export const ModalHeader = styled.div`
  background-color: #ffffff;
  padding: 48px 56px 0 48px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const ModalHeaderText = styled.div`
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

export const ModalContent = styled.div`
  padding: 48px 56px 0 48px;
  display: flex;
  align-items: flex-start;
`;

export const ModalFooter = styled.div`
  padding: 48px 56px 0 48px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const ModalButtons = styled.div`
  justify-content: flex-end;
  padding: 48px 56px 48px 48px;
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const ModalCloseButton = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 100%;
  margin: 10px 0 0 0;
`;
