import updateJobBillingWitness, {
  UpdateJobBillingWitnessServiceReturn,
} from "@/services/jobs/update-job-billing-witness.service";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

const useUpdateJobBillingWitness = (): UseMutationResult<
  UpdateJobBillingWitnessServiceReturn,
  Error,
  { jobId: number; value: boolean }
> => {
  return useMutation({
    mutationFn: ({ jobId, value }: { jobId: number; value: boolean }) => updateJobBillingWitness(jobId, value),
  });
};

export default useUpdateJobBillingWitness;
