import fetchClientAccountJobs, {
  FetchClientAccountJobsServiceReturn,
} from "@/services/jobs/fetch-client-account-jobs.service";
import transactionKeys from "@hooks/transactions/transaction.keys";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

const useFetchClientAccountJobs = (date: string): UseQueryResult<FetchClientAccountJobsServiceReturn> => {
  return useQuery({
    queryKey: transactionKeys.clientAccountsList({ date }),
    queryFn: () => fetchClientAccountJobs(date),
  });
};

export default useFetchClientAccountJobs;
