import { QueryKey } from "@tanstack/react-query";

const transactionKeys = {
  all: (): QueryKey => ["transactions"],
  lists: (): QueryKey => [...transactionKeys.all(), "list"],
  list: (filters: object): QueryKey => [...transactionKeys.lists(), { filters }],
  clientAccountsLists: (): QueryKey => [...transactionKeys.lists(), "client-accounts"],
  clientAccountsList: (filters: object): QueryKey => [...transactionKeys.clientAccountsLists(), { filters }],
  clientJobReports: (id: number): QueryKey => [
    ...transactionKeys.clientAccountsLists(),
    "client-job-reports",
    id.toString(),
  ],
  details: (): QueryKey => [...transactionKeys.all(), "detail"],
  detail: (id: number): QueryKey => [...transactionKeys.details(), id.toString()],
};

export default transactionKeys;
