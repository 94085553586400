import { useCallback, useState } from "react";

interface UsePopoverReturn {
  popoverId: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
}

function usePopover(id: string): UsePopoverReturn {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const popoverId = open ? `popover-${id}` : undefined;

  return { popoverId, open, anchorEl, handleClick, handleClose };
}

export default usePopover;
