import React, { ReactElement } from "react";
import Modal from "@ui/modal/Modal";
import { SelectChangeEvent, Stack } from "@mui/material";
import { Supplier } from "@@types/suppliers/supplier.type";
import ModalAddMasterRouteTemplateArea from "@components/modals/routeTemplate/add-master-route-template/ModalAddMasterRouteTemplateArea";
import ModalAddMasterRouteTemplateDay from "@components/modals/routeTemplate/add-master-route-template/ModalAddMasterRouteTemplateDay";
import ModalAddMasterRouteTemplateMatter from "@components/modals/routeTemplate/add-master-route-template/ModalAddMasterRouteTemplateMatter";
import ModalAddMasterRouteTemplateType from "@components/modals/routeTemplate/add-master-route-template/ModalAddMasterRouteTemplateType";
import ModalAddMasterRouteTemplateSupplier from "@components/modals/routeTemplate/add-master-route-template/ModalAddMasterRouteTemplateSupplier";
import ModalAddMasterRouteTemplateButtons from "@components/modals/routeTemplate/add-master-route-template/ModalAddMasterRouteTemplateButtons";

export type Day = {
  value: string;
  error: boolean;
};

export type ModalAddMasterRouteTemplateProps = {
  day: Day;
  type: string;
  area: string;
  matter: string;
  supplier: Supplier;
  onClose: () => void;
  opened: boolean;
  onCreate: () => void;
  onSupplierChange: (value: SelectChangeEvent<string>) => void;
  onDayChange: (value: SelectChangeEvent<string>) => void;
};

const ModalAddMasterRouteTemplate = ({
  day,
  type,
  area,
  matter,
  supplier,
  onClose,
  opened,
  onCreate,
  onSupplierChange,
  onDayChange,
}: ModalAddMasterRouteTemplateProps): ReactElement => {
  return (
    <Modal
      open={opened}
      onClose={onClose}
      maxWidth={"sm"}
    >
      <Modal.Header title={"Ajouter une route maître"} />
      <Modal.Content>
        <Stack spacing={4}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center"
            }}
          >
            <ModalAddMasterRouteTemplateType type={type} />
            <span>-</span>
            <ModalAddMasterRouteTemplateArea area={area} />
            <span>-</span>
            <ModalAddMasterRouteTemplateMatter matter={matter} />
            <span>-</span>
            <ModalAddMasterRouteTemplateDay
              day={day}
              onChange={onDayChange}
            />
          </Stack>

          <ModalAddMasterRouteTemplateSupplier
            supplier={supplier}
            onChange={onSupplierChange}
          />
        </Stack>
      </Modal.Content>
      <ModalAddMasterRouteTemplateButtons
        onClose={onClose}
        onCreate={onCreate}
      />
    </Modal>
  );
};

export default ModalAddMasterRouteTemplate;
