// React imports
import React, { useState, useEffect, useMemo } from "react";

// Internal imports
import * as API from "@services";
import useAsync from "@hooks/useAsync";
import useNotifier from "@hooks/useNotifier";
import useLogStateChange from "@hooks/useLogStateChange";
import { useFirstMountState } from "@hooks/useFirstMountState";
import StripeSetupForm from "@components/stripe/StripeSetupForm";
import useApiResponseHandler from "@hooks/useApiResponseHandler";
import CustomerSaleDetails from "@components/customer/CustomerSaleDetails";
import { useCustomerContext } from "@context/customer/CustomerContextProvider";
import CustomerPaymentDetails from "@components/customer/CustomerPaymentDetails";

// External iports
import { Button, Stack, Typography, Box, CircularProgress } from "@mui/material";

const createStripeCustomerWithSetupIntent = async (
  customerData,
  handlers,
  handleApiResponse,
  notifier,
  currentlyOpenModal
) => {
  if (!customerData) return;

  let isFormValid = handlers.validateCustomerForm();

  if (!isFormValid && currentlyOpenModal === "contract") {
    // If opened from ModalContract, we don't have access to CustomerForm if missing information so give notification
    notifier.enqueueMessage("Veuillez remplir les informations manquantes du client avant svp.");
    return;
  }

  if (!isFormValid) {
    handlers.setTabValue(0); // Go back to the first CustomerForm tab
    return;
  }

  try {
    const res = await API.Customer.createStripeCustomer(customerData.id);
    const setupIntent = await API.Stripe.createStripeSetupIntent(res.data.id);

    handlers.setCustomer((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        stripeCustomerId: res.data.id,
      },
      stripe: { ...prevState.stripe, setupIntent, data: res.data },
    }));
  } catch (error) {
    console.warn("[createStripeCustomerWithSetupIntent] error:", error);
    handleApiResponse(error);
  }
};

const createStripeCustomer = async (customer, setCustomer) => {
  const stripeCustomer = await API.Customer.createStripeCustomer(customer.id);

  if (stripeCustomer?.data) {
    customer.stripeCustomerId = stripeCustomer.data.id;

    setCustomer((prevState) => ({
      ...prevState,
      data: customer,
      stripe: {
        ...prevState.stripe,
        data: stripeCustomer.data,
      },
    }));
  }
};

const fetchStripeCustomerFull = async (stripeCustomerID, setCustomer, handleApiResponse) => {
  try {
    const stripeCustomer = await API.Stripe.retrieveStripeCustomer(stripeCustomerID);
    const purchases = await API.Stripe.retrieveStripeCustomerPurchases(stripeCustomerID);
    const paymentMethods = await API.Stripe.retrieveStripeCustomerPaymentMethods(stripeCustomerID);

    if (!paymentMethods.hasOwnProperty("error")) {
      setCustomer((prevState) => ({
        ...prevState,
        purchases,
        stripe: {
          ...prevState.stripe,
          data: stripeCustomer,
          paymentMethods: paymentMethods?.data,
        },
      }));
    }
  } catch (error) {
    console.warn("[fetchStripeCustomerFull] error:", error);
    handleApiResponse(error);
  }
};

const fetchStripeCustomerWithPaymentMethods = async (stripeCustomerID, setCustomer, handleApiResponse) => {
  try {
    const stripeCustomer = await API.Stripe.retrieveStripeCustomer(stripeCustomerID);
    const paymentMethods = await API.Stripe.retrieveStripeCustomerPaymentMethods(stripeCustomerID);

    if (!paymentMethods.hasOwnProperty("error")) {
      setCustomer((prevState) => ({
        ...prevState,
        stripe: {
          ...prevState.stripe,
          data: stripeCustomer,
          paymentMethods: paymentMethods?.data,
        },
      }));
    }
  } catch (error) {
    console.warn("[fetchStripeCustomerWithPaymentMethods] error:", error);
    handleApiResponse(error);
  }
};

function CustomerDetails({ children, isLoading, isContractSelectShowing = true }) {
  const {
    customer: { data, stripe, purchases, currentlyOpenModal, saleDetails },
    handlers,
  } = useCustomerContext();

  const notifier = useNotifier();
  const isFirstMount = useFirstMountState();
  const handleApiResponse = useApiResponseHandler();

  const fetchStripeCustomerFullAsync = useAsync(() =>
    fetchStripeCustomerFull(data.stripeCustomerId, handlers.setCustomer, handleApiResponse)
  );
  const fetchStripeCustomerWithPaymentMethodsAsync = useAsync(() =>
    fetchStripeCustomerWithPaymentMethods(data.stripeCustomerId, handlers.setCustomer, handleApiResponse)
  );

  const createStripeCustomerWithSetupIntentAsync = useAsync(() =>
    createStripeCustomerWithSetupIntent(data, handlers, handleApiResponse, notifier, currentlyOpenModal)
  );

  const isDataLoading = fetchStripeCustomerFullAsync.loading || fetchStripeCustomerWithPaymentMethodsAsync.loading;

  // We only want to show the stripe setup form if we have a stripe customer, no payment methods attached and the selected payment mode is credit card
  const isStripeSetupFormShowable = useMemo(
    () =>
      !isDataLoading &&
      saleDetails.paymentMode.id === "CREDIT_CARD" &&
      !stripe.paymentMethods?.length &&
      stripe.data?.id,
    [saleDetails.paymentMode, stripe.paymentMethods, stripe.data]
  );

  // We only want to show the customer's payment details if he has either some payment methods or purchases
  const isCustomerPaymentDetailsShowable = useMemo(
    () =>
      !isDataLoading &&
      saleDetails.paymentMode.id === "CREDIT_CARD" &&
      (!!stripe.paymentMethods?.length || !!purchases?.length),
    [saleDetails.paymentMode, stripe.paymentMethods]
  );

  useEffect(() => {
    const initCreateSetupIntent = async () => {
      const setupIntent = await API.Stripe.createStripeSetupIntent(stripe.data?.id);

      handlers.setCustomer((prevState) => ({
        ...prevState,
        stripe: { ...prevState.stripe, setupIntent },
      }));
    };

    if (isStripeSetupFormShowable && !stripe.setupIntent) {
      initCreateSetupIntent();
    }
  }, [stripe.paymentMethods, stripe.data, saleDetails.paymentMode, stripe.setupIntent]);

  useEffect(() => {
    // Decide here which data to load depending on the currently open modal
    switch (currentlyOpenModal) {
      case "contract":
        // When creating a contract, we just need the stripe customer and payment methods
        data?.stripeCustomerId && !stripe.data && fetchStripeCustomerWithPaymentMethodsAsync.run();
        break;
      case "edit":
        // When editing a customer, we need the stripe customer, his purchases and payment methods
        // but only if we have the stripe customer at mount, not when we click on the button to create one
        data?.stripeCustomerId && isFirstMount && fetchStripeCustomerFullAsync.run();
        break;
      case "new":
        // When creating a customer, we just need to create the stripe customer but
        // only when the user selects credit card as the payment mode
        if (!data?.stripeCustomerId && saleDetails.paymentMode.id === "CREDIT_CARD") {
          createStripeCustomerWithSetupIntentAsync.run();
        }
        break;
      default:
        currentlyOpenModal && console.warn("[CustomerDetails] Unknown currentlyOpenModal:", currentlyOpenModal);
        break;
    }
  }, [data, currentlyOpenModal, saleDetails.paymentMode]);

  // Returning the loading spinner seperately ensures that out data is fully ready before showing
  // some UI elements that haven't finished rendering or could change in the coming milliseconds
  if (isDataLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 500
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "space-between",
        minHeight: 500,
        position: "relative"
      }}>
      <Stack
        spacing={4}
        sx={{
          py: 2,
          px: 3,
          height: "100%"
        }}>
        {/* Type de vente, type de plan, mode de paiement selects */}
        <CustomerSaleDetails
          isLoading={isLoading}
          saleDetails={saleDetails}
          handlers={handlers}
          contracts={data.contracts}
          isUserAccounting={data.isUserAccounting}
          isContractSelectShowing={isContractSelectShowing}
          currentlyOpenModal={currentlyOpenModal}
        />

        {/* Purchased products, payment methods */}
        {isCustomerPaymentDetailsShowable && (
          <CustomerPaymentDetails
            purchases={purchases}
            setupIntent={stripe.setupIntent}
            stripeCustomer={stripe.data}
            paymentMethods={stripe.paymentMethods}
            setSingleProperty={handlers.setSingleProperty}
            stripeDefaultPaymentMethod={data?.stripeDefaultPaymentMethodId}
          />
        )}

        {/* Loading icon in place of Stripe Setup Form when creating Stripe Customer */}
        {createStripeCustomerWithSetupIntentAsync.loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 322.5
            }}>
            <CircularProgress />
          </Box>
        )}

        {/* Stripe setup form to add a payment method for later use */}
        {stripe.setupIntent && isStripeSetupFormShowable && !createStripeCustomerWithSetupIntentAsync.loading && (
          <StripeSetupForm />
        )}
      </Stack>
      {/* If the customer does not have a stripe customer attached to it, show warning message */}
      {!data?.stripeCustomerId &&
        saleDetails.paymentMode.id == "CREDIT_CARD" &&
        !createStripeCustomerWithSetupIntentAsync.loading &&
        currentlyOpenModal !== "new" && (
          <Box sx={{
            alignSelf: "center"
          }}>
            <Button
              disabled={isLoading}
              variant="contained"
              onClick={() => createStripeCustomerWithSetupIntentAsync.run()}
            >
              Saisir une carte de crédit pour ce client
            </Button>
          </Box>
        )}
      {/* Show the children components, usually buttons */}
      {children}
    </Stack>
  );
}

export default CustomerDetails;
