import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Typography,
  Select,
  InputLabel,
  Box,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { withCustomerLocations, withJobs, withJobTemplates, withSupplierLocations, withLocations } from "optigo-redux";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { withRouter } from "@utils/withRouter";
import styled from "@emotion/styled";

import DatePicker from "./form/DatePickerMui";
import ModalWarning from "./ModalWarning";
import DialogWrapper from "./ui/DialogWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import SelectUi from "./ui/Select";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import { formattedDate } from "@utils/dates";
import TextAreaUi from "./ui/TextArea";
import { filterSupplierLocations } from "@utils/filtering";
import AutocompleteModal from "./ui/AutocompleteModal";
import InlineColorPicker from "./ui/InlineColorPicker";
import { includes } from "lodash";
import ModalInterruption from "@components/modals/interruption/ModalInterruption";
import { formattedPhoneNumber } from "@utils/phoneNumber";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import * as API from "@services";
import { isArrayTruthy } from "@utils/common.js";
import { mapResponsesNotificationContacts } from "@@types/locations/mappers/mapResponseToLocation.mapper";
import SmsAndEmailNotifications from "@components/modals/quickTask/quickJob/SmsAndEmailNotifications";

const ButtonCustom = styled(Button)`
  && {
    color: ${({ theme }) => theme.colors.basic.warning};
  }
`;

const DialogActionsSC = styled(DialogActions)`
  && {
    justify-content: space-between;

    button {
      margin: 0 4px;
    }
  }
`;

const initialNotificationContactStates = { id: null, name: "", smsOrEmail: "", error: false, delete: false };

const initialNotificationContactState = { 0: initialNotificationContactStates };

const initialState = {
  errors: {
    kind: false,
    customerDestinationLocationId: false,
    customerLocationId: false,
    startDate: false,
    supplierLocationId: false,
    smsAlertPhoneNumber: false,
  },
  customerLocations: [],
  customerDestinationLocationId: "-1",
  notificationContacts: initialNotificationContactState,
  deleteWarningModalOpened: false,
  isInterruptionModalOpened: false,
  locationModalOpened: false,
  initialJobStateLoaded: false,
  editedStatus: "",
};

const ModalEditJob = (props) => {
  const [state, setState] = useState({
    ...initialState,
    statusOptions: [],
  });

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const handleChangeFields = (field) => (event) => {
    updateState({ [field]: event.target.value });
  };

  const getErrorMessage = (field) => {
    const { errors } = state;

    if (errors[field]) {
      return <Trans i18nKey="errors.blank" />;
    }

    return "";
  };

  useEffect(() => {
    fetchJobTemplates();
    availableStatus();
    fetchAllSuppliersLocations();
    fetchCustomerLocations();
    setJobInitialState();
  }, []);

  const setJobInitialState = () => {
    updateState({
      customerLocationId: props.job.customerLocationId,
      noteComments: props.job.noteComments,
      noteCommentsColor: props.job.noteCommentsColor,
      noteLocation: props.job.noteLocationToDisplay,
      notificationContacts: isArrayTruthy(props.job.notificationContacts)
        ? mapResponsesNotificationContacts(props.job.notificationContacts)
        : initialNotificationContactState,
      customerMobilePhoneNumber: props.job.customerMobilePhoneNumber,
      purchaseOrderNo: props.job.purchaseOrderNoToDisplay,
      startDate: props.job.startDate,
      jobTemplateId: props.job.jobTemplateId,
      code: props.job.code,
      kind: props.job.kind,
      supplierLocationId: props.job.supplierLocationId,
      customerDestinationLocationId: props.job.customerDestinationLocationId ?? "-1",
      supplierSecondLocationId: props.job.supplierSecondLocationId,
      initialJobStateLoaded: true,
      currentlySelectedCustomerLocationHash: {
        value: props.job.customerLocationId,
        label: props.job.locationName,
      },
    });
  };

  const validateForm = () => {
    const errors = { ...initialState.errors };
    const { customerDestinationLocationId, jobTemplateId, startDate, supplierLocationId, notificationContacts } = state;
    let valid = true;
    const jobTemplate = getJobTemplate(jobTemplateId);
    const jobShouldHaveSupplier =
      jobTemplate.code?.indexOf("_VP") > -1 ||
      jobTemplate.code?.indexOf("_VR") > -1 ||
      jobTemplate.code?.indexOf("_RE") > -1;

    const jobShouldHaveDestination = jobTemplate.code === "RE";

    for (const name of ["currentlySelectedCustomerLocationHash", "kind"]) {
      if (state[name] === "-1") {
        valid = false;
        errors[name] = true;
      }
    }

    if (jobTemplateId !== "-1") {
      if (jobTemplate.code === "RE" && customerDestinationLocationId === "-1") {
        valid = false;
        errors.customerDestinationLocationId = true;
      }
    }

    if (jobShouldHaveSupplier && (!supplierLocationId || supplierLocationId === "-1")) {
      valid = false;
      errors.supplierLocationId = true;
    }

    if (jobShouldHaveDestination && !supplierLocationId && customerDestinationLocationId === "-1") {
      valid = false;
      errors.customerDestinationLocationId = true;
    }

    if (!startDate) {
      valid = false;
      errors.startDate = true;
    }

    const notificationContactsArray = Object.values(notificationContacts);

    notificationContactsArray.map((contact, index) => {
      const activeNotificationContacts = notificationContactsArray.filter((contact) => !contact.delete);

      if (!contact.delete) {
        const phoneNoLength = contact.smsOrEmail.replace(/\D/g, "").length;

        const smsIsValid = [10, 11].includes(phoneNoLength) && !contact.smsOrEmail.includes("@");

        const emailIsValid = contact.smsOrEmail.includes("@");

        const hasDuplicate = activeNotificationContacts.filter((c) => c.smsOrEmail === contact.smsOrEmail).length > 1;

        if ((!smsIsValid && !emailIsValid) || hasDuplicate) {
          if (activeNotificationContacts.length === 1 && contact.smsOrEmail === "") return;

          handleChangeValueNotificationContact("notificationContacts", index, "error", true);

          valid = false;
        }
      }
    });

    updateState({ errors });

    return valid;
  };

  const fetchAllSuppliersLocations = () => {
    props.fetchAllSuppliersLocations();
  };

  const fetchCustomerLocations = async () => {
    const { customerItem } = props;
    const customerLocations = await API.Contract.fetchCustomerContractLocations(
      customerItem.customerId,
      customerItem.contractId
    );

    updateState({
      customerLocations,
    });
  };

  const fetchCustomerLocationsAndCloseModal = () => {
    updateState({
      locationModalOpened: false,
    });
    fetchCustomerLocations();
  };

  const fetchJobTemplates = async () => {
    const { fetchJobTemplates } = props;
    await fetchJobTemplates();
  };

  const getJobTemplate = (jobTemplateId) => props.jobTemplates.find(({ id }) => id === jobTemplateId?.toString()) || {};

  const handleChangeStartDate = (date) => {
    updateState({
      errors: {
        ...state.errors,
        startDate: false,
      },
      startDate: formattedDate(date),
    });
  };

  const handleChangeCustomerLocation = () => (value) => {
    updateState({
      errors: {
        customLocationId: false,
      },
      currentlySelectedCustomerLocationHash: value,
    });
    changeNoteLocation(value);
    changePurchaseOrderNo(value);
    ChangeNotificationContacts(value);
  };

  const handleChangeJobTemplate = () => (event) => {
    const jobTemplate = getJobTemplate(event.target.value);

    updateState({
      jobTemplateId: jobTemplate.id,
      code: jobTemplate.code,
      kind: jobTemplate.kind,
    });
  };

  const changeNoteLocation = (value) => {
    const { customerLocations } = state;
    const locationNote = customerLocations.find((customerLocation) => customerLocation.id === value.value);

    updateState({ noteLocation: locationNote ? locationNote.note : "" });
  };

  const changePurchaseOrderNo = (value) => {
    const { customerLocations } = state;
    const purchaseOrderNo = customerLocations.find((customerLocation) => customerLocation.id === value.value);

    updateState({ purchaseOrderNo: purchaseOrderNo ? purchaseOrderNo.purchaseOrderNo : "" });
  };

  const ChangeNotificationContacts = (value) => {
    const { customerLocations } = state;
    const customerLocation = customerLocations.find((customerLocation) => customerLocation.id === value.value);
    updateState({
      notificationContacts: isArrayTruthy(customerLocation?.notificationContacts)
        ? mapResponsesNotificationContacts(customerLocation.notificationContacts)
        : initialNotificationContactState,
    });
  };

  const handleAddAndRemoveNotificationContact = (stateName, value, nestedState = null) => {
    if (nestedState) {
      updateState({ [stateName]: { ...state[stateName], [nestedState]: value } });
    } else {
      updateState({ [stateName]: value });
    }
  };

  const handleChangeValueNotificationContact = (stateName, key, nestedState, value) => {
    updateState({
      [stateName]: {
        ...state[stateName],
        [key]: {
          ...state[stateName][key],
          [nestedState]: value,
        },
      },
    });
  };

  const handleClose = () => {
    updateState({
      ...initialState,
      ...props.job,
    });

    props.onClose();
  };

  const handleDeleteJob = async () => {
    const { deleteJob, router, job, handleRefund } = props;
    const { id, contractId, customerId, customerItemId, paidCustomerItem } = job;

    // If deleting a job that is not refunded yet, process the refund before deleting the job
    if (paidCustomerItem?.refunded !== undefined && !paidCustomerItem?.refunded) {
      await handleRefund.run();
    }

    await deleteJob(id);

    router.navigate(`/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}`, {
      replace: true,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const { job, refreshList, updateJob } = props;

      const {
        customerDestinationLocationId,
        currentlySelectedCustomerLocationHash,
        noteComments,
        noteCommentsColor,
        noteLocation,
        purchaseOrderNo,
        startDate,
        supplierLocationId,
        supplierSecondLocationId,
        editedStatus,
        jobTemplateId,
        code,
        kind,
        notificationContacts,
      } = state;

      deleteExistingDefaultSmsOrEmailIfNeeded(notificationContacts[0]);

      const { interventionsDispatch } = job;

      await updateJob(job.id, {
        customer_destination_location_id: customerDestinationLocationId,
        customer_location_id: currentlySelectedCustomerLocationHash.value,
        interventionsDispatch,
        note_comments: noteComments ? noteComments.trim() : "",
        note_comments_color: noteCommentsColor,
        note_location: noteLocation ? noteLocation.trim() : "",
        job_template_id: jobTemplateId,
        code: code,
        kind: kind,
        purchase_order_no: purchaseOrderNo ? purchaseOrderNo.trim() : "",
        notification_contacts: notificationContacts,
        start_date: startDate,
        status: editedStatus,
        supplier_location_id: supplierLocationId,
        supplier_second_location_id: supplierSecondLocationId,
      });

      refreshList();
    }
  };

  const deleteExistingDefaultSmsOrEmailIfNeeded = (defaultSmsOrEmail) => {
    const hasDefaultSmsOrEmail = defaultSmsOrEmail.id;
    const defaultValueIsEmpty = defaultSmsOrEmail.smsOrEmail === "";

    if (hasDefaultSmsOrEmail && defaultValueIsEmpty) {
      defaultSmsOrEmail.delete = true;
    }
  };

  const handleToggleLocationModal = (opened) => () => {
    updateState({
      locationModalOpened: opened,
    });
  };

  const handleToggleWarningModal = (deleteWarningModalOpened) => () => {
    updateState({
      deleteWarningModalOpened,
    });
  };

  const handleToggleInterruptionModal = (isInterruptionModalOpened) => () => {
    updateState({
      isInterruptionModalOpened,
    });
  };

  const renderJobTemplatesMenuItems = (jobTemplates) => [
    jobTemplates.map(({ kind, id }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {kind}
      </MenuItem>
    )),
  ];

  const renderMenuItems = (label, data, key) => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,

    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  const renderMenuItemsStatus = (data, key) => [
    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={remainingData.status}
        data-cy={remainingData.status}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  const renderLocationsFields = () => {
    const { customerDestinationLocationId, errors, jobTemplateId, supplierLocationId, supplierSecondLocationId } =
      state;
    const { jobTemplates, job } = props;

    if (jobTemplates.length === 0) {
      return null;
    }

    const jobTemplate = getJobTemplate(jobTemplateId);

    if (jobTemplate.code === "RE") {
      return (
        <Stack width="100%">
          <SelectUi
            error={errors.customerDestinationLocationId}
            disabled={jobTemplateId === "-1" || jobTemplate.code.toUpperCase().indexOf("LI") > -1}
            inputLabelText={<Trans i18nKey="customer_destination_location" />}
            onChange={handleChangeFields("customerDestinationLocationId")}
            value={customerDestinationLocationId ?? ""}
          >
            {renderMenuItems(<Trans i18nKey="select_customer_destination_location" />, job.customerLocations, "name")}
          </SelectUi>
        </Stack>
      );
    }

    const supplierLocations = filterSupplierLocations(props.supplierLocations, jobTemplate.code);

    return (
      <>
        <Select
          fullWidth
          disabled={
            jobTemplateId === "-1" ||
            jobTemplate.code?.toUpperCase().indexOf("LI") > -1 ||
            jobTemplate.code?.toUpperCase() === "MECA"
          }
          error={errors.supplierLocationId}
          formControlError={errors.supplierLocationId}
          formHelperErrorMsg={getErrorMessage("supplierLocation")}
          id="cpbr-supplier-location"
          inputLabelText={<Trans i18nKey="drop_point_1" />}
          onChange={handleChangeFields("supplierLocationId")}
          value={supplierLocationId ?? ""}
        >
          {renderMenuItems(<Trans i18nKey="select_drop_point" />, supplierLocations, "name")}
        </Select>
        <Select
          fullWidth
          disabled={jobTemplateId === "-1" || jobTemplate.code?.toUpperCase() !== "ROSD_VP_CC"}
          error={errors.supplierSecondLocationId}
          formControlError={errors.supplierSecondLocationId}
          formHelperErrorMsg={getErrorMessage("supplierSecondLocationId")}
          id="cpbr-supplier-location"
          inputLabelText={<Trans i18nKey="drop_point_2" />}
          onChange={handleChangeFields("supplierSecondLocationId")}
          value={supplierSecondLocationId ?? ""}
        >
          {renderMenuItems(<Trans i18nKey="select_drop_point" />, supplierLocations, "name")}
        </Select>
      </>
    );
  };

  const availableStatus = () => {
    const { status } = props.job;

    if (status === "IN_PROGRESS") {
      updateState({
        statusOptions: [
          {
            id: 1,
            name: "À FAIRE",
            status: "IN_PROGRESS_TO_TODO",
          },
          {
            id: 2,
            name: "EN COURS",
            status: "IN_PROGRESS",
          },
          {
            id: 3,
            name: "COMPLETÉ",
            status: "IN_PROGRESS_TO_COMPLETED",
          },
        ],
        editedStatus: status,
      });
    }
    if (status === "TODO") {
      updateState({
        statusOptions: [
          {
            id: 1,
            name: "À FAIRE",
            status: "TODO",
          },
          {
            id: 2,
            name: "EN COURS",
            status: "TODO_TO_IN_PROGRESS",
          },
          {
            id: 3,
            name: "COMPLÉTÉ",
            status: "TODO_TO_COMPLETED",
          },
        ],
        editedStatus: status,
      });
    }
    if (status === "COMPLETED") {
      updateState({
        statusOptions: [
          {
            id: 1,
            name: "COMPLETÉ",
            status: "COMPLETED",
          },
          {
            id: 2,
            name: "EN COURS",
            status: "COMPLETED_TO_IN_PROGRESS",
          },
        ],
        editedStatus: status,
      });
    }
  };

  const renderStatus = () => {
    const { statusOptions, editedStatus } = state;
    if (statusOptions.length === 0) {
      return null;
    }

    return (
      <SelectUi
        data-cy="selectChangeStatusJob"
        disabled={editedStatus === "-1"}
        id="cpbr-job-status"
        inputLabelText={<Trans i18nKey="status.title" />}
        onChange={handleChangeFields("editedStatus")}
        value={`${editedStatus}`}
      >
        {renderMenuItemsStatus(statusOptions, "name")}
      </SelectUi>
    );
  };

  // If we're not ready to show the render, don't show it
  if (
    !state.initialJobStateLoaded ||
    props.jobTemplatesLoading ||
    props.supplierLocationsLoading ||
    props.customerLocationsLoading
  ) {
    return null;
  }

  const {
    errors,
    deleteWarningModalOpened,
    isInterruptionModalOpened,
    currentlySelectedCustomerLocationHash,
    locationModalOpened,
    noteComments,
    noteCommentsColor,
    noteLocation,
    purchaseOrderNo,
    startDate,
    jobTemplateId,
    notificationContacts,
    customerMobilePhoneNumber,
    customerLocations,
  } = state;

  const { job, open } = props;
  const { contractId, customerId, paidCustomerItem } = job;

  // those are the only flows that can interchange between themselves
  // Flow select only appear for jobs with one of these 3 flows
  const jobTemplates = props.jobTemplates.filter(
    (jt) => jt.code === "ROAD_VP" || jt.code === "ROAD_LI" || jt.code === "ROAD_VP_SW"
  );
  const jobTemplatesCodes = jobTemplates.map((jt) => jt.code.includes(job.code));
  const isFlowEditable = includes(jobTemplatesCodes, true);

  return (
    <DialogWrapper
      id="edit-job-modal"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>
        <Trans i18nKey="edit_job" />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormGroupWrapper>{renderStatus()}</FormGroupWrapper>

          <AutocompleteModal
            title={<Trans i18nKey="add_location" />}
            displayHelperButton
            autocompleteList={customerLocations}
            autocompletePlaceholder="Saisir l'emplacement"
            autocompleteValue={currentlySelectedCustomerLocationHash}
            buttonClass="cta-add-location"
            buttonTextId="preparation.add_location_button"
            contractId={contractId}
            customerId={customerId}
            contractSaleType={job?.contractSaleType}
            errors={errors}
            errorMessagesHelper={getErrorMessage("currentlySelectedCustomerLocationHash")}
            modalCallback={fetchCustomerLocationsAndCloseModal}
            modalName="customerLocation"
            modalOpened={locationModalOpened}
            onChangeAutocomplete={handleChangeCustomerLocation(currentlySelectedCustomerLocationHash)}
            onClickButton={handleToggleLocationModal(true)}
            onCloseModal={handleToggleLocationModal(false)}
            dropDownMenuPortalTarget="#edit-job-modal"
          />

          <FormGroupWrapper>
            <FormControl error={errors.startDate}>
              <DatePicker
                error={errors.startDate}
                label="Date"
                value={moment(startDate)}
                onChange={handleChangeStartDate}
                variant="outlined"
                disablePast={false}
              />

              <FormHelperText>{getErrorMessage("startDate")}</FormHelperText>
            </FormControl>
          </FormGroupWrapper>

          {isFlowEditable && (
            <FormGroupWrapper>
              <FormControl>
                <InputLabel shrink>Flow</InputLabel>
                <Select
                  notched
                  label="Flow"
                  value={jobTemplateId}
                  onChange={handleChangeJobTemplate()}
                  variant="outlined"
                >
                  {renderJobTemplatesMenuItems(jobTemplates)}
                </Select>
              </FormControl>
            </FormGroupWrapper>
          )}

          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              pb: 3,
            }}
          >
            {renderLocationsFields()}
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
              pb: 3,
            }}
          >
            <TextField
              fullWidth
              error={errors.purchaseOrderNo}
              helperText={getErrorMessage("purchaseOrderNo")}
              label={<Trans i18nKey="purchase_order_no" />}
              onChange={handleChangeFields("purchaseOrderNo")}
              value={purchaseOrderNo || ""}
            />
          </Stack>

          {/* EMAIL AND SMS PHONE NUMBER TEXTFIELD */}
          <Stack spacing={1}>
            <Typography variant="body1">Notifications SMS ou courriel</Typography>
            <SmsAndEmailNotifications
              notificationContacts={notificationContacts}
              customerMobilePhoneNumber={customerMobilePhoneNumber}
              addNotificationButtonDisabled={
                notificationContacts[0].smsOrEmail === "" ||
                Object.values(notificationContacts).filter((contact) => !contact.delete).length === 4
              }
              copyCellphoneNumberDisabled={notificationContacts[0].smsOrEmail || !customerMobilePhoneNumber}
              setSingleCurrent={handleAddAndRemoveNotificationContact}
              setSingleNestedCurrent={handleChangeValueNotificationContact}
            />
          </Stack>

          <FormGroupWrapper>
            <TextAreaUi
              error={errors.note_comments}
              helperText={getErrorMessage("note_comments")}
              id="cpbr-note-comments"
              label={<Trans i18nKey="preparation.note_comments" />}
              onChange={handleChangeFields("noteComments")}
              value={noteComments || ""}
              data-cy="noteJobEditJob"
            />
          </FormGroupWrapper>

          <FormGroupWrapper>
            <TextAreaUi
              error={errors.note_location}
              helperText={getErrorMessage("note_location")}
              id="cpbr-note-location"
              label={<Trans i18nKey="preparation.note_location" />}
              onChange={handleChangeFields("noteLocation")}
              value={noteLocation || ""}
              data-cy="noteLocationEditJob"
            />
          </FormGroupWrapper>

          <FormGroupWrapper>
            <InlineColorPicker
              color={noteCommentsColor || "#000"}
              id="cpbr-note-comments-color"
              onChangeComplete={({ hex }) => {
                updateState({ noteCommentsColor: hex });
              }}
            />
          </FormGroupWrapper>
        </form>
      </DialogContent>
      <DialogActionsSC>
        {!job.intervention.is_interrupted && (
          <ButtonCustom onClick={handleToggleWarningModal(true)}>
            <Trans i18nKey="delete_job" />
          </ButtonCustom>
        )}

        <Box
          sx={{
            textAlign: "left",
          }}
        >
          {!job.intervention.is_interrupted && (
            <ButtonCustom onClick={handleToggleInterruptionModal(true)}>INTERRUPTION</ButtonCustom>
          )}
        </Box>

        <span>
          <Button onClick={handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            data-cy="buttonConfirmEditJob"
          >
            <Trans i18nKey="edit" />
          </Button>
        </span>
      </DialogActionsSC>
      {isInterruptionModalOpened && (
        <ModalInterruption
          onCancel={handleToggleInterruptionModal(false)}
          open={isInterruptionModalOpened}
          handleClose={handleClose}
          refreshList={props.refreshList}
          job={job}
        />
      )}
      <ModalWarning
        onCancel={handleToggleWarningModal(false)}
        onSubmit={handleDeleteJob}
        open={deleteWarningModalOpened}
        title={<Trans i18nKey="warning" />}
      >
        {paidCustomerItem?.refunded !== undefined && !paidCustomerItem?.refunded ? (
          <Typography>
            Vous allez rembourser la somme de{" "}
            {(paidCustomerItem?.refundableAmount / 100).toFixed(2) || "somme introuvable"}$ en supprimant cette tâche,
            êtes-vous sûr?
          </Typography>
        ) : (
          <Trans i18nKey="warning_delete_job" />
        )}
      </ModalWarning>
    </DialogWrapper>
  );
};

ModalEditJob.propTypes = {
  customerItem: PropTypes.object.isRequired,
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteJob: PropTypes.func.isRequired,
  fetchAllSuppliersLocations: PropTypes.func.isRequired,
  fetchCustomerLocations: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  jobTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  updateJob: PropTypes.func.isRequired,
};

export default withRouter(
  withJobs(withJobTemplates(withCustomerLocations(withLocations(withSupplierLocations(ModalEditJob)))))
);
