// React imports
import React, { memo, useCallback, useState } from "react";

// External iports
import Select from "react-select";
import { isEqual, map, orderBy } from "lodash";
import { Add } from "@mui/icons-material";
import { Stack, IconButton, Tooltip } from "@mui/material";
import ModalCustomerLocation from "@components/ModalCustomerLocation";
import { Trans } from "react-i18next";

function LocationSelect({ isError, location, locations, contract, customer, handlers }) {
  // console.log("[LocationSelect] >>> RENDERED");
  const [isCustomerLocationModalOpen, setIsCustomerLocationModalOpen] = useState(false);

  const handleChangeLocation = useCallback((location) => {
    if (location) {
      let updates = {
        location: location,
      };

      if (location.data?.areaIsOutOfArea && +location.data?.areaCustomFee) {
        updates = { ...updates, locationAreaCustomFee: +location.data?.areaCustomFee };
      }

      handlers.setCurrent((prevState) => ({ ...prevState, ...updates }));
    }
  }, []);

  const handleCloseCustomerLocationModal = useCallback(
    (customerLocation) => {
      if (customerLocation) {
        const newCustomerLocation = {
          value: customerLocation.id,
          label: customerLocation.name,
          data: customerLocation,
        };
        const orderedLocations = orderBy([...locations.data, newCustomerLocation], ["label"], ["asc"]);

        handlers.setData((prevState) => ({
          ...prevState,
          locations: { ...prevState.locations, data: orderedLocations },
          destinations: { ...prevState.destinations, data: orderedLocations },
        }));

        handleChangeLocation(newCustomerLocation);
      }

      setIsCustomerLocationModalOpen(false);
    },
    [locations]
  );

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
      }}
    >
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            height: 42,
            boxShadow: isError ? "none" : base.boxShadow,
            borderColor: isError ? "red" : base.borderColor,
            "&:hover": {
              borderColor: isError ? "red" : base["&:hover"].borderColor,
            },
          }),
        }}
        value={location.value ? location : null}
        isLoading={locations.loading}
        isDisabled={locations.loading}
        menuPosition="fixed"
        onChange={(location) => handleChangeLocation(location)}
        options={locations.data}
        placeholder={"Sélectionner un emplacement"}
      />
      {/* <Stack width="80px" direction="row" justifyContent="center" pl={1}> */}
      <Stack
        direction="row"
        sx={{
          width: "40px",
          justifyContent: "center",
          ml: 1,
        }}
      >
        <Tooltip
          title="Ajouter"
          placement="top"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -10] },
                },
              ],
            },
          }}
        >
          <IconButton
            color="primary"
            size="small"
            onClick={() => setIsCustomerLocationModalOpen(true)}
          >
            <Add sx={{ fontSize: 30 }} />
          </IconButton>
        </Tooltip>
      </Stack>
      {isCustomerLocationModalOpen && (
        <ModalCustomerLocation
          title={<Trans i18nKey="add_location" />}
          customerId={customer.value}
          contractId={contract.value}
          contractSaleType={contract.data.saleTypeCode}
          callback={handleCloseCustomerLocationModal}
          onClose={() => setIsCustomerLocationModalOpen(false)}
          open={isCustomerLocationModalOpen}
        />
      )}
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { isError: prevIsError, location: prevLocation, locations: prevLocations } = prevProps;
  const { isError: nextIsError, location: nextLocation, locations: nextLocations } = nextProps;
  let arePropsEqual = true;

  // If the locations array changed, rerender
  if (!isEqual(prevLocations, nextLocations)) {
    arePropsEqual = false;
  }

  // If the current location changed, rerender
  if (!isEqual(prevLocation, nextLocation)) {
    arePropsEqual = false;
  }

  // If the error changed, rerender
  if (!isEqual(prevIsError, nextIsError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(LocationSelect, areEqual);
