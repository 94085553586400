import React, { ReactElement } from "react";

import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

const OpenIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="8"
        height="7"
        viewBox="0 0 8 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 3.83333V5.83333C7 6.01014 6.92976 6.17971 6.80474 6.30474C6.67971 6.42976 6.51014 6.5 6.33333 6.5H1.66667C1.48986 6.5 1.32029 6.42976 1.19526 6.30474C1.07024 6.17971 1 6.01014 1 5.83333V1.16667C1 0.989856 1.07024 0.820286 1.19526 0.695262C1.32029 0.570238 1.48986 0.5 1.66667 0.5H3.66667M7 0.5L4 3.5M7 0.5H5M7 0.5V2.5"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default OpenIcon;
