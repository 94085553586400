import AddIcon from "@mui/icons-material/Add";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Toolbar } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { debounce } from "lodash";
import { withCustomerLocations } from "optigo-redux";
import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import FloatingActionButton from "./ui/FloatingActionButton";
import ModalCustomerLocation from "./ModalCustomerLocation";
import ModalWarning from "./ModalWarning";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableCellNoData from "./ui/TableCellNoData";
import TableLoading from "./ui/TableLoading";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import TablePaginationWrapper from "./ui/TablePaginationWrapper";
import TextFieldUi from "./ui/TextField";
import {
  filteringState,
  handleFilter,
  handlePageChange,
  handleRowsPerPageChange,
  handleSort,
  sortedData,
} from "@utils/filtering";
import useFilterAndPagination from "@/hooks/useFilterAndPagination";

const data = {
  adr1: { label: <Trans i18nKey="address.adr_normal" /> },
  city: { label: <Trans i18nKey="address.city" /> },
  name: { label: <Trans i18nKey="name_simple" /> },
  postalCode: { label: <Trans i18nKey="address.postal_code" /> },
  doorNo: { label: <Trans i18nKey="address.door_no" /> },
};

const CustomerLocationsList = (props) => {
  const initialSort = {
    columnName: "identifier",
    direction: "asc",
  };

  const initialState = {
    ...filteringState,
    customerLocationModalOpened: false,
    customerLocationToEdit: {},
    customerLocationToDelete: null,
    deleteCustomerLocationModalOpened: false,
    sort: {
      ...initialSort,
    },
  };

  const [state, setState] = useState(initialState);
  const {
    customerLocationModalOpened,
    customerLocationToEdit,
    customerLocationToDelete,
    deleteCustomerLocationModalOpened,
  } = state;

  const { filterStates, updateFilterStates } = useFilterAndPagination();

  const fetchCustomerLocations = useCallback(() => {
    const { fetchCustomerLocationsListGroupBy, router } = props;
    const { params } = router;
    const { contractId, customerId } = params;

    fetchCustomerLocationsListGroupBy(
      customerId,
      { filter: filterStates.filter.trim(), page: filterStates.page + 1, limit: filterStates.rowsPerPage },
      contractId
    );
  }, [props, filterStates]);

  const debouncedFetchCustomerLocations = useMemo(
    () => debounce(fetchCustomerLocations, 300),
    [fetchCustomerLocations]
  );

  useEffect(() => {
    fetchCustomerLocations();

    return () => {
      props.flushCustomerLocations();
    };
  }, []);

  useEffect(() => {
    debouncedFetchCustomerLocations();
  }, [filterStates.page, filterStates.rowsPerPage, filterStates.filter]);

  const fetchAndResetPagination = useCallback(() => {
    setState(initialState);
    fetchCustomerLocations();
  }, [fetchCustomerLocations]);

  const handleDeleteCustomerLocation = useCallback(async () => {
    const { deleteLocation, router } = props;

    await deleteLocation(router.params.customerId, customerLocationToDelete);

    setState((prev) => ({
      ...prev,
      customerLocationToDelete: null,
      deleteCustomerLocationModalOpened: false,
    }));

    fetchCustomerLocations();
  }, [props, customerLocationToDelete, fetchCustomerLocations]);

  const handleFilterChange = (event) => {
    event.persist();
    updateFilterStates.setFilter(event.target.value);
  };

  // const handlePageChangeCallback = useCallback(handlePageChange(fetchCustomerLocations), [fetchCustomerLocations]);
  const handleChangePage = (event, newPage) => {
    updateFilterStates.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    updateFilterStates.setRowsPerPage(parseInt(event.target.value, 10));
    updateFilterStates.setPage(0);
  };

  const handleRequestSort = (event, columnName) => {
    const isAsc = filterStates.sort.columnName === columnName && filterStates.sort.direction === "asc";
    updateFilterStates.setSort(columnName, isAsc ? "desc" : "asc");
  };

  const handleToggleCustomerLocationModal = useCallback(
    (opened, customerLocation) => (e) => {
      if (opened) {
        e.stopPropagation();
      }
      const newCustomerLocationToEdit = opened ? customerLocation : initialState.customerLocationToEdit;

      setState((prev) => ({
        ...prev,
        customerLocationModalOpened: opened,
        customerLocationToEdit: newCustomerLocationToEdit,
      }));
    },
    []
  );

  const handleNavigate = useCallback(
    (path) => (e) => {
      const { router } = props;

      e.stopPropagation();
      router.navigate(path);
    },
    [props]
  );

  const handleToggleDeleteCustomerLocationModal = useCallback(
    (opened, customerLocationId) => (e) => {
      if (opened) {
        e.stopPropagation();
      }
      const newCustomerLocationToDelete = opened ? customerLocationId : initialState.customerLocationToDelete;

      setState((prev) => ({
        ...prev,
        deleteCustomerLocationModalOpened: opened,
        customerLocationToDelete: newCustomerLocationToDelete,
      }));
    },
    []
  );

  const handleNavigateToEditLocation = useCallback(
    (path) => (e) => {
      const { router } = props;

      e.stopPropagation();
      router.navigate(path);
    },
    [props]
  );

  const renderTableHead = useCallback(() => {
    const { columnName, direction } = filterStates.sort;

    return (
      <TableHead>
        <TableRow>
          {["name", "doorNo", "adr1", "postalCode", "city"].map((name) => (
            <TableCell key={name}>
              <TableSortLabel
                active={columnName === name}
                direction={direction}
                onClick={(event) => handleRequestSort(event, name)}
              >
                {data[name].label}
              </TableSortLabel>
            </TableCell>
          ))}

          <TableCell />
        </TableRow>
      </TableHead>
    );
  }, [filterStates.sort, handleRequestSort]);

  const renderTableRows = useCallback(() => {
    const { customerLocations, customerLocationsLoading, contract } = props;

    if (!customerLocations.length) {
      return <TableCellNoData />;
    }

    return sortedData(customerLocations, state).map((customerLocation) => {
      const { id, adr1, city, doorNo, name, postalCode, province, companyName, residentialPoiOfCollectPois } =
        customerLocation;

      let path = `/customers/${contract.customerId}/contracts/${contract.id}/locations/${id}`;
      let pathEdit = `/customers/${contract.customerId}/contracts/${contract.id}/locations/${id}/edit`;

      if (residentialPoiOfCollectPois) {
        //if there is a residential_poi AND some collect_poi in the list AND is from ci_web
        const { id } = residentialPoiOfCollectPois;
        path = `/customers/${contract.customerId}/contracts/${contract.id}/locations/${id}`;
        pathEdit = `/customers/${contract.customerId}/contracts/${contract.id}/locations/${id}/edit`;
      }

      return (
        <TableRow
          key={id}
          onClick={handleNavigate(path)}
          sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
        >
          <TableCell
            style={{
              overflowWrap: "break-word",
              width: "50%",
              whiteSpace: "normal",
            }}
          >
            {companyName && companyName.length > 1 ? companyName : name}
          </TableCell>
          <TableCell>{doorNo}</TableCell>
          <TableCell
            style={{
              overflowWrap: "break-word",
              width: "50%",
              whiteSpace: "normal",
            }}
          >
            {doorNo && city && province && postalCode
              ? `${adr1}`
              : `${doorNo || ""}, ${adr1 || ""}, ${city || ""}, ${province || ""}, ${postalCode || ""}`}
          </TableCell>
          <TableCell classes={{ root: "zipcode-cell" }}>{postalCode}</TableCell>
          <TableCell>
            {city}, {province}
          </TableCell>
          <TableCell classes={{ root: "action-cell" }} />
          <TableCell classes={{ root: "action-cell no-pointer" }}>
            <IconButton
              color="secondary"
              id="cpbr-delete-location"
              onClick={handleToggleDeleteCustomerLocationModal(true, id)}
              size="large"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }, [props, state, handleNavigate, handleToggleDeleteCustomerLocationModal, filterStates]);

  const { contract, customerLocationsCount } = props;
  const actionName = customerLocationToEdit.id ? <Trans i18nKey="edit" /> : <Trans i18nKey="add" />;

  return (
    <PageContainer>
      <PaperWrapper>
        <div>
          <FloatingActionButton
            color="secondary"
            onClick={handleToggleCustomerLocationModal(true, initialState.customerLocationToEdit)}
          >
            <AddIcon />
          </FloatingActionButton>
        </div>

        <TableOverflowWrapper>
          <Toolbar>
            <TextFieldUi
              id="cpbr-filtre"
              label={<Trans i18nKey="filter" />}
              onChange={handleFilterChange}
              type="search"
              value={filterStates.filter}
            />
          </Toolbar>

          <Table>
            {renderTableHead()}

            <TableBody>{renderTableRows()}</TableBody>
          </Table>
        </TableOverflowWrapper>
        <TablePaginationWrapper
          component="div"
          count={customerLocationsCount}
          id="cpbr-pagination"
          labelRowsPerPage=""
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={filterStates.page}
          rowsPerPage={filterStates.rowsPerPage}
        />
      </PaperWrapper>

      <ModalCustomerLocation
        title={<Trans i18nKey="add_location" />}
        customerId={contract.customerId}
        contractId={contract.id}
        contractSaleType={contract.saleTypeCode}
        callback={fetchAndResetPagination}
        onClose={handleToggleCustomerLocationModal(false)}
        open={customerLocationModalOpened}
      />

      <ModalWarning
        onCancel={handleToggleDeleteCustomerLocationModal(false)}
        onSubmit={handleDeleteCustomerLocation}
        open={deleteCustomerLocationModalOpened}
        title={<Trans i18nKey="warning" />}
      >
        <Trans i18nKey="warning_delete_location" />
      </ModalWarning>
    </PageContainer>
  );
};

export default withCustomerLocations(CustomerLocationsList);
