/**
 * Converts a camelCase string to snake_case.
 *
 * @param {string} str - The string to convert.
 * @returns {string} The converted string.
 */
const camelToSnakeCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

// Define a type for a generic object
type GenericObject = { [key: string]: unknown };

/**
 * Converts all keys in an object from camelCase to snake_case.
 * This function works recursively, so nested objects will also have their keys converted.
 *
 * @param {GenericObject} obj - The object to convert.
 * @returns {GenericObject} The converted object.
 */
function convertObjectToSnakeCase(obj: GenericObject): GenericObject {
  const output: GenericObject = {};

  for (const key in obj) {
    // Convert camelCase to snake_case and handle cases with numbers like adr1 to adr_1
    const newKey = key
      .replace(/([a-z])([A-Z])/g, "$1_$2")
      .toLowerCase()
      .replace(/(\d+)/g, "_$1");

    if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
      output[newKey] = convertObjectToSnakeCase(obj[key] as GenericObject);
    } else {
      output[newKey] = obj[key];
    }
  }
  return output;
}

export { convertObjectToSnakeCase, camelToSnakeCase };
