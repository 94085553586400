import { formatReportPayload } from "@/services/reports/ReportService";
import { MutationResult } from "@@types/apiResponse";
import { ProblemCaseReport } from "@@types/reports/problem-case-report.type";
import { Report } from "@components/report/hooks/report-layout.hook";

import { axiosClientV2 as http } from "../axios";

const createProblemCasesReport = async (
  reportSettings: Report,
  customerId: string
): Promise<MutationResult<ProblemCaseReport[]>> => {
  const res = await http.post<MutationResult<ProblemCaseReport[]>>(`/reports/problem_cases_report`, {
    reportSettings: formatReportPayload(reportSettings),
    customerId,
  });
  return res.data;
};

export default createProblemCasesReport;
