import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Grid,
  Button,
  TableContainer,
  Paper,
} from "@mui/material";
import styled from "@emotion/styled";
import { debounce, orderBy } from "lodash";
import { PropTypes } from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { Trans } from "react-i18next";
import { withRouteTemplates, withRouteTemplate, withCiWebContractRoutes } from "optigo-redux";

import ModalWarning from "./ModalWarning";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableCellNoData from "./ui/TableCellNoData";
import TableLoading from "./ui/TableLoading";
import TablePaginationWrapper from "./ui/TablePaginationWrapper";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import TextFieldUi from "./ui/TextField";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { filteringState, handleFilter, handlePageChange, handleRowsPerPageChange, handleSort } from "@utils/filtering";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { withRouter } from "@utils/withRouter";
import ModalCustomerLocation from "./ModalCustomerLocation";
import ModalAddSubRouteTemplate from "@components/subRouteManagement/master-route-template-menu/modal-add-sub-route-template/ModalAddSubRouteTemplate";
import ModalAddMasterRouteTemplate from "@components/modals/routeTemplate/add-master-route-template/ModalAddMasterRouteTemplate";
import CollapsableListParent from "@ui/collapsable-list/CollapsableListParent";
import {
  CollectionPath,
  MasterCollectionSectorsPath,
  PEP_ROUTES,
  ROUTES_PARAMETERS,
} from "@components/routing/constants";

const headerLabels = {
  name: { label: <Trans i18nKey="route_templates.name" /> },
  masterRoute: { label: <Trans i18nKey="route_templates.master_route_name" /> },
  routeLocationsCount: { label: <Trans i18nKey="route_templates.lifting_count" /> },
  createRouteTemplateCI: { label: <Trans i18nKey="route_templates.sub_route" /> },
  collapse: { label: <Trans i18nKey="expand" /> },
  editRoute: { label: <Trans i18nKey="edit_route_template" /> },
  deleteRoute: { label: <Trans i18nKey="route_templates.delete" /> },
};

const CollapsibleRow = styled(TableRow)`
  height: 0 !important;
  background-color: #f8f8f8 !important;

  td {
    display: none !important;
    height: 0 !important;
    overflow: hidden;
    min-height: 0px;
  }

  &.expended-true {
    height: initial;

    td {
      display: table-cell !important;
    }
  }
`;

const CustomTable = styled(Table)`
  tbody {
    tr:nth-of-type(even) {
      background-color: transparent;
    }

    tr.collapsible-row {
      background-color: #f7f7f7;
    }
  }
`;

const RouteTemplatesList = (props) => {
  const initialSort = {
    columnName: "name",
    direction: "asc",
  };

  const initialState = {
    ...filteringState,
    deleteRouteTemplateModalOpened: false,
    ciWebContext: false,
    masterRouteIdForDelete: null,
    routeTemplateModalOpened: false,
    routeTemplateToEdit: {},
    masterRouteIdForUpdate: null,
    openCreateRouteTemplate: false,
    openCreateMasterRouteTemplate: false,
    supplier: { id: null, error: false },
    routeTemplates: [],
    newRouteTemplate: { name: "", error: false },
    masterRouteTemplate: {},
    day: { value: null, error: false },
    sort: {
      ...initialSort,
    },
    collapseOpen: false,
    collapse: {},
    customerLocationModalOpened: false,
  };

  const [state, setState] = useState(initialState);

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const debouncedFetchRouteTemplates = useCallback(
    debounce(() => {
      fetchRouteTemplates();
    }, 300),
    []
  );

  const handleFilterChange = useCallback(
    (event) => {
      updateState({ filter: event.target.value });
      debouncedFetchRouteTemplates();
    },
    [debouncedFetchRouteTemplates]
  );

  const handlePageChangeFunc = useCallback((event, newPage) => {
    updateState({ page: newPage });
    fetchRouteTemplates();
  }, []);

  const handleRowsPerPageChangeFunc = useCallback((event) => {
    updateState({ limit: parseInt(event.target.value, 10), page: 0 });
    fetchRouteTemplates();
  }, []);

  const handleSortFunc = useCallback(
    (columnName) => () => {
      updateState((prevState) => ({
        sort: {
          columnName,
          direction: prevState.sort.columnName === columnName && prevState.sort.direction === "asc" ? "desc" : "asc",
        },
      }));
    },
    []
  );

  const setCiWebContext = useCallback(() => {
    const { router, ciWebContractRoutes } = props;
    const { customerItemId } = router.params;
    const ciWebContractRoute = ciWebContractRoutes.find(
      (item) => parseInt(item.customerItemId) === parseInt(customerItemId)
    );
    if (ciWebContractRoute && ciWebContractRoute.ciWebSync === true) {
      updateState({ ciWebContext: true });
    }
  }, [props.router.params, props.ciWebContractRoutes]);

  const createStateForRouteMaster = useCallback(() => {
    const tempoObject = {};
    if (props.routeTemplates.length === 0 || props.routeTemplatesLoading) {
      return null;
    }
    props.routeTemplates.forEach((routeTemplate) => {
      if (routeTemplate.isAMasterRoute) {
        const { completeName } = routeTemplate;
        tempoObject[completeName] = false;
      }
    });
    updateState({ collapse: tempoObject });
  }, [props.routeTemplates, props.routeTemplatesLoading]);

  const fetchAndResetPagination = useCallback(() => {
    setState(initialState);
    fetchRouteTemplates();
  }, []);

  const fetchRouteTemplates = useCallback(() => {
    const { fetchRouteTemplatesByCustomerItem, router } = props;
    const { filter, limit, page } = state;

    fetchRouteTemplatesByCustomerItem(router.params.customerItemId, {
      filter: filter.trim(),
      page: page + 1,
      limit: null,
    });
  }, [
    props.fetchRouteTemplatesByCustomerItem,
    props.router.params.customerItemId,
    state.filter,
    state.limit,
    state.page,
  ]);

  const handleAddNewRouteTemplate = useCallback(() => {
    updateState({ openCreateMasterRouteTemplate: true });
  }, []);

  const handleCloseDeleteRouteTemplateModal = useCallback(() => {
    updateState({
      deleteRouteTemplateModalOpened: false,
      routeTemplateToDelete: null,
    });
  }, []);

  const handleOpenDeleteRouteTemplateModal = useCallback((e, routeTemplateToDeleteId, masterRouteTemplateId) => {
    e.stopPropagation();

    updateState({
      deleteRouteTemplateModalOpened: true,
      routeTemplateToDelete: routeTemplateToDeleteId,
      masterRouteIdForUpdate: masterRouteTemplateId,
    });
  }, []);

  const handleChangeSupplier = useCallback((event) => {
    updateState({
      supplier: { id: event.target.value, error: false },
    });
  }, []);

  const handleChangeDay = useCallback((event) => {
    updateState({
      day: { value: event.target.value, error: false },
    });
  }, []);

  const handleCloseCreateMasterRouteTemplateModal = useCallback(() => {
    updateState({
      openCreateMasterRouteTemplate: false,
      newRouteTemplate: { name: "", error: false },
      supplier: { id: null, error: false },
      day: { value: "", error: false },
    });
  }, []);

  const validNewMasterRouteTemplate = useCallback(() => {
    const { supplier, collapse, day } = state;
    const existingMasterRouteTemplatesDays = Object.keys(collapse)
      .map((masterRouteTemplate) => masterRouteTemplate.split("-")[3])
      .join("");

    let isValid = true;

    if (!supplier.id) {
      updateState({ supplier: { ...supplier, error: true } });
      isValid = false;
    }

    if (existingMasterRouteTemplatesDays.match(day.value) || !day.value) {
      updateState({ day: { ...day, error: true } });
      isValid = false;
    }

    return isValid;
  }, [state.supplier, state.collapse, state.day]);

  const handleCreateNewMasterRouteTemplate = useCallback(async () => {
    const { day, supplier } = state;
    const { createRouteTemplate, router, customerItem } = props;
    const { area, loadingType, matter } = customerItem;

    const isValid = validNewMasterRouteTemplate();

    if (isValid) {
      await createRouteTemplate(router.params.customerItemId, {
        average_lifting_time: null,
        customer_item_id: customerItem.id,
        master_route: `${loadingType}-${area.toUpperCase()}-${matter}-${day.value}`,
        master_route_id: null,
        is_a_master_route: true,
        name: "-",
        supplier_location_id: supplier.id,
      });

      updateState({ openCreateMasterRouteTemplate: false, newRouteTemplate: { name: "", error: false } });
      fetchRouteTemplates();
    }
  }, [
    state.day,
    state.supplier,
    props.createRouteTemplate,
    props.router.params.customerItemId,
    props.customerItem,
    validNewMasterRouteTemplate,
    fetchRouteTemplates,
  ]);

  const handleOpenCreateRouteTemplateModal = useCallback(
    (e, id) => {
      const { routeTemplates } = props;

      const routeTemplate = routeTemplates.find((routeTemplate) => routeTemplate.id === id);
      e.stopPropagation();
      updateState({
        openCreateRouteTemplate: true,
        routeTemplates: routeTemplate.children,
        masterRouteTemplate: routeTemplate,
      });
    },
    [props.routeTemplates]
  );

  const handleCloseCreateRouteTemplateModal = useCallback(() => {
    updateState({
      openCreateRouteTemplate: false,
      routeTemplates: [],
      masterRouteId: "",
      newRouteTemplate: { name: "", error: false },
    });
  }, []);

  const handleChangeNewRouteTemplateName = useCallback((event) => {
    updateState({ newRouteTemplate: { name: event.target.value, error: false } });
  }, []);

  const valid = useCallback((newRouteTemplate, routeTemplates) => {
    const existingRouteTemplatesNames = routeTemplates.map((routeTemplate) => routeTemplate.name).join("");

    const validNameValues = new RegExp(/^[0-9\b]+$/);

    if (!validNameValues.test(newRouteTemplate.name) || existingRouteTemplatesNames.match(newRouteTemplate.name)) {
      updateState({ newRouteTemplate: { ...newRouteTemplate, error: true } });
      return false;
    }
    return true;
  }, []);

  const handleCreateNewRouteTemplate = useCallback(async () => {
    const { routeTemplates, newRouteTemplate, masterRouteTemplate } = state;
    const { createRouteTemplate, router } = props;

    const isValid = valid(newRouteTemplate, routeTemplates);

    if (isValid) {
      await createRouteTemplate(router.params.customerItemId, {
        average_lifting_time: masterRouteTemplate.averageLiftingTime,
        customer_item_id: masterRouteTemplate.customerItemId,
        master_route: masterRouteTemplate.masterRoute,
        master_route_id: masterRouteTemplate.id,
        is_a_master_route: false,
        name: newRouteTemplate.name,
        supplier_location_id: masterRouteTemplate.supplierLocationId,
      });

      updateState({ openCreateRouteTemplate: false, newRouteTemplate: { name: "", error: false } });
      fetchRouteTemplates();
    }
  }, [
    state.routeTemplates,
    state.newRouteTemplate,
    state.masterRouteTemplate,
    props.createRouteTemplate,
    props.router.params.customerItemId,
    valid,
    fetchRouteTemplates,
  ]);

  const handleDeleteRouteTemplate = useCallback(async () => {
    const { deleteRouteTemplate } = props;
    const { masterRouteIdForUpdate, routeTemplateToDelete } = state;

    await deleteRouteTemplate(routeTemplateToDelete, masterRouteIdForUpdate);

    updateState({
      deleteRouteTemplateModalOpened: false,
      routeTemplateToDelete: null,
    });

    fetchRouteTemplates();
  }, [props.deleteRouteTemplate, state.masterRouteIdForUpdate, state.routeTemplateToDelete, fetchRouteTemplates]);

  const handleToggleDeleteRouteTemplateModal = useCallback(
    (action, id, masterRouteId) => (e) => {
      e && e.stopPropagation();
      updateState({
        deleteRouteTemplateModalOpened: action,
        routeTemplateToDelete: id,
        masterRouteIdForUpdate: masterRouteId,
      });
    },
    []
  );

  const renderDeleteIcon = useCallback(
    (id, masterRouteId) => (
      <TableCell classes={{ root: "action-cell" }}>
        <IconButton
          color="secondary"
          id="cpbr-delete-route-template"
          onClick={handleToggleDeleteRouteTemplateModal(true, id, masterRouteId)}
          size="large"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    ),
    [handleToggleDeleteRouteTemplateModal]
  );

  const renderCreateRouteTemplateIcon = useCallback(
    (routeTemplates, masterRouteTemplate) => (
      <TableCell classes={{ root: "action-cell" }}>
        <IconButton
          color="secondary"
          id="cpbr-add-create-route-template"
          onClick={(e) => handleOpenCreateRouteTemplateModal(e, masterRouteTemplate.id)}
          size="large"
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </TableCell>
    ),
    [handleOpenCreateRouteTemplateModal]
  );

  const renderTableHead = useCallback(() => {
    const { columnName, direction } = state.sort;

    return (
      <TableHead>
        <TableRow>
          {["collapse", "name", "masterRoute", "routeLocationsCount", "createRouteTemplateCI"].map((name) => (
            <TableCell key={name}>
              <TableSortLabel
                active={columnName === name}
                direction={direction}
                onClick={handleSortFunc(name)}
              >
                {headerLabels[name].label}
              </TableSortLabel>
            </TableCell>
          ))}

          <TableCell />
        </TableRow>
      </TableHead>
    );
  }, [state.sort, handleSortFunc]);

  const handleCollapse = useCallback((routeMasterName) => {
    setState((prevState) => {
      const copyCollapse = { ...prevState.collapse };
      const actualValue = copyCollapse[routeMasterName];
      copyCollapse[routeMasterName] = !actualValue;
      return {
        ...prevState,
        collapse: copyCollapse,
        collapseOpen: !prevState.collapseOpen,
      };
    });
  }, []);

  const ordreRouteTemplate = useCallback((arrayRouteMaster, key) => {
    return arrayRouteMaster.sort((a, b) => a[key] - b[key]);
  }, []);

  const handleRowClick = useCallback(
    (path) => () => {
      props.router.navigate(path);
    },
    [props.router]
  );

  const renderTableRowRouteTemplate = useCallback(
    (routeTemplateMaster, arrayWithOutRouteMaster, collapse, completeName) => {
      const arrayWithOut = [];
      let rowToReturn = null;
      const { router } = props;
      const { customerId, contractId } = router.params;

      const getRowPath = ({ customerId, customerItemId, masterRouteId, id }) => {
        if (import.meta.env.VITE_FEATURE_FLAG_SUB_ROUTE_V2 === "true") {
          return `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_template_locations?masterRouteId=${masterRouteId}&routeTemplateIds=${id}`;
        }

        return `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_templates/${id}/edit/master_route/${masterRouteId}`;
      };

      arrayWithOutRouteMaster.map((routeTemplate) => {
        if (routeTemplate.masterRoute === routeTemplateMaster.masterRoute) {
          arrayWithOut.push(routeTemplate);
        }
      });

      rowToReturn = ordreRouteTemplate(arrayWithOut, "name").map((routeTemplate) => {
        const { id, customerItemId, name, masterRoute, routeLocationsCount, masterRouteId } = routeTemplate;
        return (
          <CollapsibleRow
            key={id}
            className={`collapsible-row expended-${collapse[completeName]} link-row`}
            onClick={handleRowClick(getRowPath({ masterRouteId, customerId, customerItemId, id }))}
          >
            <TableCell />
            <TableCell>{name}</TableCell>
            <TableCell>{masterRoute}</TableCell>
            <TableCell>{routeLocationsCount}</TableCell>
            <TableCell />
            <TableCell />
            {renderDeleteIcon(id, masterRouteId)}
          </CollapsibleRow>
        );
      });
      return rowToReturn;
    },
    [props.router.params, renderDeleteIcon, ordreRouteTemplate, handleRowClick]
  );

  const renderTableMasterRouteRows = useCallback(() => {
    const { collapse } = state;
    const { routeTemplates, routeTemplatesLoading, router } = props;
    const customerId = router.params.customerId;
    const contractId = router.params.contractId;
    const customerItemId = router.params.customerItemId;
    const arrayRouteMaster = [];
    let arrayWithOutRouteMaster = [];
    let rowToReturn = null;

    if (routeTemplatesLoading) {
      return <TableLoading />;
    }

    if (!routeTemplates.length) {
      return <TableCellNoData />;
    }

    routeTemplates.forEach((routeTemplate) => {
      if (routeTemplate.isAMasterRoute || routeTemplate.masterRouteId === null) {
        arrayRouteMaster.push(routeTemplate);
      }
    });

    arrayWithOutRouteMaster = routeTemplates.filter((ar) => !arrayRouteMaster.find((rm) => rm.id === ar.id)); // les sous-routes des route-maîtres
    const subRouteOnly = arrayWithOutRouteMaster.filter((awrm) => awrm.masterRouteId !== null);

    rowToReturn = ordreRouteTemplate(arrayRouteMaster, "sequence").map((routeTemplateMaster, index) => {
      const { id, masterRoute, name, routeLocationsCount, masterRouteId, isAMasterRoute, completeName } =
        routeTemplateMaster;

      const getRowPath = ({ customerId, customerItemId, id }) => {
        if (import.meta.env.VITE_FEATURE_FLAG_SUB_ROUTE_V2 === "true") {
          return `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_template_locations?masterRouteId=${id}&routeTemplateIds=${id}`;
        }

        return `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_templates/${id}/edit`;
      };

      const subRoutes = subRouteOnly.filter((subRoute) => subRoute.masterRouteId === id);
      const masterRouteTemplate = routeTemplates.find((routeTemplate) => routeTemplate.id === id);

      return (
        <React.Fragment key={index}>
          <TableRow
            className="link-row"
            key={id}
            onClick={() => handleCollapse(completeName)}
          >
            <TableCell>
              <IconButton
                size="large"
                color="secondary"
              >
                {collapse[completeName] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{masterRoute}</TableCell>
            <TableCell>{routeLocationsCount}</TableCell>
            {isAMasterRoute && masterRouteId === null ? (
              renderCreateRouteTemplateIcon(subRoutes, masterRouteTemplate)
            ) : (
              <TableCell />
            )}

            <TableCell>
              <IconButton
                color="secondary"
                onClick={handleRowClick(getRowPath({ customerItemId, customerId, id }))}
                size="large"
              >
                <OpenInNewIcon />
              </IconButton>
            </TableCell>
            <TableCell>-</TableCell>
          </TableRow>

          {renderTableRowRouteTemplate(routeTemplateMaster, subRouteOnly, collapse, completeName)}
        </React.Fragment>
      );
    });
    return rowToReturn;
  }, [
    state.collapse,
    props.routeTemplates,
    props.routeTemplatesLoading,
    props.router.params,
    handleCollapse,
    handleRowClick,
    ordreRouteTemplate,
    renderTableRowRouteTemplate,
    renderCreateRouteTemplateIcon,
  ]);

  const handleToggleCustomerLocationModal = useCallback((action) => {
    updateState({
      customerLocationModalOpened: action,
    });
  }, []);

  useEffect(() => {
    const initData = async () => {
      await props.flushRouteTemplates();
      fetchRouteTemplates();
      setCiWebContext();
    };

    initData();

    return () => {
      props.flushRouteTemplates();
    };
  }, []);

  // useEffect(() => {
  //   if (props.router.location.pathname !== prevLocation) {
  //     fetchRouteTemplates();
  //   }
  // }, [props.router.location.pathname]);

  // componentDidUpdate(prevProps) {
  //   if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
  //     this.fetchRouteTemplates();
  //   }

  //   if (prevProps.routeTemplates !== this.props.routeTemplates) {
  //     this.createStateForRouteMaster();
  //   }
  // }

  useEffect(() => {
    createStateForRouteMaster();
  }, [props.routeTemplates]);

  const { routeTemplates, routeTemplatesCount, router, customerItem } = props;
  const customerId = router.params.customerId;
  const contractId = router.params.contractId;
  const customerItemId = router.params.customerItemId;

  const {
    filter,
    limit,
    page,
    deleteRouteTemplateModalOpened,
    customerLocationModalOpened,
    ciWebContext,
    openCreateRouteTemplate,
    openCreateMasterRouteTemplate,
    newRouteTemplate,
    supplier,
    day,
  } = state;

  return (
    <PageContainer>
      <PaperWrapper>
        <TableOverflowWrapper>
          <Toolbar>
            <Grid
              container
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <TextFieldUi
                  id="cpbr-filtre"
                  label={<Trans i18nKey="filter" />}
                  onChange={handleFilterChange}
                  type="search"
                  value={filter}
                />
              </Grid>

              {!ciWebContext && customerItem?.contractOperationalFlow === "CCAV" ? (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewRouteTemplate}
                    style={{ marginRight: 8 }}
                  >
                    Ajouter un modèle de route
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleToggleCustomerLocationModal(true)}
                  >
                    Ajouter un emplacement
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Toolbar>

          <CustomTable>
            {renderTableHead()}
            <TableBody>
              {routeTemplates
                .sort((a, b) => a.sequence - b.sequence)
                .map((template) => {
                  const path = template?.geoCollectionSectorUuid
                    ? MasterCollectionSectorsPath({
                        customerItemId,
                        collectionSectorIds: [template.geoCollectionSectorUuid],
                        masterCollectionSectorId: template.geoCollectionSectorUuid,
                        geoCollectionUUID: template.geoCollectionSectorUuid,
                      })
                    : `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_template_locations?masterRouteId=${template.id}&routeTemplateIds=${template.id}`;
                  return (
                    <CollapsableListParent
                      key={template.id}
                      id={template.id}
                      masterRoute={template.masterRoute}
                      geoCollectionSectorUuid={template.geoCollectionSectorUuid}
                      name={template.name}
                      children={template.children}
                      routeTemplateLocationsCount={template?.routeTemplateLocationsCount}
                      path={path}
                      onCreate={handleOpenCreateRouteTemplateModal}
                      onDelete={handleOpenDeleteRouteTemplateModal}
                      templateType={customerItem?.contractOperationalFlow}
                    />
                  );
                })}
            </TableBody>
          </CustomTable>
        </TableOverflowWrapper>

        <TablePaginationWrapper
          component="div"
          count={routeTemplatesCount}
          id="cpbr-pagination"
          labelRowsPerPage=""
          onPageChange={handlePageChangeFunc}
          onRowsPerPageChange={handleRowsPerPageChangeFunc}
          page={page}
          rowsPerPage={limit}
        />

        {customerLocationModalOpened && (
          <ModalCustomerLocation
            title={<Trans i18nKey="add_location" />}
            customerId={router.params.customerId}
            contractId={router.params.contractId}
            contractSaleType={customerItem?.contractSaleType}
            callback={fetchAndResetPagination}
            onClose={() => handleToggleCustomerLocationModal(false)}
            open={customerLocationModalOpened}
          />
        )}

        {openCreateRouteTemplate && (
          <ModalAddSubRouteTemplate
            newSubRouteTemplate={newRouteTemplate}
            opened={openCreateRouteTemplate}
            onClose={handleCloseCreateRouteTemplateModal}
            onChange={handleChangeNewRouteTemplateName}
            onCreate={handleCreateNewRouteTemplate}
          />
        )}

        {openCreateMasterRouteTemplate && (
          <ModalAddMasterRouteTemplate
            day={day}
            type={props.customerItem.loadingType}
            area={props.customerItem.area}
            matter={props.customerItem.matter}
            supplier={supplier}
            opened={openCreateMasterRouteTemplate}
            onClose={handleCloseCreateMasterRouteTemplateModal}
            onCreate={handleCreateNewMasterRouteTemplate}
            onSupplierChange={handleChangeSupplier}
            onDayChange={handleChangeDay}
          />
        )}

        <ModalWarning
          onCancel={handleCloseDeleteRouteTemplateModal}
          onSubmit={handleDeleteRouteTemplate}
          open={deleteRouteTemplateModalOpened}
          title={<Trans i18nKey="warning" />}
        >
          <Trans i18nKey="warning_delete_route_template" />
        </ModalWarning>
      </PaperWrapper>
    </PageContainer>
  );
};

RouteTemplatesList.defaultProps = {
  routeTemplatesLoading: true,
};

RouteTemplatesList.propTypes = {
  customerItem: PropTypes.object.isRequired,
  deleteRouteTemplate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  fetchRouteTemplatesByCustomerItem: PropTypes.func.isRequired,
  flushRouteTemplates: PropTypes.func.isRequired,

  routeTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  routeTemplatesCount: PropTypes.number.isRequired,
  routeTemplatesLoading: PropTypes.bool,
};

export default withCiWebContractRoutes(withRouter(withRouteTemplates(withRouteTemplate(RouteTemplatesList))));
