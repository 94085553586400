import React, { createContext, Dispatch, ReactElement, SetStateAction, useMemo, useState } from "react";

export type HeaderContextState = {
  title: string | undefined;
  setTitle: Dispatch<SetStateAction<string | undefined>>;
};

export const HeaderContext = createContext({} as HeaderContextState);

type Props = {
  children: ReactElement;
};

function HeaderProvider({ children }: Props): ReactElement {
  const [title, setTitle] = useState<string>();
  const value: HeaderContextState = useMemo(
    () => ({
      title,
      setTitle,
    }),
    [title]
  );

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
}

function useHeaderContext(): HeaderContextState {
  const context = React.useContext(HeaderContext);
  if (context === undefined) {
    throw new Error("useHeaderContext must be used within a HeaderProvider");
  }
  return context as unknown as HeaderContextState;
}

/**
 * Children component
 * @param Component
 */
function withHeaderContext<T extends object>(Component: React.ComponentType<T>): React.ComponentType<T> {
  return function fn(props: T) {
    return (
      <HeaderProvider>
        <Component {...props} />
      </HeaderProvider>
    );
  };
}

export { HeaderProvider, withHeaderContext, useHeaderContext };
