import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import EditOffIcon from "@mui/icons-material/EditOff";

const handleCancelEdit = (setUser, setIsEditingPassword) => {
  setIsEditingPassword((prevState) => !prevState);

  setUser((prevState) => ({
    ...prevState,
    passwords: {
      ...prevState.passwords,
      password: { error: false, value: "" },
      confirmPassword: { error: false, value: "" },
    },
  }));
};

const handleEditUserTextArea = (userState, setUser) => (event) => {
  setUser((prevState) => ({
    ...prevState,
    passwords: {
      ...prevState.passwords,
      [userState]: { show: prevState.passwords[userState].show, error: false, value: event.target.value },
    },
  }));
};

const handleClickShowPassword = (setUser, field) => {
  setUser((prevState) => ({
    ...prevState,
    passwords: {
      ...prevState.passwords,
      [field]: {
        value: prevState.passwords[field].value,
        error: prevState.passwords[field].error,
        show: !prevState.passwords[field].show,
      },
    },
  }));
};

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

export default function UserPassword({ user, setUser, modalType }) {
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        pl: 3,
        pr: 3,
        width: "100%"
      }}>
      <FormControl variant="outlined" fullWidth>
        {/* PASSWORD */}
        <InputLabel>Mot de passe</InputLabel>
        <OutlinedInput
          type={user.passwords.password.show ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleClickShowPassword(setUser, "password")}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                disabled={!isEditingPassword && modalType === "editUser"}
                color="primary"
              >
                {user.passwords.password.show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          autoComplete="new-password"
          label="Mot de passe"
          disabled={!isEditingPassword && modalType === "editUser"}
          error={user.passwords.password.error}
          value={user.passwords.password.value}
          onChange={handleEditUserTextArea("password", setUser)}
        />
      </FormControl>
      {/* CONFIRM PASSWORD */}
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Confirmer mot de passe</InputLabel>
        <OutlinedInput
          type={user.passwords.confirmPassword.show ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleClickShowPassword(setUser, "confirmPassword")}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                disabled={!isEditingPassword && modalType === "editUser"}
                color="primary"
              >
                {user.passwords.confirmPassword.show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          autoComplete="new-password"
          fullWidth
          label="Confirmer mot de passe"
          disabled={!isEditingPassword && modalType === "editUser"}
          error={user.passwords.confirmPassword.error}
          value={user.passwords.confirmPassword.value}
          onChange={handleEditUserTextArea("confirmPassword", setUser)}
        />
      </FormControl>
      {/* EDIT BUTTONS */}
      {modalType === "editUser" && (
        <Stack
          direction={"row"}
          sx={{
            width: "55px",
            alignItems: "center"
          }}>
          <Box>
            {!isEditingPassword && (
              <Tooltip title="Modifier">
                <IconButton color="primary" onClick={() => setIsEditingPassword((prevState) => !prevState)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            )}

            {isEditingPassword && (
              <Tooltip title="Annuler Modification">
                <IconButton color="primary" onClick={() => handleCancelEdit(setUser, setIsEditingPassword)}>
                  <EditOffIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
