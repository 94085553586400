import { QueryKey } from "@tanstack/react-query";

type AdministrativeSectorsKeys = {
  base: "administrative-sectors";
  list: () => QueryKey;
  show: (id: string) => QueryKey;
};

export const administrativeSectorsKeys: AdministrativeSectorsKeys = {
  base: "administrative-sectors",
  list: () => [administrativeSectorsKeys.base, "list"],
  show: (id: string) => [administrativeSectorsKeys.base, { id }],
};
