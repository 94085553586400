import { StreetStatus } from "@@types/collection-streets/status";
import { InclusionStatus } from "@components/master-collection-sectors-management/MasterCollectionSectorsManagementScreen";
import { hexToRgb, RGBA } from "@utils/hexToRgb";

type SubRouteColors = {
  unassigned: string;
  selected: string;
  [key: string]: string;
};

export const TEMPLATE_COLORS: SubRouteColors = {
  "1": "#F9C22EFF",
  "2": "#74E39AFF",
  "3": "#BB9AD5FF",
  "4": "#6187EAFF",
  "5": "#F482C7FF",
  "6": "#57b5ffFF",
  "7": "#C5EEDFFF",
  "8": "#fff4a7FF",
  "9": "#7a6a4fFF",
  "10": "#e133ffFF",
  "11": "#beffbeFF",
  "12": "#74e4ffFF",
  "13": "#4e78a4FF",
  "14": "#ffe2feFF",
  "15": "#ff8f8fFF",
  unassigned: "#F15946FF",
  selected: "#50B4FCFF",
};

export type BadgesColors = {
  green: string;
  yellow: string;
  beige: string;
  blue: string;
  red: string;
};

export const BADGE_COLORS: BadgesColors = {
  green: "#C5EEDF",
  beige: "#EEDEC5",
  blue: "#C5E4EE",
  yellow: "#E9EEC5",
  red: "#F15946",
};

export type RouteLocationColors = {
  [key: string]: string;
};

export const JOB_STATUS_COLORS: RouteLocationColors = {
  COMPLETED: "#74E39A",
  INTERRUPTED: "#E31B0C",
  IN_PROGRESS: "#F9C22E",
  TODO: "#84BCCE",
};

export const ROUTE_LOCATION_COLORS: RouteLocationColors = {
  finishedWithoutAnomaly: "#74E39A",
  finishedWithAnomaly: "#F9C22E",
  finishedWithOnlyAnomaly: "#F15946",
  hasNoLiftingRequest: "#464646",
  unfinished: "#9e9e9e",
};

export const ADDITIONAL_INFO_BACK_GROUND_COLOR: RouteLocationColors = {
  rotation: "#50B4FC3B",
  collectStop: "#FED8D8",
  noLifting: "#464646",
  additionalLifting: "#cecece",
};

export const ADDITIONAL_INFO_COLOR: RouteLocationColors = {
  rotation: "#2196F3",
  collectStop: "#B7092F",
  noLifting: "#ffffff",
  additionalLifting: "#ffffff",
};

export const TIME_COLOR: RouteLocationColors = {
  anomaly: "#F15946",
  lifting: "#74E39A",
};

export const STEPPER_STATUS_COLOR: RouteLocationColors = {
  todo: "#D9D9D9",
  done: "#464646",
  inProgress: "#50B4FC",
};

export const STREET_STATUS_COLOR: { [status: string]: string } = {
  NEW: "#29B6F6FF",
  PENDING: "#296DF6FF",
  PARTIAL: "#F6CD29FF",
  COMPLETED: "#25F173FF",
};

export const getStreetStatusColor = (status: StreetStatus): RGBA => {
  const hex = STREET_STATUS_COLOR[status];
  return hexToRgb(hex);
};

export type InclusionStatusColor = {
  included: string;
  excluded: string;
  selected: string;
};

export const INCLUSION_STATUS_COLOR: InclusionStatusColor = {
  included: "#74E39AFF",
  excluded: "#F15946FF",
  selected: "#50B4FCFF",
};
