// React imports
import React, { useCallback, useEffect, useState } from "react";

// Internal imports
import * as API from "@services";
import DialogWrapper from "@ui/DialogWrapper";
import LoadingButton from "@ui/LoadingButton";
import CustomerForm from "@components/customer/CustomerForm";
import CustomerDetails from "@components/customer/CustomerDetails";
import { useCustomerContext } from "@context/customer/CustomerContextProvider";

// External iports
import { Box, Tabs, Tab, Fab, Stack, DialogTitle, Button } from "@mui/material";
import { Save } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const handleChangeTab = (setTabValue) => (event, newValue) => {
  setTabValue(newValue);
};

/**
 * Modal for editing an existing customer.
 * @param {Function} editCustomer Optigo-Redux function that handles the edit of a customer
 */
function ModalCustomerEdit() {
  const [stripeStatusData, setStripeStatusData] = useState(false);

  const { customer, isLoading, tabValue, handlers } = useCustomerContext();

  // useEffect(() => {
  //   API.Customer.getCustomerStripeStatus(customer.data.id).then((customerStripeStatus) => {
  //     setStripeStatusData(customerStripeStatus);
  //   });
  // }, []);

  return (
    <DialogWrapper onClose={() => handlers.handleToggleModalCustomer("")} open={customer.currentlyOpenModal === "edit"}>
      <DialogTitle>Modifier un client</DialogTitle>
      <Stack
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
        <Tabs value={tabValue} onChange={handleChangeTab(handlers.setTabValue)} centered variant="fullWidth">
          <Tab label="Informations client" />
          <Tab label="Informations de vente" />
        </Tabs>

        {/* CustomerForm */}
        <TabPanel value={tabValue} index={0}>
          <CustomerForm isEditing />
        </TabPanel>

        {/* CustomerDetails */}
        <TabPanel value={tabValue} index={1} style={{ height: "100%" }}>
          <CustomerDetails stripeStatusData={stripeStatusData}>
            <Stack
              spacing={1}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 1
              }}>
              <Button onClick={() => handlers.handleToggleModalCustomer("")} disabled={isLoading}>
                Annuler
              </Button>

              <LoadingButton
                loading={isLoading}
                startIcon={<Save />}
                onClick={handlers.handleSubmitCustomerDetails}
                loadingPosition="start"
              >
                Sauvegarder
              </LoadingButton>
            </Stack>
          </CustomerDetails>
        </TabPanel>
      </Stack>
    </DialogWrapper>
  );
}

export default ModalCustomerEdit;
