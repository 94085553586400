import { axiosClientV2 as http } from "../axios";

export type GenerateInvoiceableJobReportsServiceReturn = { blob: Blob; filename: string };

const generateInvoiceableJobReports = async (jobId: number): Promise<GenerateInvoiceableJobReportsServiceReturn> => {
  try {
    const res = await http.get<Blob>(`/jobs/${jobId}/generate_invoiceable_job_reports`, {
      responseType: "blob",
    });

    const filename = res.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0]
      .trim()
      .replace(/^"|"$/g, "");

    return { blob: new Blob([res.data]), filename };
  } catch (error) {
    throw error as Error;
  }
};

export default generateInvoiceableJobReports;
