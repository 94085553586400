import React, { ReactElement } from "react";
import { Backdrop, ListItemButton, ListItemText, Typography } from "@mui/material";
import { List } from "@components/header/menu/Menu.styles";
import { useFeatureFlagsContext } from "@context/featureFlags/FeatureFlagsContext";
import { useAdministrativeSectorsContext } from "@context/geo/AdministrativeSectorsContext";
import { Link } from "react-router-dom";
import { AdministrativeSectorPath, PEP_ROUTES, ROUTES_PARAMETERS } from "@components/routing/constants";
import { ROUTES } from "@components/routing/RouterV2";
import { Trans } from "react-i18next";
import { SectorDetail } from "@@types/sector-details/sector-details.type";
import { uniq } from "lodash";
import MenuDrawerExtension from "@components/header/menu/MenuDrawerExtension";
import { MatterCodes } from "@@types/matter/matter";

export type MenuPepItemsProps = {
  open: boolean;
  closeMenu: () => void;
};
const MenuAdminSectorItems = ({ open, closeMenu }: MenuPepItemsProps): ReactElement | null => {
  const { featureFlags } = useFeatureFlagsContext();
  const { administrativeSectors } = useAdministrativeSectorsContext();

  if (!featureFlags["access-linea-geo-api-web-pages"]) {
    return null;
  }

  const getUniqueMatterCodes = (sectorDetails: SectorDetail[]): string[] => {
    return uniq(sectorDetails.map((x) => x.matterCode));
  };

  return (
    <MenuDrawerExtension
      openDrawerExtension={open}
      onClose={closeMenu}
    >
      <Backdrop
        open
        onClick={closeMenu}
        invisible
      />

      <Typography sx={{ pt: 3.5, marginLeft: "315px", size: "16px", lineHeight: "20px", fontWeight: 700 }}>
        <Trans i18nKey="common.title.admin_sectors" />
      </Typography>
      <List
        style={{
          overflow: "auto",
          marginLeft: "315px",
        }}
      >
        {Object.values(administrativeSectors)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((administrativeSector) => {
            return (
              <>
                <ListItemText
                  key={administrativeSector.id}
                  primary={<Typography variant="caption">{administrativeSector.name}</Typography>}
                  sx={{ color: "#0000008A", pt: 2 }}
                />
                {getUniqueMatterCodes(Object.values(administrativeSector.sectorDetails)).map((matter) => (
                  <ListItemButton
                    key={matter}
                    component={Link}
                    to={AdministrativeSectorPath({
                      matter: matter as MatterCodes,
                      administrativeSectorId: administrativeSector.id,
                    })}
                    onClick={closeMenu}
                  >
                    <ListItemText primary={<Trans i18nKey={`matter.code.${matter}`} />} />
                  </ListItemButton>
                ))}
              </>
            );
          })}
      </List>
    </MenuDrawerExtension>
  );
};

export default MenuAdminSectorItems;
