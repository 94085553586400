import { Checkbox, Stack, Typography } from "@mui/material";
import React from "react";

const handleChangeRolesSwitch = (roleType, setUser) => (event, child) => {
  setUser((prevState) => ({
    ...prevState,
    roles: {
      ...prevState.roles,
      [roleType]: child,
    },
  }));
};

export default function UserRoles({ user, setUser }) {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        pl: 3,
        pt: 2
      }}>
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: "bold"
        }}>
        Rôles
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: "center",
          pl: 10,
          justifyContent: "flex-end",
          width: "87%"
        }}>
        {/* ACCOUNTING */}
        <Stack direction="row" sx={{
          alignItems: "center"
        }}>
          <Checkbox checked={user.roles.accounting} onChange={handleChangeRolesSwitch("accounting", setUser)} />
          <Typography>Comptabilité</Typography>
        </Stack>

        {/* ADMIN */}
        <Stack direction="row" sx={{
          alignItems: "center"
        }}>
          <Checkbox checked={user.roles.admin} onChange={handleChangeRolesSwitch("admin", setUser)} />
          <Typography>Administrateur</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
