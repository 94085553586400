import { FC } from "react";

import CopyIcon from "@/components/icons/CopyIcon";
import DownloadIcon from "@/components/icons/DownloadIcon";
import EcoIcon from "@/components/icons/EcoIcon";
import OpenIcon from "@/components/icons/OpenIcon";
import ProblemCaseIcon from "@/components/icons/ProblemCaseIcon";
import { AdministrationIcon } from "@components/icons/AdministrationIcon";
import { AutomaticLiftingIcon } from "@components/icons/AutomaticLiftingIcon";
import BoltIcon from "@components/icons/BoltIcon";
import BubbleIcon from "@components/icons/BubbleIcon";
import { CcavIcon } from "@components/icons/CcavIcon";
import CheckIcon from "@components/icons/CheckIcon";
import { CloseIcon } from "@components/icons/CloseIcon";
import ContainerIcon from "@components/icons/ContainerIcon";
import CubeIcon from "@components/icons/CubeIcon";
import { DashboardIcon } from "@components/icons/dashboardIcon";
import DotIcon from "@components/icons/DotIcon";
import { EditIcon } from "@components/icons/EditIcon";
import FileIcon from "@components/icons/FileIcon";
import { GeolocateAnomalyIcon } from "@components/icons/GeolocateAnomalyIcon";
import HashtagIcon from "@components/icons/HashtagIcon";
import { IconProps } from "@components/icons/Icon.type";
import { InventoryIcon } from "@components/icons/InventoryIcon";
import LineIcon from "@components/icons/LineIcon";
import ListIcon from "@components/icons/ListIcon";
import { LocationIcon } from "@components/icons/LocationIcon";
import { ManualLiftingIcon } from "@components/icons/ManualLiftingIcon";
import { MapDotMarkerIcon } from "@components/icons/MapDotMarkerIcon";
import { MarkerIcon } from "@components/icons/MarkerIcon";
import { ModalityIcon } from "@components/icons/ModalityIcon";
import MoreDotIcon from "@components/icons/MoreDotIcon";
import { PepIcon } from "@components/icons/PepIcon";
import { PinIcon } from "@components/icons/PinIcon";
import RefreshIcon from "@components/icons/RefreshIcon";
import { RollOffIcon } from "@components/icons/RollOffIcon";
import { ScrollIcon } from "@components/icons/ScrollIcon";
import { TrashIcon } from "@components/icons/TrashIcon";

export const ICON_NAMES: IconNameComponent = {
  administration: AdministrationIcon,
  automaticLifting: AutomaticLiftingIcon,
  bolt: BoltIcon,
  bubble: BubbleIcon,
  ccav: CcavIcon,
  check: CheckIcon,
  close: CloseIcon,
  container: ContainerIcon,
  copy: CopyIcon,
  cube: CubeIcon,
  dashboard: DashboardIcon,
  dot: DotIcon,
  download: DownloadIcon,
  eco: EcoIcon,
  edit: EditIcon,
  file: FileIcon,
  geolocateAnomalyIcon: GeolocateAnomalyIcon,
  hashtag: HashtagIcon,
  inventory: InventoryIcon,
  line: LineIcon,
  list: ListIcon,
  location: LocationIcon,
  manualLifting: ManualLiftingIcon,
  mapDotMarker: MapDotMarkerIcon,
  marker: MarkerIcon,
  modality: ModalityIcon,
  more: MoreDotIcon,
  open: OpenIcon,
  pep: PepIcon,
  pin: PinIcon,
  problemCase: ProblemCaseIcon,
  refresh: RefreshIcon,
  rollOff: RollOffIcon,
  scroll: ScrollIcon,
  trash: TrashIcon,
};

export type IconNames =
  | "administration"
  | "automaticLifting"
  | "bolt"
  | "bubble"
  | "ccav"
  | "check"
  | "close"
  | "container"
  | "copy"
  | "cube"
  | "dashboard"
  | "dot"
  | "download"
  | "eco"
  | "edit"
  | "file"
  | "geolocateAnomalyIcon"
  | "hashtag"
  | "inventory"
  | "line"
  | "list"
  | "location"
  | "manualLifting"
  | "mapDotMarker"
  | "marker"
  | "modality"
  | "more"
  | "open"
  | "pep"
  | "pin"
  | "problemCase"
  | "refresh"
  | "rollOff"
  | "scroll"
  | "trash";

interface IconNameComponent {
  [name: string]: FC<IconProps>;
}
