import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import React, { ReactElement, useCallback, useState } from "react";

import HeaderTitle from "./HeaderTitle";
import Menu from "@components/header/menu/Menu";
import HeaderSearchBar from "@components/header/HeaderSearchBar.jsx";
import AccountMenu from "@components/header/AccountMenu";
import useCurrentRoles from "@hooks/auth/useCurrentRoles";
import { useHeaderContext } from "@context/header/HeaderContext";

const AppBarSC = styled(AppBar)`
  flex: none;

  button {
    margin-right: 15px;
  }
`;

const BurgerMenuIcon = styled(MenuIcon)`
  &.icon-color {
    color: white;
  }
`;

const Header = (): ReactElement => {
  const { auth } = useCurrentRoles();
  const { title } = useHeaderContext();
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  const handleOpenMenu = useCallback(() => {
    setMenuOpened(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuOpened(false);
  }, []);

  return (
    <AppBarSC
      position="static"
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="secondary"
          onClick={handleOpenMenu}
          size="large"
        >
          <BurgerMenuIcon classes={{ root: "icon-color" }} />
        </IconButton>

        <Menu
          opened={menuOpened}
          onClose={handleCloseMenu}
          user={auth.user}
        />

        <HeaderTitle title={title} />
        <HeaderSearchBar />
        <AccountMenu user={auth.user} />
      </Toolbar>
    </AppBarSC>
  );
};

export default Header;
