// React imports
import React, { memo, useCallback } from "react";

// Internal imports
import InlineColorPicker from "@ui/InlineColorPicker";

// External imports
import { isEqual } from "lodash";
import { Stack } from "@mui/material";

function NoteColorPicker({ colorStateName, noteColor, handlers }) {
  // console.log("[NoteColorPicker] >>> RENDERED");

  const handleChangeNoteColor = useCallback((color) => {
    handlers.setSingleCurrent(colorStateName, color.hex);
  }, []);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: "center",
        width: "100%",
        display: "flex",
        position: "relative"
      }}>
      <InlineColorPicker color={noteColor} onChangeComplete={handleChangeNoteColor} styles={{ width: "100%" }} />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { noteColor: prevNoteColor } = prevProps;
  const { noteColor: nextNoteColor } = nextProps;
  let arePropsEqual = true;

  if (!isEqual(prevNoteColor, nextNoteColor)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(NoteColorPicker, areEqual);
