// React imports
import React, { memo, useCallback, useState } from "react";

// External iports
import Select from "react-select";
import { isEqual } from "lodash";
import {
  Stack,
  FormControl,
  Input,
  Typography,
  Tooltip,
  InputAdornment,
  MenuItem,
  IconButton,
  Divider,
} from "@mui/material";
import { Select as MuiSelect } from "@mui/material";
import { Edit, HelpOutline } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useToggle from "@hooks/useToggle";

const handleChangeDestinationAreaDropdown = (setSingleCurrent) => (event) => {
  setSingleCurrent("destinationCustomAreaCode", event.target.value);
};

function DestinationSelect({
  destinationCustomAreaCode,
  destinationAreaCustomFee,
  isDestinationOutOfAreaError,
  isDestinationError,
  destination,
  destinations,
  handlers,
}) {
  const destinationAreaHandler = useToggle(false);

  const areaIsOutOfArea = destination.data?.areaIsOutOfArea;

  const handleChangeDestination = useCallback((destination) => {
    destination &&
      handlers.setCurrent((prevState) => ({
        ...prevState,
        destination: destination,
        destinationAreaCustomFee: +destination.data?.areaCustomFee,
      }));
  }, []);

  const handleChangeDestinationAreaCustomFee = useCallback(
    ({ target }) => {
      const value = Number(target.value);

      if (value >= 0 && value <= 9999 && value !== destinationAreaCustomFee) {
        handlers.setSingleCurrent("destinationAreaCustomFee", value);
      }
    },
    [destinationAreaCustomFee]
  );

  const destinationArea = destination?.data?.areaCustomCode
    ? destination?.data?.areaCustomCode
    : destination?.data?.areaCode;
  const selectedArea = destinationCustomAreaCode ?? destinationArea;

  return (
    <Stack
      direction="row"
      alignItems="center"
      pb={1}
      spacing={2}
    >
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            height: 42,
            boxShadow: isDestinationError ? "none" : base.boxShadow,
            borderColor: isDestinationError ? "red" : base.borderColor,
            "&:hover": {
              borderColor: isDestinationError ? "red" : base["&:hover"].borderColor,
            },
          }),
        }}
        value={destination.value ? destination : null}
        menuPosition="fixed"
        onChange={(destination) => handleChangeDestination(destination)}
        options={destinations.data}
        placeholder={"Sélectionner une destination"}
      />
      {destination.data && (
        <Stack
          height={"42px"}
          width={"80px"}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            minHeight="15px"
          >
            <Typography
              variant="overline"
              fontSize={10}
              lineHeight={1}
              textAlign="right"
              color={areaIsOutOfArea && "darkorange"}
              alignSelf="center"
            >
              {areaIsOutOfArea ? "HORS-ZONE" : "ZONE"}
            </Typography>

            {!areaIsOutOfArea && (
              <Tooltip
                title={
                  <>
                    <Typography
                      fontWeight="bold"
                      textAlign="center"
                    >
                      Mise en garde
                    </Typography>

                    <Divider
                      variant="middle"
                      sx={{
                        backgroundColor: "lightgray",
                        opacity: "0.6"
                      }} />
                    <Stack
                      spacing="2px"
                      mt={1}
                    >
                      <span>
                        Le changement de zone va être sauvegardé pour cette adresse et réutilisé par la suite.
                      </span>
                    </Stack>
                  </>
                }
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      minWidth: 245,
                      maxWidth: 350,
                    },
                  },

                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: { offset: [0, -10] },
                      },
                    ],
                  }
                }}>
                <HelpOutline
                  sx={{ fontSize: 15, marginLeft: "2px", marginRight: "-2px" }}
                  color="primary"
                />
              </Tooltip>
            )}

            {areaIsOutOfArea && (
              <Tooltip
                title="Cet emplacement est hors-zone et requiert d'entrer un prix manuellement."
                placement="right"
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: { offset: [0, -10] },
                      },
                    ],
                  }
                }}
              >
                <HelpOutline
                  sx={{ fontSize: 15, marginLeft: "2px", marginRight: "-2px" }}
                  color="primary"
                />
              </Tooltip>
            )}
          </Stack>

          {areaIsOutOfArea ? (
            <FormControl
              variant="standard"
              sx={{ width: 60, justifyContent: "flex-end" }}
              size="small"
            >
              <Input
                id="component-simple"
                size="small"
                value={destinationAreaCustomFee.toString() || 0} // toString to disallow leading zeros like 054
                onChange={handleChangeDestinationAreaCustomFee}
                error={isDestinationOutOfAreaError}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                sx={{
                  // Remove input padding and change text alignment
                  ".MuiInput-input": { textAlign: "right", padding: 0 },
                  // Remove input arrows
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                }}
                inputProps={{ min: 0, max: 9999 }}
                type="number"
              />
            </FormControl>
          ) : (
            <Stack direction="row">
              <FormControl
                variant="standard"
                disabled
              >
                <Stack
                  direction="row"
                  spacing={0.5}
                >
                  <MuiSelect
                    onClose={destinationAreaHandler.setState.toggle}
                    value={`${selectedArea}`}
                    open={destinationAreaHandler.state}
                    disableUnderline
                    inputProps={{ IconComponent: () => null }}
                    onChange={handleChangeDestinationAreaDropdown(handlers.setSingleCurrent)}
                    sx={{
                      ".MuiInput-input": { padding: "1px !important", WebkitTextFillColor: "black" },
                      fontSize: 15,
                    }}
                  >
                    {["1", "2", "3", "4"].map((option) => (
                      <MenuItem
                        key={option}
                        value={`${option}`}
                        disabled={option === selectedArea}
                      >
                        {"ZONE " + option}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </Stack>
              </FormControl>
              {
                <IconButton
                  size="small"
                  color="primary"
                  disableRipple
                  disableFocusRipple
                  onClick={destinationAreaHandler.setState.toggle}
                >
                  <KeyboardArrowDownIcon
                    fontSize="inherit"
                    sx={{ marginRight: "-10px" }}
                  />
                </IconButton>
              }
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const {
    isDestinationError: prevIsDestinationError,
    destination: prevDestination,
    destinations: prevDestinations,
    destinationAreaCustomFee: prevDestinationAreaCustomFee,
    isDestinationOutOfAreaError: prevIsDestinationOutOfAreaError,
    destinationCustomAreaCode: prevDestinationCustomAreaCode,
  } = prevProps;
  const {
    isDestinationError: nextIsDestinationError,
    destination: nextDestination,
    destinations: nextDestinations,
    destinationAreaCustomFee: nextDestinationAreaCustomFee,
    isDestinationOutOfAreaError: nextIsDestinationOutOfAreaError,
    destinationCustomAreaCode: nextDestinationCustomAreaCode,
  } = nextProps;
  let arePropsEqual = true;

  if (!isEqual(prevDestinations, nextDestinations)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevDestination, nextDestination)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevIsDestinationError, nextIsDestinationError)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevIsDestinationOutOfAreaError, nextIsDestinationOutOfAreaError)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevDestinationAreaCustomFee, nextDestinationAreaCustomFee)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevDestinationCustomAreaCode, nextDestinationCustomAreaCode)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(DestinationSelect, areEqual);
