// React imports
import React, { memo, useCallback } from "react";

// External iports
import Select from "react-select";
import { isEqual } from "lodash";
import { Stack } from "@mui/material";

function DropPointSelect({ isError, dropPoint, dropPoints, handlers }) {
  // console.log("[DropPointSelect] >>> RENDERED");

  const handleChangeDropPoint = useCallback((dropPoint) => {
    dropPoint && handlers.setSingleCurrent("dropPoint", dropPoint);
  }, []);

  return (
    <Stack direction="row" spacing={1} sx={{
      alignItems: "center"
    }}>
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            height: 48,
            boxShadow: isError ? "none" : base.boxShadow,
            borderColor: isError ? "red" : base.borderColor,
            "&:hover": {
              borderColor: isError ? "red" : base["&:hover"].borderColor,
            },
          }),
        }}
        value={dropPoint.value ? dropPoint : null}
        menuPosition="fixed"
        onChange={(dropPoint) => handleChangeDropPoint(dropPoint)}
        options={dropPoints.data}
        placeholder={"Sélectionner un point de chute"}
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { isError: prevIsError, dropPoint: prevDropPoint, dropPoints: prevDropPoints } = prevProps;
  const { isError: nextIsError, dropPoint: nextDropPoint, dropPoints: nextDropPoints } = nextProps;
  let arePropsEqual = true;

  if (!isEqual(prevDropPoints, nextDropPoints)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevDropPoint, nextDropPoint)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevIsError, nextIsError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(DropPointSelect, areEqual);
