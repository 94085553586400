import React, { ReactElement } from "react";
import { ListItem, Typography } from "@mui/material";

type FinancialReportChildRowProps = {
  total: number;
};

const FinancialReportTotalRow = ({ total }: FinancialReportChildRowProps): ReactElement => {
  return (
    <ListItem
      divider
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Typography variant="h6">TOTAL</Typography>
      <Typography
        variant="h6"
        sx={{
          pr: 9.5
        }}
      >
        {total}
      </Typography>
    </ListItem>
  );
};

export default FinancialReportTotalRow;
