// React imports
import React, { useEffect, useState, useCallback, memo } from "react";

// Internal imports
import QuickJob from "@components/modals/quickTask/quickJob/QuickJob";
import QuickRoute from "@components/modals/quickTask/quickRoute/QuickRoute";

// External iports
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { DialogTitle, FormControlLabel, RadioGroup, Radio, Grid } from "@mui/material";
import QuickPepLayout from "@components/modals/quickTask/quickPep/QuickPepLayout";

/**
 * Header for ModalQuickTask separated as 4 grids within a grid container to render
 * what we want in each corner from within QuickJob or QuickRoute.
 *
 * @function
 * @param {Object} props - Component props
 * @param {string} props.taskType - The current task type
 * @param {function} props.setTaskType - A callback function to handle changing task type
 * @param {Node} props.topLeft - JSX elements to be rendered on the top left of the grid container
 * @param {Node} props.topRight - JSX elements to be rendered on the top right of the grid container
 * @param {Node} props.bottomRight - JSX elements to be rendered on the bottom right of the grid container
 */
function QuickTaskHeader({ taskType, setTaskType, topLeft, topRight, bottomRight }) {
  const handleChangeTaskType = useCallback((event) => setTaskType(event.target.value), [setTaskType]);

  const paymentType = bottomRight.props.contractPaymentType;
  const flowCode = bottomRight.props.flow?.code;
  const location = bottomRight.props.location?.value;

  const paddingBottom = !location || !paymentType || paymentType === "CLIENT_ACCOUNT" || flowCode === "RE" ? 0 : 5;

  return (
    <DialogTitle>
      <Grid
        container
        sx={{ paddingBottom: paddingBottom }}
      >
        {/* Top row */}
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              height: "32px"
            }}
          >
            {topLeft}
          </Grid>
          <Grid
            item
            xs={6}
            align="right"
            sx={{
              height: "32px"
            }}
          >
            {topRight}
          </Grid>
        </Grid>

        {/* Bottom row */}
        <Grid
          container
          sx={{
            flexWrap: "nowrap",
            mt: 1
          }}>
          <Grid
            item
            xs={6}
            sx={{
              height: "32px",
              minWidth: "61%"
            }}>
            <RadioGroup
              row
              aria-labelledby="quick-task-type"
              name="quick-task-type"
              value={taskType}
              onChange={handleChangeTaskType}
              sx={{ height: "32px" }}
            >
              <FormControlLabel
                value="job"
                control={<Radio size="small" />}
                label="Tâche"
              />
              <FormControlLabel
                value="route"
                control={<Radio size="small" />}
                label="Route CCAV"
              />
              <FormControlLabel
                value="pep"
                control={<Radio size="small" />}
                label="Route PEP"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            xs={6}
            align="right"
            sx={{
              height: "32px"
            }}
          >
            {bottomRight}
          </Grid>
        </Grid>
      </Grid>
    </DialogTitle>
  );
}

function ModalQuickTask({ onClose, isOpen, units }) {
  // console.log("[ModalQuickTask] >>> RENDERED");
  const [taskType, setTaskType] = useState("job");
  // useLogStateChange("taskType", taskType);

  let QuickTask;
  switch (taskType) {
    case "job":
      QuickTask = QuickJob;
      break;
    case "route":
      QuickTask = QuickRoute;
      break;
    case "pep":
      QuickTask = QuickPepLayout;
      break;
  }

  return (
    <QuickTask
      onClose={onClose}
      isOpen={isOpen}
      units={units}
      Header={QuickTaskHeader}
      taskType={taskType}
      setTaskType={setTaskType}
    />
  );
}

const areEqual = (prevProps, nextProps) => {
  const { units: prevUnits, isOpen: prevIsOpen } = prevProps;
  const { units: nextUnits, isOpen: nextIsOpen } = nextProps;
  let arePropsEqual = true;

  if (!isEqual(prevUnits, nextUnits)) {
    arePropsEqual = false;
  }

  if (prevIsOpen !== nextIsOpen) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

QuickTaskHeader.propTypes = {
  taskType: PropTypes.string.isRequired,
  setTaskType: PropTypes.func.isRequired,
  topLeft: PropTypes.node,
  topRight: PropTypes.node,
  bottomRight: PropTypes.node,
};

ModalQuickTask.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  units: PropTypes.array.isRequired,
};

export default memo(ModalQuickTask, areEqual);
