import moment from "moment";
import { Moment } from "moment/moment";
import { ReactNode, useState } from "react";

import { Customer } from "@@types/customers/customer.type";
import { FinancialReportData } from "@@types/reports/financial-report.type";
import { MasterRouteReport } from "@@types/reports/master-route-report.type";
import { ProblemCaseReport } from "@@types/reports/problem-case-report.type";
import useCreateFinancialReport from "@hooks/reports/create-financial-report.hook";
import useCreateMasterRoutesReport from "@hooks/reports/create-master-routes-report.hook";
import useCreateProblemCasesReport from "@hooks/reports/create-problem-cases-report.hook";
import useNotifier from "@hooks/useNotifier";
import { SelectChangeEvent } from "@mui/material";

type UseLocationContainer = {
  reportTypes: ReportType[];
  report: Report;
  selectAllContracts: boolean;
  financialReport: FinancialReportData[] | undefined;
  problemCasesReport: ProblemCaseReport[] | undefined;
  masterRoutesReport: MasterRouteReport[] | undefined;
  handleChangeSelectedReportType: (event: SelectChangeEvent<string>) => void;
  handleChangeSelectedContracts: (event: SelectChangeEvent<number[]>, child: ReactNode) => void;
  handleClickCreateReport: () => void;
  handleChangeStartDate: (event: Moment | null) => void;
  handleChangeEndDate: (event: Moment | null) => void;
  isLoading: boolean;
};

export type ReportType = {
  value: string;
  label: string;
  disabled: boolean;
};

const INITIAL_REPORT_TYPES_STATE = [
  { value: "1", label: "Financier", disabled: false },
  { value: "2", label: "Cas problèmes", disabled: false },
  { value: "3", label: "Routes maître", disabled: false },
];

export type Report = {
  reportType: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  contractIds: number[];
};

const today = moment();

const REPORT_INITIAL_STATES = {
  reportType: "1",
  startDate: today,
  endDate: today,
  contractIds: [],
};

const useReportLayout = (customer: Customer): UseLocationContainer => {
  const [reportTypes, setReportTypes] = useState<ReportType[]>(INITIAL_REPORT_TYPES_STATE);
  const [selectAllContracts, setSelectAllContracts] = useState<boolean>(false);
  const [report, setReport] = useState<Report>(REPORT_INITIAL_STATES);

  const [financialReport, setFinancialReport] = useState<FinancialReportData[]>();
  const [problemCasesReport, setProblemCasesReport] = useState<ProblemCaseReport[]>();
  const [masterRoutesReport, setMasterRoutesReport] = useState<MasterRouteReport[]>();

  const { mutate: createFinancialReport, isPending: isFinancialReportPending } = useCreateFinancialReport(
    customer.id.toString()
  );
  const { mutate: createProblemCasesReport, isPending: isProblemCasesReportPending } = useCreateProblemCasesReport(
    customer.id.toString()
  );
  const { mutate: createMasterRoutesReport, isPending: isMasterRoutesReportPending } = useCreateMasterRoutesReport(
    customer.id.toString()
  );

  const { enqueueMessage } = useNotifier();

  const handleChangeSelectedReportType = (event: SelectChangeEvent<string>): void => {
    setReport((prevState) => ({
      ...prevState,
      reportType: event.target.value,
      contractIds: [],
      startDate: today,
      endDate: today,
    }));
    setFinancialReport(undefined);
    setProblemCasesReport(undefined);
    setMasterRoutesReport(undefined);
  };

  const handleChangeSelectedContracts = (event: SelectChangeEvent<number[]>, child: ReactNode): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if ([child.props?.value, event.target.value].includes("allContracts")) {
      if (report.contractIds.length === customer.contracts.length) {
        setReport((prevState) => ({
          ...prevState,
          contractIds: [],
        }));
      } else {
        const allContractIds = customer.contracts.map((contract) => contract.id);
        setReport((prevState) => ({
          ...prevState,
          contractIds: allContractIds,
        }));
      }
    } else {
      const contractIds = Array.isArray(event.target.value)
        ? event.target.value.map((value) => parseInt(value.toString()))
        : [];
      setReport((prevState) => ({
        ...prevState,
        contractIds: contractIds,
      }));
    }

    event.target.value.length === customer.contracts.length
      ? setSelectAllContracts(true)
      : setSelectAllContracts(false);
  };

  const handleChangeStartDate = (event: Moment | null): void => {
    setReport((prevState) => ({
      ...prevState,
      startDate: moment(event),
    }));
  };

  const handleChangeEndDate = (event: Moment | null): void => {
    setReport((prevState) => ({
      ...prevState,
      endDate: moment(event),
    }));
  };

  const handleClickCreateReport = (): void => {
    setFinancialReport(undefined);
    setProblemCasesReport(undefined);
    setMasterRoutesReport(undefined);

    switch (report.reportType) {
      case "1": {
        createFinancialReport(report)
          .then((res) => {
            setFinancialReport(res.data);
            enqueueMessage("Rapport financier généré avec succès.", "success");
          })
          .catch(() => {
            enqueueMessage("Une erreur est survenue lors de la génération du rapport financier.", "error");
          });
        break;
      }
      case "2": {
        createProblemCasesReport(report)
          .then((res) => {
            setProblemCasesReport(res.data);
            enqueueMessage("Rapport cas problèmes généré avec succès.", "success");
          })
          .catch(() => {
            enqueueMessage("Une erreur est survenue lors de la génération du rapport de cas problèmes.", "error");
          });
        break;
      }
      case "3": {
        createMasterRoutesReport(report)
          .then((res) => {
            setMasterRoutesReport(res.data);
            enqueueMessage("Rapport routes maître généré avec succès.", "success");
          })
          .catch(() => {
            enqueueMessage("Une erreur est survenue lors de la génération du rapport de routes maître.", "error");
          });
        break;
      }
    }
  };

  return {
    reportTypes,
    report,
    selectAllContracts,
    financialReport,
    problemCasesReport,
    masterRoutesReport,
    handleChangeSelectedReportType,
    handleChangeSelectedContracts,
    handleChangeStartDate,
    handleChangeEndDate,
    handleClickCreateReport,
    isLoading: isFinancialReportPending || isProblemCasesReportPending || isMasterRoutesReportPending,
  };
};

export default useReportLayout;
