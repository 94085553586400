import DialogWrapper from "@ui/DialogWrapper";
import { Button, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import LoadingButton from "@ui/LoadingButton";
import { Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import useAsync from "@hooks/useAsync";
import * as API from "@services";
import { includes, map } from "lodash";
import useNotifier from "@hooks/useNotifier";
import useApiResponseHandler from "@hooks/useApiResponseHandler";
import { isArrayTruthy } from "@utils/common";

const newItemInitialState = {
  id: "",
  value: "",
  error: false,
};

const newItemInitialStates = {
  number: newItemInitialState,
  format: newItemInitialState,
};

const validNewItem = (newItem, setNewItem) => {
  const errors = map(newItem, (value, key) => {
    if (value.value === "") {
      setNewItem((prevState) => ({
        ...prevState,
        [key]: { ...prevState[key], error: true },
      }));
    }
    return value.value === "";
  });

  let isValid = !includes(errors, true);

  return isValid;
};

const handleSaveNewItem = async (newItem, setNewItem, setIsItemNewModalOpen, handleApiResponse) => {
  const valid = validNewItem(newItem, setNewItem);

  if (valid) {
    const payload = {
      container_number: newItem.number.value,
      item_spec_id: newItem.format.id,
      item_kind_id: 3, // Seulement les item_kind RO sont afficher / creer dans cette page
      available: true,
    };
    const res = await API.Item.createItem(payload);
    if (isArrayTruthy(res.errors)) {
      setNewItem((prevState) => ({
        ...prevState,
        number: { ...prevState.number, error: true },
      }));
      handleApiResponse(res);
    } else {
      setIsItemNewModalOpen((prevState) => !prevState);
    }
  }
};

const SelectItems = (formats) => {
  return formats.map((format) => (
    <MenuItem key={format.format} value={format.format}>
      {format.name}
    </MenuItem>
  ));
};

const fetchItemSpecsFormatsListAsync = async (setFormats) => {
  const res = await API.ItemSpecs.fetchItemSpecs();
  setFormats(res.data);
};

const handleChangeFormat = (formats, setNewItem) => (event) => {
  const selectedValue = formats.find((value) => value.format === event.target.value);
  selectedValue &&
    selectedValue?.format &&
    selectedValue?.id &&
    setNewItem((prevState) => ({
      ...prevState,
      format: { ...prevState.format, value: selectedValue.format, id: selectedValue.id, error: false },
    }));
};

const handleChangeNumber = (setNewItem) => (event) => {
  setNewItem((prevState) => ({
    ...prevState,
    number: { ...prevState.number, value: event.target.value, error: false },
  }));
};

export default function ItemNewModal({ open, onClose, setIsItemNewModalOpen, updateItemsList }) {
  const [formats, setFormats] = useState([]);
  const [newItem, setNewItem] = useState(newItemInitialStates);

  const fetchItemSpecsFormatsList = useAsync(() => fetchItemSpecsFormatsListAsync(setFormats));

  const handleApiResponse = useApiResponseHandler();

  useEffect(() => {
    fetchItemSpecsFormatsList.run();
  }, []);

  return (
    <DialogWrapper onClose={onClose} open={open}>
      <DialogTitle>Ajouter un conteneur</DialogTitle>
      <Stack
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          pb: 2
        }}>
        {/* NUMBER  */}
        <TextField
          type={"number"}
          sx={{
            width: 250,
            "input::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
            },
          }}
          label="Numéro (1 à 9999)"
          error={newItem.number.error}
          value={newItem.number.value < 9999 ? newItem.number.value : 9999}
          onChange={handleChangeNumber(setNewItem)}
          slotProps={{
            input: { inputProps: { min: 1, max: 9999 } },

            inputLabel: {
              shrink: true,
            }
          }} />

        {/* FORMAT */}
        <FormControl>
          <InputLabel shrink>Format</InputLabel>
          <Select
            sx={{ width: 250 }}
            notched
            label="Format"
            type="number"
            error={newItem.format.error}
            value={newItem.format.value}
            onChange={handleChangeFormat(formats, setNewItem)}
          >
            {SelectItems(formats)}
          </Select>
        </FormControl>
      </Stack>
      {/* ACTION BUTTONS  */}
      <Stack
        spacing={1}
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 1,
          pr: 3
        }}>
        <Button onClick={onClose}>Annuler</Button>

        <LoadingButton
          startIcon={<Save />}
          onClick={() =>
            handleSaveNewItem(newItem, setNewItem, setIsItemNewModalOpen, handleApiResponse).then(updateItemsList)
          }
          loadingPosition="start"
        >
          Enregister
        </LoadingButton>
      </Stack>
    </DialogWrapper>
  );
}
