import React, { ComponentProps, FC } from "react";

import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Wrapper = styled.div`
  position: relative;

  && {
    input {
      cursor: pointer;
    }

    & > :first-of-type:not(style):not(:first-of-type ~ *),
    & > style + * {
      width: 100%;
    }
  }
`;

const DatePickerMui: FC<ComponentProps<typeof DatePicker>> = ({ onChange, value, ...remainingProps }) => (
  <Wrapper>
    <DatePicker
      {...remainingProps}
      onChange={onChange}
      value={value}
      slotProps={{
        textField: {
          sx: {
            svg: { color: "#006F9C" },
          },
        },
      }}
    />
  </Wrapper>
);

export default DatePickerMui;
