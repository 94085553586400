import { Moment } from "moment";
import moment from "moment/moment";
import React, { ReactElement } from "react";
import { Trans } from "react-i18next";

import DatePicker from "@components/form/DatePickerMui";
import { FormControl } from "@mui/material";

type ReportDateSelectorProps = {
  startDate: Moment;
  endDate: Moment;
  onChangeStartDate: (event: Moment | null) => void;
  onChangeEndDate: (event: Moment | null) => void;
};

const ReportDateSelector = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
}: ReportDateSelectorProps): ReactElement => {
  return (
    <>
      <FormControl
        variant="outlined"
        sx={{ width: "200px" }}
      >
        <DatePicker
          label={<Trans i18nKey="start_date" />}
          name="startDate"
          onChange={onChangeStartDate}
          value={startDate}
          minDate={moment().subtract(1, "years")}
        />
      </FormControl>
      <FormControl
        variant="outlined"
        sx={{ width: "200px" }}
      >
        <DatePicker
          label={<Trans i18nKey="end_date" />}
          name="endDate"
          onChange={onChangeEndDate}
          value={endDate}
          maxDate={moment().add(1, "years")}
        />
      </FormControl>
    </>
  );
};

export default ReportDateSelector;
