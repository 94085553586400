// React imports
import React, { memo, useCallback } from "react";

// External iports
import Select from "react-select";
import { isEqual } from "lodash";
import { Stack } from "@mui/material";

function DropPointSelects({ flow, isDropPointPrimaryError, dropPointPrimary, dropPointSecondary, suppliers, handlers }) {
  // console.log("[DropPointSelects] >>> RENDERED");
  const isDropPointPrimaryDisabled = !flow.data || flow.data.code.toUpperCase().indexOf("LI") > -1;
  const isDropPointSecondaryDisabled = !flow.data || flow.data.code.toUpperCase() !== "ROSD_VP_CC";

  const handleChangeDropPoint = useCallback((state, dropPoint) => {
    dropPoint && handlers.setSingleCurrent(state, dropPoint);
  }, []);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        pb: 1
      }}>
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            height: 42,
            boxShadow: isDropPointPrimaryError ? "none" : base.boxShadow,
            borderColor: isDropPointPrimaryError ? "red" : base.borderColor,
            "&:hover": {
              borderColor: isDropPointPrimaryError ? "red" : base["&:hover"].borderColor,
            },
          }),
          placeholder: (base) => ({ ...base, lineHeight: 1 }),
        }}
        menuPosition="fixed"
        value={dropPointPrimary?.value ? dropPointPrimary : null}
        isDisabled={isDropPointPrimaryDisabled}
        onChange={(dropPoint) => handleChangeDropPoint("dropPointPrimary", dropPoint)}
        options={suppliers.data}
        placeholder={"Sélectionner un point de chute"}
      />
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({ ...base, height: 42 }),
          placeholder: (base) => ({ ...base, lineHeight: 1 }),
        }}
        menuPosition="fixed"
        value={dropPointSecondary?.value ? dropPointSecondary : null}
        isDisabled={isDropPointSecondaryDisabled}
        onChange={(dropPoint) => handleChangeDropPoint("dropPointSecondary", dropPoint)}
        options={suppliers.data}
        placeholder={"Sélectionner un point de chute alternatif"}
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const {
    flow: prevFlow,
    dropPointPrimary: prevDropPointPrimary,
    dropPointSecondary: prevDropPointSecondary,
    suppliers: prevSuppliers,
    isDropPointPrimaryError: isPrevDropPointPrimaryError
  } = prevProps;
  const {
    flow: nextFlow,
    dropPointPrimary: nextDropPointPrimary,
    dropPointSecondary: nextDropPointSecondary,
    suppliers: nextSuppliers,
    isDropPointPrimaryError: isNextDropPointPrimaryError
  } = nextProps;
  let arePropsEqual = true;

  // If the suppliers array changed, rerender
  if (!isEqual(prevSuppliers, nextSuppliers)) {
    arePropsEqual = false;
  }

  // If the current flow changed, rerender
  if (!isEqual(prevFlow, nextFlow)) {
    arePropsEqual = false;
  }

  // If the error changed, rerender
  if (!isEqual(isPrevDropPointPrimaryError, isNextDropPointPrimaryError)) {
    arePropsEqual = false;
  }

  // If the current primary dropPoint changed, rerender
  if (!isEqual(prevDropPointPrimary, nextDropPointPrimary)) {
    arePropsEqual = false;
  }

  // If the current secondary dropPoint changed, rerender
  if (!isEqual(prevDropPointSecondary, nextDropPointSecondary)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(DropPointSelects, areEqual);
