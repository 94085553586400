import * as React from "react";

import { User } from "@@types/users/user.type";
import useLogout from "@hooks/auth/useLogout";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Divider, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

type AccountMenuProps = {
  user: User;
};

const AccountMenu = ({ user }: AccountMenuProps): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { mutateAsync: logout } = useLogout();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = React.useCallback(async () => {
    try {
      await logout(
        {},
        {
          onSuccess: () => {
            window.location.href = "/login";
          },
        }
      );

      setAnchorEl(null);
    } catch (error) {
      alert("Erreur lors de la tentative de déconnexion. Veuillez réessayer.");
    }
  }, []);

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ "&:hover": { backgroundColor: "rgba(250,250,250,0.82)" }, backgroundColor: "white", marginLeft: 5 }}
      >
        <Avatar
          sx={{
            width: 36,
            height: 36,
            backgroundColor: "#006F9C",
          }}
        >
          {user.firstName?.substring(0, 1) + " " + user.lastName?.substring(0, 1)}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <Typography
          align="center"
          variant="body2"
          sx={{
            color: "#0000008A"
          }}
        >
          {user.fullName}
        </Typography>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <PowerSettingsNewIcon color="secondary" />
          </ListItemIcon>
          Déconnexion
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
