// React imports
import React, { useEffect, useMemo, memo } from "react";

// Internal imports
import * as API from "@services";
import { isObjectTruthy } from "@utils/common";
import usePrevious from "@hooks/usePrevious";
import { withProvider } from "@utils/withProvider";
import ModalCustomerNew from "@components/modals/customer/ModalCustomerNew";
import ModalCustomerEdit from "@components/modals/customer/ModalCustomerEdit";
import CustomerContextProvider, { useCustomerContext } from "@context/customer/CustomerContextProvider";

// External iports
import Select from "react-select";
import { isEqual, find, orderBy } from "lodash";
import { Stack, IconButton, Tooltip, Typography } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const handleChangeCustomer = async (customerID, setSingleCurrent) => {
  if (!customerID) {
    return;
  }

  const customer = await API.Customer.fetchCustomer(customerID);

  setSingleCurrent("customer", {
    value: customer.id,
    label: customer.displayableName,
    data: customer,
  });
};

const handleOpenEditCustomer = (customer, setCustomerFromContext, handleToggleModalCustomer) => {
  setCustomerFromContext(customer);
  handleToggleModalCustomer("edit");
};

const updateCustomers = (customers, dataFromContext) => {
  if (!customers || !dataFromContext) {
    return [];
  }

  return customers.map((customer) => {
    if (customer.value === dataFromContext.id) {
      return {
        value: customer.value,
        label: dataFromContext.displayableName,
        data: { id: dataFromContext.id, name: dataFromContext.displayableName },
      };
    }

    return customer;
  });
};

// If the user edited a customer, update the current customer
// and also the customers array if the new customer does not already exist.
const handleEditCustomerStates = (customers, dataFromContext, handlers) => {
  const updatedCustomers = updateCustomers(customers, dataFromContext);

  handlers.setSingleData("customers", updatedCustomers, "data");
  handleChangeCustomer(dataFromContext.id, handlers.setSingleCurrent);
};

// If the user created a new customer, update the current customer
// and also the customers array if the new customer does not already exist.
const handleNewCustomerStates = (customers, dataFromContext, handlers) => {
  const existingCustomer = find(customers, { value: dataFromContext.id });

  // Since there are two steps to the customer creation, we only want to update the customers
  // array in the first step since there it won't change in the second step.
  if (!existingCustomer) {
    const newCustomer = {
      value: dataFromContext.id,
      label: dataFromContext.displayableName,
      data: { id: dataFromContext.id, name: dataFromContext.displayableName },
    };

    const orderedCustomers = orderBy([...customers, newCustomer], ["label"], ["asc"]);

    handlers.setSingleData("customers", orderedCustomers, "data");
  }

  handleChangeCustomer(dataFromContext.id, handlers.setSingleCurrent);
};

function CustomerSelect({ isError, customer, customers, handlers }) {
  const { customer: customerFromContext, handlers: contextHandlers } = useCustomerContext();

  const { data: dataFromContext, currentlyOpenModal } = customerFromContext;
  const previouslyOpenModal = usePrevious(currentlyOpenModal);

  // console.log("[CustomerSelect] >>> RENDERED");

  useEffect(() => {
    // We only want to check the currently open modal if we actually have data in the customer context.
    if (isObjectTruthy(dataFromContext, "province")) {
      // This is for updating the current customer and customers array when the user completes the first step of the
      // customer creation in the modal. We do this in case he skips the second step so that we have the data at least,
      // but the accounting-items and addresses will not have been created so the related dropdowns will be empty.
      if (currentlyOpenModal === "new") {
        handleNewCustomerStates(customers.data, dataFromContext, handlers);
      }

      // Everything trigged in here is upon closing either the new or edit customer modal
      if (!currentlyOpenModal) {
        // For now, we always want to update the customer state to force the contract re-fetch
        previouslyOpenModal === "edit" && handleEditCustomerStates(customers.data, dataFromContext, handlers);

        previouslyOpenModal === "new" && handleNewCustomerStates(customers.data, dataFromContext, handlers);
      }
    }
  }, [currentlyOpenModal, dataFromContext]);

  return (
    <>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          mt: 2
        }}>
        <Select
          styles={{
            container: (base) => ({ ...base, flex: 1 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (base) => ({
              ...base,
              height: 42,
              boxShadow: isError ? "none" : base.boxShadow,
              borderColor: isError ? "red" : base.borderColor,
              "&:hover": {
                borderColor: isError ? "red" : base["&:hover"].borderColor,
              },
            }),
          }}
          value={customer.value ? customer : null}
          isLoading={customers.loading}
          isDisabled={customers.loading}
          menuPosition="fixed"
          onChange={(customer) => handleChangeCustomer(customer.value, handlers.setSingleCurrent)}
          options={customers.data}
          placeholder="Sélectionner un client"
        />

        <Stack
          direction="row"
          sx={{
            width: "80px",
            ml: 1
          }}>
          <Tooltip
            title="Ajouter"
            placement="top"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -10] },
                  },
                ],
              }
            }}
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() => contextHandlers.handleToggleModalCustomer("new")}
            >
              <Add sx={{ fontSize: 30 }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Modifier"
            placement="top"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -10] },
                  },
                ],
              }
            }}
          >
            {/* The <span> is the show the tooltip on disabled button or else console error */}
            <span>
              <IconButton
                color="primary"
                size="small"
                disabled={!customer.value}
                onClick={() =>
                  handleOpenEditCustomer(
                    customer.data,
                    contextHandlers.setCurrentCustomer,
                    contextHandlers.handleToggleModalCustomer
                  )
                }
              >
                <Edit sx={{ fontSize: 30 }} />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>

        {currentlyOpenModal === "new" && <ModalCustomerNew />}
        {currentlyOpenModal === "edit" && <ModalCustomerEdit />}
      </Stack>
    </>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { isError: prevIsError, customer: prevCustomer, customers: prevCustomers } = prevProps;
  const { isError: nextIsError, customer: nextCustomer, customers: nextCustomers } = nextProps;
  let arePropsEqual = true;

  // If the customers array changed, rerender
  if (!isEqual(prevCustomers, nextCustomers)) {
    arePropsEqual = false;
  }

  // If the current customer changed, rerender
  if (!isEqual(prevCustomer, nextCustomer)) {
    arePropsEqual = false;
  }

  // If the error changed, rerender
  if (!isEqual(prevIsError, nextIsError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default withProvider(CustomerContextProvider)(memo(CustomerSelect, areEqual));
