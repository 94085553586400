import "regenerator-runtime/runtime";
import "moment/dist/locale/fr-ca";

import { SnackbarProvider } from "notistack";
import React, { FC } from "react";

import Router from "@/components/routing/Router";
import AuthProvider from "@context/auth/AuthProvider";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import muiTheme from "@styles/mui-theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const locale = "fr-ca";

const queryClient = new QueryClient();

const App: FC = () => {
  console.log("Initializing app with theme:", muiTheme);
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />

      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={10000}
      >
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={locale}
        >
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-left"
            />
            <AuthProvider>
              <CssBaseline />
              <Router />
            </AuthProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
