// TODO Change .js to .ts

// Internal imports
import React, { useCallback, useState } from "react";

// External imports
import styled from "@emotion/styled";
import {
  Paper,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  Stack,
  Container,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// Local imports
import LogoVilleco from "@assets/images/logo-villeco.png";
import useLogin from "@hooks/auth/useLogin";
import useSetCurrentUser from "@hooks/auth/useSetCurrentUser";
import { formattedDate } from "@utils/dates.js";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Logo = styled.img`
  position: relative;
  width: 300px;
  align-self: center;
  padding-bottom: 30px;
`;

const LoginBtn = styled(Button)`
  margin-top: 50px;
`;

const LoginWrapper = styled.div`
  height: 100vh;
  width: 300px;
`;

const PaperContainer = styled(Paper)`
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 20px 0 20px;
`;

/**
 *
 * @returns Login screen used for authentication.
 */
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { mutateAsync: login } = useLogin();
  const { mutateAsync: setCurrentUser } = useSetCurrentUser();

  const handleOnFormSubmit = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        const formData = new FormData(event.target);

        const email = /** @type {string} **/ (formData.get("email"));
        const password = /** @type {string} **/ (formData.get("password"));

        await login({ email, password });

        await setCurrentUser();

        navigate(`/?date=${formattedDate()}`);
      } catch (error) {
        const { data } = error.response ?? {};

        data.messages?.length && alert(data.messages[0]);
      }
    },
    [login]
  );

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}
    >
      <Logo src={LogoVilleco} />

      <form onSubmit={handleOnFormSubmit}>
        <PaperContainer>
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              label="Courriel"
              name="email"
              fullWidth
              sx={{ pb: 2 }}
            />

            <FormControl
              variant="outlined"
              fullWidth
            >
              <InputLabel>Mot de passe</InputLabel>
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                      color="primary"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{ backgroundColor: "#E8F0FE" }}
                margin="normal"
                type={showPassword ? "text" : "password"}
                label="Mot de passe"
                name="password"
                fullWidth
              />
            </FormControl>
          </div>

          <LoginBtn
            type="submit"
            variant="contained"
            color="primary"
          >
            Connexion
          </LoginBtn>
        </PaperContainer>
      </form>
    </Container>
  );
};

export default Login;
