import DialogWrapper from "@ui/DialogWrapper";
import { Button, Divider, Stack, Typography } from "@mui/material";
import LoadingButton from "@ui/LoadingButton";
import { Save } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import UserInformations from "@components/modals/user/UserInformations";
import UserRoles from "@components/modals/user/UserRoles";
import UserPassword from "@components/modals/user/UserPassword";
import UserNip from "@components/modals/user/UserNip";
import { includes, map } from "lodash";
import * as API from "@services";
import UserStatus from "@components/modals/user/UserStatus";
import useNotifier from "@hooks/useNotifier";

const handleOpenAndCloseEditUserModal = (setIsEditModalOpen) => {
  setIsEditModalOpen((prevState) => !prevState);
};

const valid = (user, setUser) => {
  const errorsData = map(user.data, (value, key) => {
    if (value.value === "") {
      setUser((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [key]: { ...prevState[key], error: true },
        },
      }));
    }
    return value.value === "";
  });

  const errorsPassword = user.passwords.password.value === user.passwords.confirmPassword.value;

  const errorsNip = user.passwords.nip.value === user.passwords.confirmNip.value;

  const errors = [...errorsData, !errorsPassword, !errorsNip];

  let isValid = !includes(errors, true);

  return isValid;
};

const handleSaveEditUser = async (user, setUser, setIsEditModalOpen, notifier) => {
  const isValid = valid(user, setUser);

  if (isValid) {
    const payload = {
      first_name: user.data.firstName.value,
      last_name: user.data.lastName.value,
      email: user.data.email.value,
      password: user.passwords.password.value,
      nip: user.passwords.nip.value,
      admin: user.roles.admin,
      accounting: user.roles.accounting,
      disabled: user.status.disabled,
    };

    const res = await API.User.updateUser(user.id, payload);
    if (res.error) {
      // format error exception created by devise (custom messages in fr.yml)
      const message = res.exception.slice(0, -1).split(":")[4].split(",");

      setUser((prevState) => {
        let test;

        // email textField to error
        if (message[0].includes("Courriel")) {
          test = { ...prevState, data: { ...prevState.data, email: { ...prevState.data.email, error: true } } };
        } else {
          // password && confirmPassword textField to error
          test = {
            ...prevState,
            passwords: {
              ...prevState.passwords,
              password: { ...prevState.passwords.password, error: true },
              confirmPassword: { ...prevState.passwords.confirmPassword, error: true },
            },
          };
        }
        return test;
      });
      notifier.enqueueMessages(message);
    } else {
      setIsEditModalOpen((prevState) => !prevState);
    }
  }
};

export default function UserEditModal({ user, setUser, isEditModalOpen, setIsEditModalOpen, fetchUsersList }) {
  const notifier = useNotifier();

  return (
    <DialogWrapper onClose={() => handleOpenAndCloseEditUserModal(setIsEditModalOpen)} open={isEditModalOpen}>
      <Stack spacing={1}>
        {/* USER STATUS (active, inactive) */}
        <UserStatus user={user} setUser={setUser} type={"edit"} />

        <Divider />

        <Stack spacing={2}>
          {/* ROLES */}
          <UserRoles user={user} setUser={setUser} />

          {/* INFORMATIONS */}
          <UserInformations user={user} setUser={setUser} />

          {/* PASSWORDS */}
          <Typography
            sx={{
              pl: 3,
              pb: 1,
              fontSize: 20,
              fontWeight: "bold"
            }}>
            Mots de passes
          </Typography>

          <UserPassword user={user} setUser={setUser} modalType={"editUser"} />

          <UserNip user={user} setUser={setUser} modalType={"editUser"} />
        </Stack>
      </Stack>
      {/* ACTION BUTTONS  */}
      <Stack
        spacing={1}
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 1,
          pr: 3
        }}>
        <Button onClick={() => handleOpenAndCloseEditUserModal(setIsEditModalOpen)}>Annuler</Button>

        <LoadingButton
          startIcon={<Save />}
          onClick={() => handleSaveEditUser(user, setUser, setIsEditModalOpen, notifier).then(fetchUsersList)}
          loadingPosition="start"
        >
          Enregister
        </LoadingButton>
      </Stack>
    </DialogWrapper>
  );
}
