import { MatterCodes } from "@@types/matter/matter";
import { ROUTES } from "@components/routing/RouterV2";

export const ROUTES_PARAMETERS = {
  administrativeSectorId: ":administrativeSectorId",
  collectionId: ":collectionId",
  geoCollectionUUID: ":collectionUUID",
  customerItemId: ":customerItemId",
  locationId: ":locationId",
  masterCollectionSectorId: ":masterCollectionSectorId",
  matterCode: ":matterCode",
  routeId: ":routeId",
  sectorDetailId: ":sectorDetailId",
  sectorId: ":sectorId",
};

export const LOCATIONS_SUB_ROUTES = {
  location: `/${ROUTES_PARAMETERS.locationId}`,
};

export const LOCATIONS_ROUTES = {
  location: `${ROUTES.locations}/${ROUTES_PARAMETERS.locationId}`,
};

export const ROUTE_ROUTES = {
  route: `${ROUTES.routes}/${ROUTES_PARAMETERS.routeId}`,
};

export const ROUTE_SUB_ROUTES = {
  route: `/${ROUTES_PARAMETERS.routeId}`,
};

export const PEP_ROUTES = {
  administrativeSectors: `/secteurs-administratifs`,
  collections: `/collectes`,
  customerItems: `/contrat-clients`,
  masterCollectionSectors: `/secteurs-collecte-maitres`,
  sectorDetails: `/details-secteurs`,
  sectorDetailsMasterCollectionSectors: `/details-secteurs/${ROUTES_PARAMETERS.matterCode}/secteurs-collecte-maitres`,
  sectors: `/secteurs`,
  edit: "edit",
};

type MasterCollectionSectorPathIds = {
  masterCollectionSectorId: string;
};

type CollectionPathIds = {
  collectionId: string;
  geoCollectionUUID: string;
};

type EditCollectionSectorStreetsUrlParams = {
  customerItemId: string;
  masterCollectionSectorId: string;
  collectionSectorIds: string[];
  geoCollectionUUID: string;
};

export const MasterCollectionSectorPath = ({ masterCollectionSectorId }: MasterCollectionSectorPathIds): string => {
  const path = `${ROUTES.pep}${PEP_ROUTES.masterCollectionSectors}/${ROUTES_PARAMETERS.masterCollectionSectorId}`;
  return path.replace(ROUTES_PARAMETERS.masterCollectionSectorId, masterCollectionSectorId);
};

export const CollectionPath = ({ collectionId, geoCollectionUUID }: CollectionPathIds): string => {
  const path = `${ROUTES.pep}${PEP_ROUTES.collections}/${ROUTES_PARAMETERS.collectionId}/${ROUTES_PARAMETERS.geoCollectionUUID}`;
  return path
    .replace(ROUTES_PARAMETERS.collectionId, collectionId)
    .replace(ROUTES_PARAMETERS.geoCollectionUUID, geoCollectionUUID);
};

export const MasterCollectionSectorsPath = ({
  customerItemId,
  collectionSectorIds,
  masterCollectionSectorId,
  geoCollectionUUID,
}: EditCollectionSectorStreetsUrlParams): string => {
  // return `${ROUTES.pep}${PEP_ROUTES.customerItems}/${customerItemId}${PEP_ROUTES.masterCollectionSectors}/${geoCollectionUUID}`;

  let path = `${ROUTES.pep}${PEP_ROUTES.customerItems}/${customerItemId}`;
  path += `${PEP_ROUTES.masterCollectionSectors}?masterCollectionSectorId=${masterCollectionSectorId}`;

  if (collectionSectorIds.length > 0) {
    collectionSectorIds.forEach((id: string, index) => {
      if (index + 1 === collectionSectorIds.length) {
        path += "&";
      }

      path += `collectionSectorIds=${id}`;
    });
  }

  return path;
};

type AdministrativeSectorPathParams = {
  matter: MatterCodes;
  administrativeSectorId: string;
  masterCollectionSectorId?: string;
};

export const AdministrativeSectorPath = ({
  matter,
  administrativeSectorId,
}: AdministrativeSectorPathParams): string => {
  return `${ROUTES.pep}${PEP_ROUTES.administrativeSectors}/${ROUTES_PARAMETERS.administrativeSectorId.replace(
    ROUTES_PARAMETERS.administrativeSectorId,
    administrativeSectorId
  )}${PEP_ROUTES.sectorDetailsMasterCollectionSectors.replace(ROUTES_PARAMETERS.matterCode, matter)}`;
};
