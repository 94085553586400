import moment from "moment/moment";
import React, { FC, useCallback, useState } from "react";

import {
  CheckboxCell,
  CustomerContractCell,
  CustomerItemCell,
  CustomerLocationCell,
  DaysCell,
  DispositionCell,
  PreviousTaskCell,
  TaskCell,
} from "@/components/transactions/client-accounts/TransactionsClientAccountsCells";
import useFetchClientAccountJobs from "@/hooks/jobs/fetch-client-account-jobs.hook";
import { JobType } from "@/types/jobs/job.type";
import TransactionsTableHead from "@components/transactions/TransactionsTableHead";
import styled from "@emotion/styled";
import { Table, TableBody, TableRow, Toolbar } from "@mui/material";
import theme from "@styles/theme";
import InlineDatePickerWrapper from "@ui/InlineDatePickerWrapper";
import { formattedDate } from "@utils/dates";

// Styled components
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  > * {
    margin-left: 10px;
  }
`;

type ClientAccountRowProps = {
  job: JobType;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLTableRowElement>, id: number) => void;
};

const ClientAccountRow: FC<ClientAccountRowProps> = ({ job, selected, onClick }) => {
  return (
    <TableRow
      onClick={(event) => onClick(event, job.id)}
      hover
      tabIndex={-1}
      selected={selected}
      sx={{
        verticalAlign: "top",
        cursor: selected ? "default" : "pointer",
        "&.Mui-selected": {
          backgroundColor: "#ECF4FC",
          outline: "2px solid #3397FF",
          outlineOffset: "-2px",
        },
      }}
    >
      <CheckboxCell job={job} />
      <CustomerContractCell job={job} />
      <CustomerItemCell job={job} />
      <CustomerLocationCell job={job} />
      <PreviousTaskCell job={job} />
      <TaskCell job={job} />
      <DaysCell job={job} />
      <DispositionCell job={job} />
    </TableRow>
  );
};

// Table headers
const TABLE_HEADERS = [
  "",
  "Client / Contrat / BC",
  "Item de contrat",
  "Emplacement",
  "Tâche préc.",
  "Tâche",
  "Jours",
  "Disposition",
];

const TransactionsClientAccountsTable: FC = () => {
  const [currentDate, setCurrentDate] = useState(moment());
  const { data } = useFetchClientAccountJobs(formattedDate(currentDate));
  const [selectedRow, setSelectedRow] = useState<number>(0);

  const handleChangeDate = useCallback((value: unknown) => setCurrentDate(value as moment.Moment), []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLTableRowElement>, id: number) => {
    setSelectedRow((prevId) => (prevId === id ? 0 : id));
  }, []);

  return (
    <>
      <Toolbar>
        <Wrapper>
          <InlineDatePickerWrapper
            // @ts-expect-error not typed
            id="cpbr-date"
            keyboard
            label="Date"
            value={currentDate}
            onChange={handleChangeDate}
            slotProps={{
              textField: {
                sx: {
                  svg: { color: theme.colors.palette.secondary.main },
                },
              },
            }}
          />
        </Wrapper>
        &nbsp;({data?.length})
      </Toolbar>

      <Table>
        <TransactionsTableHead headers={TABLE_HEADERS} />
        <TableBody>
          {data?.map((job) => (
            <ClientAccountRow
              key={job.id}
              job={job}
              selected={selectedRow === job.id}
              onClick={handleClick}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TransactionsClientAccountsTable;
