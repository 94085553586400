import { ApiResponse } from "@@types/apiResponse";
import { JobType } from "@@types/jobs/job.type";

import { axiosClientV2 as http } from "../axios";

export type FetchClientAccountJobsServiceReturn = JobType[];

const fetchClientAccountJobs = async (date: string): Promise<FetchClientAccountJobsServiceReturn> => {
  try {
    const res = await http.get<ApiResponse<JobType[]>>(`/jobs/invoiceable_client_accounts_jobs.json?date=${date}`);

    return res.data?.data || [];
  } catch (error) {
    throw error as Error;
  }
};

export default fetchClientAccountJobs;
